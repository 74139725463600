import useMakeModelYear from "../../hooks/MakeModelYearHook";
import { cloneDeep } from "lodash";
import { Box, Button, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";

const DesktopMakeModelYear = ({
	manufacturerOptions,
	handleUserVehicleChange,
	loading,
	variantOptions,
	yearOptions,
	modelOptions,
	userInput,
	submit
}) => {
	return (
		<Box sx={{ width: '100%', padding: 2, backgroundColor: '#262626', borderRadius: 2 }}>
			<Typography variant="h4" sx={{ mb: 3, textAlign: 'center', color: '#ffffff' }}>
				Search by Make Model Year
			</Typography>
			<form onSubmit={submit}>
				<Grid container spacing={2} alignItems="center" justifyContent="center">
					<Grid item xs={3}>
						<Autocomplete
							loading={loading}
							disabled={loading}
							value={userInput.manufacturer}
							onChange={handleUserVehicleChange("manufacturer")}
							options={manufacturerOptions}
							getOptionLabel={(option) => option}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Make"
									variant="outlined"
									InputLabelProps={{ style: { color: '#ffffff' } }} // White label text
									InputProps={{
										...params.InputProps,
										style: { color: '#ffffff' }, // White input text
										startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
										endAdornment: params.InputProps.endAdornment, // Preserve clear button
									}}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: '#888888', // Darker gray border
												borderWidth: '2px', // Thicker border
											},
											'&:hover fieldset': {
												borderColor: '#ffffff', // White border on hover
											},
											'&.Mui-focused fieldset': {
												borderColor: '#ffffff', // White border when focused
											},
										},
										'& .MuiSvgIcon-root': {
											color: '#ffffff', // White arrow color
										},
										'& .MuiAutocomplete-clearIndicator': {
											color: '#ffffff', // White clear button color
										},
									}}
								/>
							)}
							clearOnEscape
							isOptionEqualToValue={(option, value) => option === value}
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							loading={loading}
							disabled={loading}
							value={userInput.model}
							onChange={handleUserVehicleChange("model")}
							options={modelOptions}
							getOptionLabel={(option) => option}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Model"
									variant="outlined"
									InputLabelProps={{ style: { color: '#ffffff' } }} // White label text
									InputProps={{
										...params.InputProps,
										style: { color: '#ffffff' }, // White input text
										startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
										endAdornment: params.InputProps.endAdornment, // Preserve clear button
									}}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: '#888888', // Darker gray border
												borderWidth: '2px', // Thicker border
											},
											'&:hover fieldset': {
												borderColor: '#ffffff', // White border on hover
											},
											'&.Mui-focused fieldset': {
												borderColor: '#ffffff', // White border when focused
											},
										},
										'& .MuiSvgIcon-root': {
											color: '#ffffff', // White arrow color
										},
										'& .MuiAutocomplete-clearIndicator': {
											color: '#ffffff', // White clear button color
										},
									}}
								/>
							)}
							clearOnEscape
							isOptionEqualToValue={(option, value) => option === value}
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							loading={loading}
							value={userInput.variant}
							onChange={handleUserVehicleChange("variant")}
							options={variantOptions}
							getOptionLabel={(option) => option}
							disabled={(!userInput.manufacturer && !userInput.model) || loading}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Variant"
									variant="outlined"
									InputLabelProps={{ style: { color: '#ffffff' } }} // White label text
									InputProps={{
										...params.InputProps,
										style: { color: '#ffffff' }, // White input text
										startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
										endAdornment: params.InputProps.endAdornment, // Preserve clear button
									}}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: '#888888', // Darker gray border
												borderWidth: '2px', // Thicker border
											},
											'&:hover fieldset': {
												borderColor: '#ffffff', // White border on hover
											},
											'&.Mui-focused fieldset': {
												borderColor: '#ffffff', // White border when focused
											},
										},
										'& .MuiSvgIcon-root': {
											color: '#ffffff', // White arrow color
										},
										'& .MuiAutocomplete-clearIndicator': {
											color: '#ffffff', // White clear button color
										},
									}}
								/>
							)}
							clearOnEscape
							isOptionEqualToValue={(option, value) => option === value}
						/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							loading={loading}
							disabled={
								(!userInput.manufacturer &&
								!userInput.model &&
								!userInput.variant) || loading
							}
							value={userInput.year}
							onChange={handleUserVehicleChange("year")}
							options={yearOptions}
							getOptionLabel={(option) => option}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Year"
									variant="outlined"
									InputLabelProps={{ style: { color: '#ffffff' } }} // White label text
									InputProps={{
										...params.InputProps,
										style: { color: '#ffffff' }, // White input text
										startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
										endAdornment: params.InputProps.endAdornment, // Preserve clear button
									}}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: '#888888', // Darker gray border
												borderWidth: '2px', // Thicker border
											},
											'&:hover fieldset': {
												borderColor: '#ffffff', // White border on hover
											},
											'&.Mui-focused fieldset': {
												borderColor: '#ffffff', // White border when focused
											},
										},
										'& .MuiSvgIcon-root': {
											color: '#ffffff', // White arrow color
										},
										'& .MuiAutocomplete-clearIndicator': {
											color: '#ffffff', // White clear button color
										},
									}}
								/>
							)}
							clearOnEscape
							isOptionEqualToValue={(option, value) => option === value}
						/>
					</Grid>
				</Grid>
				<Box sx={{ mt: 4, textAlign: 'center' }}>
					<Button variant="contained" color="error" type="submit" disabled={loading}>
						Search
					</Button>
				</Box>
			</form>
		</Box>
	);
}

export default DesktopMakeModelYear;