import { getPlaceDetails } from "../../api/googlePlaceController";
import { createAddress } from "../../api/addressController";

export const fetchGooglePlaceDetails = async ({
	auth0Token,
	placeId,
	description,
	setGooglePlaceDetailsResponse,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getPlaceDetails(placeId, description, auth0Token);
		if (response.success) {
			setStatusSuccess();
			setGooglePlaceDetailsResponse(response.data);
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const createAddressData = async ({
	auth0Token,
	firstName,
	lastName,
	streetAddress,
	countryId,
	suburb,
	city,
	postalCode,
	provinceId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const body = {
			firstName: firstName,
			lastName: lastName,
			countryId: countryId,
			suburb: suburb,
			city: city,
			postalCode: postalCode,
			provinceId: provinceId,
			streetAddress: streetAddress
		};
		let response = await createAddress(body, auth0Token);

		if (response.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
