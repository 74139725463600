import React, { Fragment } from "react";
import About from "../../components/About";
import { Backdrop, CircularProgress } from "@mui/material";

const AboutPage = ({
	genericDataLoaded
}) => {
	const pageIsLoading = genericDataLoaded === false;

	return (
		<Fragment>
			<About/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default AboutPage;