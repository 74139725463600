import React from 'react';
import { styled } from "@mui/system";
import {
	Box,
	Button,
	Divider, List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper, SwipeableDrawer,
	Typography
} from "@mui/material";
import { SouthAfricanRand } from "../../utils/helpers";

const MobileCartSummary = ({
	settlementAmount,
	vat,
	cartNettTotal,
	deliveryFeeToDisplay,
	vatAmount,
	total,
	logisticsFee,
	goToCheckout
}) => {
	const SummaryContainer = styled(Paper)(({ theme }) => ({
		padding: theme.spacing(2),
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		boxShadow: theme.shadows[24],
		borderRadius: '16px 16px 0 0',
		zIndex: theme.zIndex.drawer + 2,
	}));

	const TotalContainer = styled(Box)(({ theme }) => ({
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
		borderTop: `1px solid ${theme.palette.divider}`,
		color: "#333"
	}));

	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const toggleDrawer = (open) => () => {
		setDrawerOpen(open);
	};


	return (
		<>
			<SummaryContainer elevation={4}>
				<TotalContainer>
					<Typography variant="h6" fontWeight="bold">
						Total
					</Typography>
					<Typography variant="h6" fontWeight="bold" color="textPrimary">
						{SouthAfricanRand.format(total)}
					</Typography>
				</TotalContainer>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					sx={{ marginTop: '1rem', padding: '0.75rem', borderRadius: '12px' }}
					onClick={toggleDrawer(true)}
				>
					Checkout
				</Button>
			</SummaryContainer>

			<SwipeableDrawer
				anchor="bottom"
				open={drawerOpen}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				PaperProps={{
					sx: {
						borderRadius: '16px 16px 0 0',
						padding: '1rem',
					},
				}}
			>
				<Typography variant="h6" gutterBottom>
					Order Summary
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary="Nett Total" />
						<ListItemSecondaryAction>
							<Typography variant="body2">{SouthAfricanRand.format(cartNettTotal)}</Typography>
						</ListItemSecondaryAction>
					</ListItem>
					{settlementAmount > 0 && (
						<ListItem>
							<ListItemText primary="Settlement Discount" />
							<ListItemSecondaryAction>
								<Typography variant="body2">({SouthAfricanRand.format(settlementAmount)})</Typography>
							</ListItemSecondaryAction>
						</ListItem>
					)}
					<ListItem>
						<ListItemText primary={`VAT (${vat}%)`} />
						<ListItemSecondaryAction>
							<Typography variant="body2">{SouthAfricanRand.format(vatAmount)}</Typography>
						</ListItemSecondaryAction>
					</ListItem>
					{logisticsFee > 0 && (
						<ListItem>
							<ListItemText primary="Delivery Fee" />
							<ListItemSecondaryAction>
								<Typography variant="body2">{deliveryFeeToDisplay()}</Typography>
							</ListItemSecondaryAction>
						</ListItem>
					)}
					<Divider sx={{ my: 2 }} />
					<ListItem>
						<ListItemText primary="Total" />
						<ListItemSecondaryAction>
							<Typography variant="body2" color="textPrimary">
								{SouthAfricanRand.format(total)}
							</Typography>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					sx={{ marginTop: '1rem', padding: '0.75rem', borderRadius: '12px' }}
					onClick={goToCheckout}
				>
					Proceed to Checkout
				</Button>
			</SwipeableDrawer>
		</>
	)
}

export default MobileCartSummary;