import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageHeader from "../PageHeader";
import { SouthAfricanRand } from "../../utils/helpers";

const CheckoutAddressUi = {
	firstLoad: -1,
	noAddressOptions: 0,
	addressDropdown: 1,
	addressForm: 2,
	addressChosen: 3,
	error: 4
};

const CheckoutBillingAddress = ({
	checkoutData,
	countries,
	provinces,
	userAddresses,
	onSubmit,
	gotoCreateAddressPage
}) => {
	const {screenSize} = useSystemHook();
	const [currentUiState, setCurrentUiState] = useState(
		CheckoutAddressUi.firstLoad
	);
	const [chosenAddressId, setChosenAddressId] = useState(
		checkoutData?.billingAddressId ?? null
	);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const renderAddressString = (address) => {
		const country = countries.find(x => x.countryId === address.countryId);
		const province = provinces.find(x => x.provinceId === address.provinceId);
		const addressArray = [
			address.streetAddress,
			address.suburb,
			address.city,
			address.postalCode,
			province?.name,
			country?.name
		];
		return addressArray.filter(Boolean).join(", ");
	};

	const onSaveAddressClick = async () => {
		if (!!chosenAddressId) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.billingAddressId = chosenAddressId;

			await onSubmit({checkoutData: checkoutDataClone});
		}
	};

	const onUseAddressClick = (addressId) => {
		setChosenAddressId(addressId);
	};

	const showAddressDropdown = () => {
		setCurrentUiState(CheckoutAddressUi.addressDropdown);
	};

	const showNoAddressOptions = () => {
		setCurrentUiState(CheckoutAddressUi.noAddressOptions);
	};

	const showAddAddressButton = () => {
		return (
			currentUiState === CheckoutAddressUi.noAddressOptions ||
			currentUiState === CheckoutAddressUi.addressDropdown
		);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!userAddresses && userAddresses.length > 0) {
			showAddressDropdown();
		}
		else {
			showNoAddressOptions();
		}
	}, [userAddresses]);

	useEffect(() => {
		if (checkoutData?.billingAddressId) {
			setChosenAddressId(checkoutData.billingAddressId);
		}
	}, [checkoutData]);

	return (
		<>
			<PageHeader title="Checkout" />
			{!!checkoutData && (
				<>
					{screenSize === ScreenSize.Desktop &&
						<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={8}>
									<Paper elevation={4} sx={{ padding: '2rem', borderRadius: '8px', backgroundColor: '#ffffff' }}>
										<Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
											<Typography variant="h6" color="textPrimary" sx={{ fontWeight: 'bold' }}>
												Select Billing Address
											</Typography>
											<Button
												variant="outlined"
												color="primary"
												startIcon={<AddIcon />}
												onClick={gotoCreateAddressPage}
												sx={{ textTransform: 'none' }}
											>
												Add New Address
											</Button>
										</Box>

										{currentUiState === CheckoutAddressUi.noAddressOptions && (
											<Typography variant="body1" sx={{ color: '#777', padding: '1rem 0' }}>
												No addresses found.
											</Typography>
										)}

										{currentUiState === CheckoutAddressUi.addressDropdown && (
											<>
												<Divider sx={{ marginBottom: '1rem' }} />
												<Grid container direction="column" spacing={2}>
													{userAddresses.map((address, index) => (
														<Grid container key={index} alignItems="center" spacing={2} sx={{ padding: '1rem', borderBottom: '1px solid #e0e0e0' }}>
															<Grid item xs={10}>
																<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
																	{`${address.firstName} ${address.lastName}`}
																</Typography>
																<Typography variant="caption" color="textSecondary">
																	{renderAddressString(address)}
																</Typography>
															</Grid>
															<Grid item xs={2} sx={{ textAlign: 'right' }}>
																{chosenAddressId === address.addressId ? (
																	<IconButton color="success" disabled>
																		<CheckIcon />
																	</IconButton>
																) : (
																	<Button
																		variant="contained"
																		color="primary"
																		size="small"
																		onClick={() => onUseAddressClick(address.addressId)}
																		sx={{ textTransform: 'none' }}
																	>
																		Use
																	</Button>
																)}
															</Grid>
														</Grid>
													))}
												</Grid>
											</>
										)}
									</Paper>
								</Grid>
								<Grid item xs={12} md={4}>
									<CartSummary
										vat={checkoutData.vatPercentage}
										cartNettTotal={checkoutData.subtotal}
										settlementAmount={checkoutData.settlementAmount}
										vatAmount={checkoutData.vatAmount}
										total={checkoutData.total}
										sx={{ marginBottom: '2rem' }}
									/>
									{!!chosenAddressId && (
										<Button
											variant="contained"
											color="primary"
											fullWidth
											onClick={onSaveAddressClick}
											endIcon={<ArrowForwardIcon />}
											sx={{ marginTop: '0.75rem', fontSize: '0.875rem' }}
										>
											Continue
										</Button>
									)}
								</Grid>
							</Grid>
						</Box>
					}
					{screenSize === ScreenSize.Mobile &&
						<Box sx={{ minHeight: '100vh', padding: '1rem' }}>
							<Paper elevation={2} sx={{ padding: '1rem', borderRadius: '8px', backgroundColor: '#ffffff', marginBottom: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
									<Typography variant="h6" color="textPrimary">
										Select Address
									</Typography>
									<Button
										variant="outlined"
										size="small"
										color="primary"
										startIcon={<AddIcon />}
										onClick={gotoCreateAddressPage}
										sx={{ textTransform: 'none' }}
									>
										Add Address
									</Button>
								</Box>

								{currentUiState === CheckoutAddressUi.noAddressOptions && (
									<Typography variant="body2" sx={{ color: '#777', padding: '1rem 0', textAlign: 'center' }}>
										No addresses found.
									</Typography>
								)}

								{currentUiState === CheckoutAddressUi.addressDropdown && (
									<>
										<Divider sx={{ marginBottom: '1rem' }} />
										<Box>
											{userAddresses.map((address, index) => (
												<Box key={index} sx={{ padding: '1rem', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '1rem' }}>
													<Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
														{`${address.firstName} ${address.lastName}`}
													</Typography>
													<Typography variant="body2" color="textSecondary" sx={{ marginBottom: '0.5rem' }}>
														{renderAddressString(address)}
													</Typography>
													{chosenAddressId === address.addressId ? (
														<Button variant="contained" size="small" color="success" startIcon={<CheckIcon />} fullWidth disabled>
															Selected
														</Button>
													) : (
														<Button
															variant="outlined"
															size="small"
															color="primary"
															onClick={() => onUseAddressClick(address.addressId)}
															fullWidth
															sx={{ textTransform: 'none' }}
														>
															Use this Address
														</Button>
													)}
												</Box>
											))}
										</Box>
									</>
								)}
							</Paper>

							<Paper elevation={2} sx={{ padding: '1rem', borderRadius: '8px', backgroundColor: '#ffffff', marginBottom: '1rem' }}>
								<Typography variant="h6" sx={{ marginBottom: '1rem' }}>Order Summary</Typography>
								<Typography variant="body2">Subtotal: {SouthAfricanRand.format(checkoutData.subtotal)}</Typography>
								<Typography variant="body2">VAT: {SouthAfricanRand.format(checkoutData.vatAmount)}</Typography>
								<Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: '0.5rem' }}>Total: {SouthAfricanRand.format(checkoutData.total)}</Typography>
							</Paper>

							{!!chosenAddressId && (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={onSaveAddressClick}
									endIcon={<ArrowForwardIcon />}
									sx={{ textTransform: 'none', borderRadius: '8px', marginTop: '1rem', marginBottom: "1rem" }}
								>
									Continue
								</Button>
							)}
						</Box>
					}
				</>
			)}
		</>
	);
};

export default CheckoutBillingAddress;
