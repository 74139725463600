import React, { useEffect, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Link,
	Modal,
	Radio,
	RadioGroup,
	Typography,
	Button
} from "@mui/material";
import "./style.css";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateProfileData } from "../../main-component/PageFunctions/userFunctions";
import { ApiCallStatus } from "../../utils/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import TextField from "@mui/material/TextField";


export const ModalState = Object.freeze({
	Choice: 0,
	Retail: 1,
	Trade: 2,
	InProgress: 3,
	Success: 4,
	Error: 5
});

export const RegistrationAccountType = Object.freeze({
	Retail: "retail",
	TradePersonal: "trade-personal",
	TradeCompany: "trade-company"
})

const ArgusRoleModal = ({
	open,
	setClose
}) => {
	const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
	const [modalState, setModalState] = useState(ModalState.Choice);
	const [updateProfileDataCallStatus, setUpdateProfileDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editArgusRolePopupAsSeenCallStatus, setEditArgusRolePopupAsSeenCallStatus] = useState(ApiCallStatus.NotStarted);
	const [resultMessage, setResultMessage] = useState(null);

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: isMobile ? '90%' : 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 3,
		maxHeight: '90vh',
		overflowY: 'auto',
		borderRadius: 2,
		textAlign: 'center',
	};

	const schema = yup
		.object({
			firstName: yup
				.string()
				.required("First Name is required."),
			lastName: yup
				.string()
				.required("Last Name is required."),
			mobile: yup
				.string()
				.required("Mobile number is required.")
				.matches(/^\d+$/, 'Mobile number must be digits only'),
			accountType: yup.string().required('Please select an account type'),
			personalIdNumber: yup.string().when('accountType', {
				is: (val) => {
					return RegistrationAccountType.TradePersonal === val;
				},
				then: (s) => s.required('ID Number is required'),
			}),
			companyRegNo: yup.string().when('accountType', {
				is: (val) => {
					return RegistrationAccountType.TradeCompany === val;
				},
				then: (s) => s.required('Company Registration Number/Registered Trading Name is required'),
			}),
			termsAndConditions: yup.bool().oneOf([true], 'You must accept the terms and conditions')
		})
		.required();
	const {
		control,
		watch,
		setValue,
		reset,
		handleSubmit,
		formState: {errors},
		setError,
		trigger,
		getValues
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			mobile: "",
			personalIdNumber: "",
			companyRegNo: "",
			termsAndConditions: false
		}
	});

	const accountType = watch('accountType');

	const changeToRetail = () => {
		reset();
		setValue("accountType", RegistrationAccountType.Retail)
		setModalState(ModalState.Retail);
	};

	const changeToTrade = () => {
		reset();
		setModalState(ModalState.Trade);
	};

	const changeToChoice = () => {
		setModalState(ModalState.Choice);
	};

	const changeToInProgress = () => {
		setModalState(ModalState.InProgress);
	};

	const closeModal = () => {
		setClose();
	};

	const updateProfileDataAsync = async (formData) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}

		await updateProfileData({
			auth0Token,
			firstName: formData.firstName,
			lastName: formData.lastName,
			mobile: formData.mobile,
			wholesaleTrader: !!ModalState.Trade,
			personalIdNumber: formData.personalIdNumber,
			companyRegNo: formData.companyRegNo,
			personalCapacity: formData.accountType === RegistrationAccountType.TradePersonal,
			setResultMessage,
			setStatusInProgress: () => {
				setUpdateProfileDataCallStatus(ApiCallStatus.InProgress);
				changeToInProgress();
			},
			setStatusSuccess: () =>
				setUpdateProfileDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () =>
				setUpdateProfileDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setUpdateProfileDataCallStatus(ApiCallStatus.Error)
		});
	};

	const submit = async (e) => {
		e.preventDefault();

		const result = await trigger([
			"firstName",
			"lastName",
			"mobile",
			"accountType",
			"personalIdNumber",
			"companyRegNo",
			"termsAndConditions"
		]);

		const firstName = getValues("firstName");
		const lastName = getValues("lastName");
		const mobile = getValues("mobile");
		const accountType = getValues("accountType");
		let personalIdNumber = getValues("personalIdNumber");
		let companyRegNo = getValues("companyRegNo");
		const termsAndConditions = getValues("termsAndConditions");

		const formData = {
			firstName,
			lastName,
			mobile,
			accountType,
			personalIdNumber,
			companyRegNo,
			termsAndConditions
		}

		if (!!result) {
			await updateProfileDataAsync(formData)
					.catch((e) => {
						console.error(e);
						setResultMessage("Something went wrong. Please try again or contact support.");
					}
			);
		}
	};

	useEffect(() => {
		if (isLoading === false) {
			if (isAuthenticated === true) {
				if (updateProfileDataCallStatus === ApiCallStatus.Succeeded && !!resultMessage) {
					setResultMessage(resultMessage);
					setModalState(ModalState.Success);
				}
				else if ((updateProfileDataCallStatus === ApiCallStatus.Failed || updateProfileDataCallStatus === ApiCallStatus.Error) && !!resultMessage) {
					setResultMessage(resultMessage);
					setModalState(ModalState.Error);
				}
			}
		}

	}, [updateProfileDataCallStatus, resultMessage, isLoading, isAuthenticated]);

	useEffect(() => {
		if (updateProfileDataCallStatus === ApiCallStatus.Failed || updateProfileDataCallStatus === ApiCallStatus.Error) {
			setResultMessage("Something went wrong. Please try again or contact support.");
			setModalState(ModalState.Error);
		}
	}, [editArgusRolePopupAsSeenCallStatus, resultMessage]);

	const allLoadingStates = [
		updateProfileDataCallStatus,
		editArgusRolePopupAsSeenCallStatus
	];

	const modalIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<Modal
			open={open}
			onClose={closeModal}
			disableBackdropClick
			disableEscapeKeyDown
		>
			<Box sx={modalStyle}>
				{modalState === ModalState.Choice && (
					<>
						<Typography id="modal-title" variant="h6" sx={{ mb: 2 }}>
							Welcome aboard!
						</Typography>
						<Typography id="modal-description" sx={{ mb: 3 }}>
							Your account has been successfully created.
						</Typography>
						<Typography sx={{ mb: 3 }}>
							Please select the category that best describes you:
						</Typography>
						<Box sx={{ mb: 3, display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', gap: 2 }}>
							<Button
								onClick={changeToRetail}
								sx={{
									width: isMobile ? '100%' : '45%',
									height: 100,
									backgroundColor: '#f0f0f0',
									'&:hover': { backgroundColor: '#e0e0e0' },
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textTransform: 'none',
									borderRadius: 2,
									overflow: 'hidden',
								}}
							>
								<img src="https://argusweb.azureedge.net/argus-web/home/sign-up-argus-role-popup-retail.jpg"
								     alt="retail"
								     onClick={changeToRetail}
								     style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
								<Typography sx={{ position: 'absolute', color: '#eee', fontWeight: 'bold' }}>Retail</Typography>
							</Button>

							<Button
								onClick={changeToTrade}
								sx={{
									width: isMobile ? '100%' : '45%',
									height: 100,
									backgroundColor: '#f0f0f0',
									'&:hover': { backgroundColor: '#e0e0e0' },
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textTransform: 'none',
									borderRadius: 2,
									overflow: 'hidden',
								}}
							>
								<img src="https://argusweb.azureedge.net/argus-web/home/sign-up-argus-role-popup-trade.jpg"
								     alt="Trade"
								     onClick={changeToTrade}
								     style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
								<Typography sx={{ position: 'absolute', color: '#fff', fontWeight: 'bold' }}>Trade</Typography>
							</Button>
						</Box>
						<Box sx={{ mb: 3}}>
							<Link variant="body2" sx={{ color: 'primary.main', cursor: 'pointer'}} href="#"
							      onClick={closeModal}>
								(Skip for now)
							</Link>
						</Box>
					</>
				)}
				{modalState === ModalState.Retail && (
					<>
						<Typography id="modal-title" variant="h6" sx={{mb: 2}}>
							Thank you for selecting Retail!
						</Typography>
						<Typography sx={{mb: 3}}>
							To streamline your checkout process, please provide us with the following details:
						</Typography>
						<Box sx={{ mb: 3}}>
							<Link variant="body2" sx={{ color: 'primary.main', cursor: 'pointer'}} href="#"
							      onClick={closeModal}>
								(Skip for now)
							</Link>
						</Box>


						<form onSubmit={submit}>
							<Controller
								name="firstName"
								control={control}
								render={({field}) => (
									<TextField
										{...field}
										label="First Name"
										variant="outlined"
										fullWidth
										error={!!errors.firstName}
										helperText={errors.firstName ? errors.firstName.message : ''}
										sx={{mb: 2}}
									/>
								)}
							/>

							<Controller
								name="lastName"
								control={control}
								render={({field}) => (
									<TextField
										{...field}
										label="Last Name"
										variant="outlined"
										fullWidth
										error={!!errors.lastName}
										helperText={errors.lastName ? errors.lastName.message : ''}
										sx={{mb: 2}}
									/>
								)}
							/>

							<Controller
								name="mobile"
								control={control}
								render={({field}) => (
									<TextField
										{...field}
										label="Mobile Number"
										variant="outlined"
										fullWidth
										error={!!errors.mobile}
										helperText={errors.mobile ? errors.mobile.message : ''}
										sx={{mb: 2}}
									/>
								)}
							/>

							<Controller
								name="termsAndConditions"
								control={control}
								render={({field}) => (
									<FormControlLabel
										control={
											<Checkbox {...field} checked={field.value}/>
										}
										label="I accept the terms and conditions"
										sx={{mb: 2}}
									/>
								)}
							/>
							{errors.termsAndConditions &&
								<Typography variant="body2" color="error">
									{errors.termsAndConditions.message}
								</Typography>
							}

							<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
								<Button type="submit" variant="contained" color="primary">
									Submit
								</Button>
								<Button onClick={changeToChoice} variant="outlined" color="secondary">
									Cancel
								</Button>
							</Box>
						</form>
					</>
				)}
				{modalState === ModalState.Trade && (
					<>
						<Typography variant="h6" sx={{mb: 3}}>
							Thank you for selecting Trade!
						</Typography>
						<Typography sx={{mb: 5}}>
							Please continue to provide us with the required information to complete the registration of
							your trade account.
						</Typography>
						<Box sx={{ mb: 3}}>
							<Link variant="body2" sx={{ color: 'primary.main', cursor: 'pointer'}} href="#"
							      onClick={changeToRetail}>
								(Click here to continue as a Retail account)
							</Link>
						</Box>
						<Box sx={{ mb: 3}}>
							<Link variant="body2" sx={{ color: 'primary.main', cursor: 'pointer'}} href="#"
							      onClick={closeModal}>
								(Skip for now)
							</Link>
						</Box>
						<form onSubmit={submit}>
							<Typography variant="h6" sx={{mb: 3}}>
								Personal Details
							</Typography>

							<Controller
								name="firstName"
								control={control}
								render={({field}) => (
									<TextField
										{...field}
										label="First Name"
										variant="outlined"
										fullWidth
										error={!!errors.firstName}
										helperText={errors.firstName ? errors.firstName.message : ''}
										sx={{mb: "1rem"}}
									/>
								)}
							/>

							<Controller
								name="lastName"
								control={control}
								render={({field}) => (
									<TextField
										{...field}
										label="Last Name"
										variant="outlined"
										fullWidth
										error={!!errors.lastName}
										helperText={errors.lastName ? errors.lastName.message : ''}
										sx={{mb: "1rem"}}
									/>
								)}
							/>

							<Controller
								name="mobile"
								control={control}
								render={({field}) => (
									<TextField
										{...field}
										label="Mobile Number"
										variant="outlined"
										fullWidth
										error={!!errors.mobile}
										helperText={errors.mobile ? errors.mobile.message : ''}
										sx={{mb: "1rem"}}
									/>
								)}
							/>

							<Typography variant="h6" sx={{mb: "1rem"}}>
								Trade Account Details
							</Typography>

							<Controller
								name="accountType"
								control={control}
								render={({field}) => (
									<FormControl component="fieldset" error={!!errors.accountType} sx={{mb: "1rem"}}>
										<FormLabel component="legend">
											Are you registering for a company or in a personal capacity?
										</FormLabel>
										<RadioGroup {...field} row>
											<FormControlLabel value={RegistrationAccountType.TradePersonal} control={<Radio/>} label="Personal"/>
											<FormControlLabel value={RegistrationAccountType.TradeCompany} control={<Radio/>} label="Company"/>
										</RadioGroup>
										<FormHelperText>{errors.accountType ? errors.accountType.message : ''}</FormHelperText>
									</FormControl>
								)}
							/>

							{accountType === RegistrationAccountType.TradePersonal && (
								<Controller
									name="personalIdNumber"
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											label="ID Number"
											variant="outlined"
											fullWidth
											error={!!errors.personalIdNumber}
											helperText={errors.personalIdNumber ? errors.personalIdNumber.message : ''}
											sx={{mb: "1rem"}}
										/>
									)}
								/>
							)}

							{accountType === RegistrationAccountType.TradeCompany && (
								<Controller
									name="companyRegNo"
									control={control}
									render={({field}) => (
										<TextField
											{...field}
											label="Company Registration Number/Registered Trading Name"
											variant="outlined"
											fullWidth
											error={!!errors.companyRegNo}
											helperText={errors.companyRegNo ? errors.companyRegNo.message : ''}
											sx={{mb: "1rem"}}
										/>
									)}
								/>
							)}

							<Controller
								name="termsAndConditions"
								control={control}
								render={({field}) => (
									<FormControlLabel
										control={
											<Checkbox {...field} checked={field.value}/>
										}
										label="I accept the terms and conditions"
										sx={{mb: "1rem"}}
									/>
								)}
							/>
							{errors.termsAndConditions &&
								<Typography variant="body2" color="error">
									{errors.termsAndConditions.message}
								</Typography>
							}

							<Box sx={{display: 'flex', justifyContent: 'space-between', mt: "1rem"}}>
								<Button type="submit" variant="contained" color="primary">
									Submit
								</Button>
								<Button onClick={changeToChoice} variant="outlined" color="secondary">
									Cancel
								</Button>
							</Box>
						</form>
					</>
				)}
				{modalState === ModalState.InProgress && (
					<>
						<Typography variant="h6" sx={{mb: "1rem"}}>
							Loading
						</Typography>
						<CircularProgress />
					</>

				)}
				{modalState === ModalState.Success && !!resultMessage && (
					<>
						<Typography variant="body1" sx={{ mb: 5 }}>
							{resultMessage}
						</Typography>
						<Button onClick={closeModal} variant="contained" color="primary">
							Close
						</Button>
					</>
				)}
				{modalState === ModalState.Error && !!resultMessage && (
					<>
						<Typography variant="body2" sx={{ mb: 5 }}>
							{resultMessage}
						</Typography>
						<Button onClick={changeToChoice} variant="contained" color="primary">
							Try again
						</Button>
					</>
				)}
			</Box>
		</Modal>
	);
};

export default ArgusRoleModal;
