import React, { useEffect, useState } from "react";
import { cloneDeep, compact, find, join, trim } from "lodash";
import { CartSummary } from "../CartSummary";
import { useNavigate } from "react-router-dom";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import {
	Box,
	Button, Divider,
	Grid, IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";

const CheckoutAddressUi = {
	firstLoad: -1,
	noAddressOptions: 0,
	addressDropdown: 1,
	addressChosen: 3,
	error: 4
};

const CheckoutShippingAddress = ({
	checkoutData,
	countries,
	provinces,
	userAddresses,
	onSubmit
}) => {
	const {screenSize} = useSystemHook();
	const navigate = useNavigate();
	const [currentUiState, setCurrentUiState] = useState(
		CheckoutAddressUi.firstLoad
	);
	const [chosenAddressId, setChosenAddressId] = useState(null);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const onSaveAddressClick = async () => {
		if (chosenAddressId) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.shippingAddressId = chosenAddressId;
			checkoutDataClone.collectionAddressId = null;

			await onSubmit({checkoutData: checkoutDataClone});
		}
	};

	const onUseAddressClick = (addressId) => {
		setChosenAddressId(addressId);
	};

	const showAddressDropdown = () => {
		setCurrentUiState(CheckoutAddressUi.addressDropdown);
	};

	const showNoAddressOptions = () => {
		setCurrentUiState(CheckoutAddressUi.noAddressOptions);
	};

	const showAddAddressButton = () => {
		return (
			currentUiState === CheckoutAddressUi.noAddressOptions ||
			currentUiState === CheckoutAddressUi.addressDropdown
		);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!userAddresses && userAddresses.length > 0) {
			showAddressDropdown();
		}
		else {
			showNoAddressOptions();
		}
	}, [userAddresses]);

	useEffect(() => {
		if (checkoutData?.shippingAddressId) {
			setChosenAddressId(checkoutData.shippingAddressId);
		}
	}, [checkoutData]);

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
					{!!checkoutData && (
						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
								<Paper elevation={3} sx={{ padding: '1.5rem' }}>
									<Box sx={{ marginBottom: '1.5rem', display: 'flex', justifyContent: 'space-between' }}>
										<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontSize: '1.25rem' }}>
											<CheckCircleIcon sx={{ marginRight: '0.5rem', fontSize: '1.5rem' }} />
											Shipping Address
										</Typography>
										{showAddAddressButton() && (
											<Button
												variant="outlined"
												startIcon={<AddIcon />}
												onClick={() => navigate("/checkout/shipping/create")}
												sx={{ fontSize: '0.875rem' }}
											>
												Add Address
											</Button>
										)}
									</Box>
									{currentUiState === CheckoutAddressUi.noAddressOptions && (
										<Typography variant="body1" sx={{ color: 'gray' }}>
											No addresses found.
										</Typography>
									)}
									{currentUiState === CheckoutAddressUi.addressDropdown && (
										<List sx={{ width: '100%', backgroundColor: '#fafafa', borderRadius: '8px' }}>
											{userAddresses.map((address, index) => {
												const country = countries.find((x) => x.countryId === address.countryId);
												const province = provinces.find((x) => x.provinceId === address.provinceId);
												const displayName = `${address.firstName} ${address.lastName}`.trim();
												const addressArray = [
													address.streetAddress,
													address.suburb,
													address.city,
													address.postalCode,
													province?.name,
													country?.name,
												];
												const addressString = addressArray.filter(Boolean).join(', ');

												const isCurrent = chosenAddressId
													? address.addressId === chosenAddressId
													: checkoutData.shippingAddressId === address.addressId;

												return (
													<React.Fragment key={index}>
														<ListItem sx={{ paddingY: '0.5rem' }}>
															<ListItemText
																primary={displayName}
																secondary={addressString}
																sx={{ flexGrow: 1 }}
															/>
															{chosenAddressId === address.addressId ? (
																<IconButton color="success" disabled>
																	<CheckIcon />
																</IconButton>
															) : (
																<Button
																	variant="contained"
																	color="primary"
																	size="small"
																	onClick={() => onUseAddressClick(address.addressId)}
																	sx={{ textTransform: 'none' }}
																>
																	Use
																</Button>
															)}
														</ListItem>
														{index < userAddresses.length - 1 && <Divider />}
													</React.Fragment>
												);
											})}
										</List>
									)}
								</Paper>
							</Grid>
							<Grid item xs={12} md={4}>
								<CartSummary
									vat={checkoutData.vatPercentage}
									cartNettTotal={checkoutData.subtotal}
									settlementAmount={checkoutData.settlementAmount}
									vatAmount={checkoutData.vatAmount}
									logisticsFee={checkoutData.logisticsFee}
									total={checkoutData.total}
								/>
								{!!chosenAddressId && (
									<Button
										variant="contained"
										color="primary"
										fullWidth
										onClick={onSaveAddressClick}
										endIcon={<ArrowForwardIcon />}
										sx={{ marginTop: '1rem', fontSize: '0.875rem' }}
									>
										Continue
									</Button>
								)}
							</Grid>
						</Grid>
					)}
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '100vh', padding: '1rem', backgroundColor: '#f9f9f9' }}>
					<Typography variant="h5" sx={{ backgroundColor: '#ffffff', padding: '1rem', marginBottom: '1.5rem', textAlign: 'center' }}>
						Checkout
					</Typography>
					{!!checkoutData && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Paper elevation={3} sx={{ padding: '1rem' }}>
									<Box sx={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										<Typography variant="h6" sx={{ fontSize: '1.125rem', display: 'flex', alignItems: 'center' }}>
											<CheckCircleIcon sx={{ marginRight: '0.5rem', fontSize: '1.5rem' }} />
											Shipping Address
										</Typography>
										{showAddAddressButton() && (
											<Button
												variant="outlined"
												startIcon={<AddIcon />}
												onClick={() => navigate("/checkout/shipping/create")}
												sx={{ fontSize: '0.875rem' }}
											>
												Add
											</Button>
										)}
									</Box>
									{currentUiState === CheckoutAddressUi.noAddressOptions && (
										<Typography variant="body1" sx={{ color: 'gray', textAlign: 'center' }}>
											No addresses found.
										</Typography>
									)}
									{currentUiState === CheckoutAddressUi.addressDropdown && (
										<List sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: '8px' }}>
											{userAddresses.map((address, index) => {
												const country = countries.find((x) => x.countryId === address.countryId);
												const province = provinces.find((x) => x.provinceId === address.provinceId);
												const displayName = `${address.firstName} ${address.lastName}`.trim();
												const addressArray = [
													address.streetAddress,
													address.suburb,
													address.city,
													address.postalCode,
													province?.name,
													country?.name,
												];
												const addressString = addressArray.filter(Boolean).join(', ');

												const isCurrent = chosenAddressId
													? address.addressId === chosenAddressId
													: checkoutData.shippingAddressId === address.addressId;

												return (
													<React.Fragment key={index}>
														<ListItem sx={{ paddingY: '0.75rem' }}>
															<ListItemText
																primary={displayName}
																secondary={addressString}
																sx={{ flexGrow: 1 }}
															/>
															<ListItemSecondaryAction>
																<IconButton
																	color={isCurrent ? 'success' : 'primary'}
																	onClick={() => !isCurrent && onUseAddressClick(address.addressId)}
																	disabled={isCurrent}
																>
																	{isCurrent ? (
																		<CheckCircleIcon />
																	) : (
																		<Typography variant="button" sx={{ textTransform: 'none', fontSize: '0.875rem' }}>
																			Use
																		</Typography>
																	)}
																</IconButton>
															</ListItemSecondaryAction>
														</ListItem>
														{index < userAddresses.length - 1 && <Divider />}
													</React.Fragment>
												);
											})}
										</List>
									)}
								</Paper>
							</Grid>
							<Grid item xs={12}>
								<Paper elevation={3} sx={{ padding: '1rem', marginTop: '1.5rem' }}>
									<CartSummary
										vat={checkoutData.vatPercentage}
										cartNettTotal={checkoutData.subtotal}
										settlementAmount={checkoutData.settlementAmount}
										vatAmount={checkoutData.vatAmount}
										logisticsFee={checkoutData.logisticsFee}
										total={checkoutData.total}
									/>
									{!!chosenAddressId && (
										<Button
											variant="contained"
											color="primary"
											fullWidth
											onClick={onSaveAddressClick}
											endIcon={<ArrowForwardIcon />}
											sx={{ marginTop: '1rem', fontSize: '1rem', padding: '0.75rem 1.5rem' }}
										>
											Continue
										</Button>
									)}
								</Paper>
							</Grid>
						</Grid>
					)}
				</Box>
			}
		</>
	);
};

export default CheckoutShippingAddress;
