import React, { useEffect, useState } from "react";
import { AccountType, LogisticsMethod, ScreenSize } from "../../utils/constants";
import { find } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Button, Divider, Grid, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import PageHeader from "../PageHeader";

const CheckoutSummary = ({
	checkoutData,
	accountType,
	billingAddresses,
	shippingAddresses,
	collectionAddresses,
	salesPeople,
	tecfinityAccountName,
	tecfinityAccountNumber,
	countries,
	provinces,
	gotoCheckoutOrderInformation,
	gotoCheckoutBillingAddress,
	gotoCheckoutCollectionAddress,
	gotoCheckoutDeliveryMethod,
	gotoCheckoutShippingAddress,
	onSubmit
}) => {
	const {screenSize} = useSystemHook();
	const [salesPerson, setSalesPerson] = useState(null);
	const [billingAddress, setBillingAddress] = useState(null);
	const [shippingAddress, setShippingAddress] = useState(null);
	const [collectionAddress, setCollectionAddress] = useState(null);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (
			!!billingAddresses &&
			!!checkoutData?.billingAddressId &&
			!!countries &&
			!!provinces
		) {
			const address = find(
				billingAddresses,
				(x) => x.addressId === checkoutData.billingAddressId
			);
			if (address) {
				const country = find(
					countries,
					(x) => x.countryId === address.countryId
				);
				const province = find(
					provinces,
					(x) => x.provinceId === address.provinceId
				);

				address.country = country.name ?? "";
				address.province = province.name ?? "";

				setBillingAddress(address);
			}
		}
	}, [checkoutData?.billingAddressId, billingAddresses, countries, provinces]);

	useEffect(() => {
		if (
			!!shippingAddresses &&
			!!checkoutData?.shippingAddressId &&
			!!countries &&
			!!provinces
		) {
			const address = find(
				shippingAddresses,
				(x) => x.addressId === checkoutData.shippingAddressId
			);
			if (address) {
				const country = find(
					countries,
					(x) => x.countryId === address.countryId
				);
				const province = find(
					provinces,
					(x) => x.provinceId === address.provinceId
				);

				address.country = country.name ?? "";
				address.province = province.name ?? "";

				setShippingAddress(address);
			}
		}
	}, [
		checkoutData?.shippingAddressId,
		shippingAddresses,
		countries,
		provinces
	]);

	useEffect(() => {
		if (
			!!collectionAddresses &&
			!!checkoutData?.collectionAddressId &&
			!!countries &&
			!!provinces
		) {
			const address = find(
				collectionAddresses,
				(x) => x.addressId === checkoutData.collectionAddressId
			);
			if (address) {
				const country = find(
					countries,
					(x) => x.countryId === address.countryId
				);
				const province = find(
					provinces,
					(x) => x.provinceId === address.provinceId
				);

				address.country = country.name ?? "";
				address.province = province.name ?? "";

				setCollectionAddress(address);
			}
		}
	}, [
		checkoutData?.collectionAddressId,
		collectionAddresses,
		countries,
		provinces
	]);

	useEffect(() => {
		if (!!checkoutData?.salesPersonCode && !!salesPeople) {
			const person = find(
				salesPeople,
				(x) => x.code === checkoutData.salesPersonCode
			);
			setSalesPerson(person);
		}
	}, [checkoutData?.salesPersonCode]);

	const onGoToPaymentClick = async () => {
		await onSubmit(checkoutData);
	};

	return (
		<>
			<PageHeader title="Checkout" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>

					{!!checkoutData && (
						<Grid container spacing={3}>
							<Grid item xs={12} md={8}>
								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1.5rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
										<ShoppingCartIcon sx={{ marginRight: '0.5rem' }} />
										Order Information
										<Button
											variant="outlined"
											startIcon={<EditIcon />}
											sx={{ marginLeft: 'auto' }}
											onClick={gotoCheckoutOrderInformation}
										>
											Change
										</Button>
									</Typography>

									<List disablePadding>
										<ListItem>
											<ListItemText primary={<Typography variant="caption"><b>Email:</b> {checkoutData.email}</Typography>} />
										</ListItem>
										{accountType === AccountType.Commercial && (
											<ListItem>
												<ListItemText primary={<Typography variant="caption"><b>Account:</b> {`${checkoutData.accountName} - ${checkoutData.accountNumber}`}</Typography>} />
											</ListItem>
										)}
										{salesPerson && (
											<ListItem>
												<ListItemText primary={<Typography variant="caption"><b>Sales Person:</b> {salesPerson.name}</Typography>} />
											</ListItem>
										)}
									</List>
								</Paper>

								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1.5rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
										<HomeIcon sx={{ marginRight: '0.5rem' }} />
										Billing Address
										<Button
											variant="outlined"
											startIcon={<EditIcon />}
											sx={{ marginLeft: 'auto' }}
											onClick={gotoCheckoutBillingAddress}
										>
											Change
										</Button>
									</Typography>

									<List disablePadding>
										<ListItem>
											<ListItemText primary={<Typography variant="caption">{billingAddress?.firstName} {billingAddress?.lastName}</Typography>} />
										</ListItem>
										<ListItem>
											<ListItemText primary={<Typography variant="caption">{billingAddress?.streetAddress}, {billingAddress?.city}</Typography>} />
										</ListItem>
										<ListItem>
											<ListItemText primary={<Typography variant="caption">{billingAddress?.province}, {billingAddress?.country}</Typography>} />
										</ListItem>
									</List>
								</Paper>

								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1.5rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
										<LocalShippingIcon sx={{ marginRight: '0.5rem' }} />
										Delivery Method
										<Button
											variant="outlined"
											startIcon={<EditIcon />}
											sx={{ marginLeft: 'auto' }}
											onClick={gotoCheckoutDeliveryMethod}
										>
											Change
										</Button>
									</Typography>

									<Typography variant="body2">
										{checkoutData.logisticsMethod === LogisticsMethod.Ship ? 'Delivery' : 'Collection'}
									</Typography>

									{checkoutData.logisticsMethod === LogisticsMethod.Ship && (
										<>
											<Divider sx={{ my: '1.5rem' }} />
											<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
												<LocalShippingIcon sx={{ marginRight: '0.5rem' }} />
												Shipping Address
												<Button
													variant="outlined"
													startIcon={<EditIcon />}
													sx={{ marginLeft: 'auto' }}
													onClick={gotoCheckoutShippingAddress}
												>
													Change
												</Button>
											</Typography>
											<List disablePadding>
												<ListItem>
													<ListItemText primary={<Typography variant="caption">{shippingAddress?.firstName} {shippingAddress?.lastName}</Typography>} />
												</ListItem>
												<ListItem>
													<ListItemText primary={<Typography variant="caption">{shippingAddress?.streetAddress}, {shippingAddress?.city}</Typography>} />
												</ListItem>
												<ListItem>
													<ListItemText primary={<Typography variant="caption">{shippingAddress?.province}, {shippingAddress?.country}</Typography>} />
												</ListItem>
											</List>
										</>
									)}
								</Paper>
							</Grid>

							<Grid item xs={12} md={4}>
								<CartSummary
									vat={checkoutData.vatPercentage}
									cartNettTotal={checkoutData.subtotal}
									settlementAmount={checkoutData.settlementAmount}
									vatAmount={checkoutData.vatAmount}
									logisticsFee={checkoutData.logisticsFee}
									total={checkoutData.total}
								/>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={onGoToPaymentClick}
									endIcon={<ArrowForwardIcon />}
									sx={{ marginTop: '1.5rem' }}
								>
									Go to Payment
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>
			}
		</>
	);
};

export default CheckoutSummary;
