import React, { useEffect, useState } from "react";
import { DeliveryMethod, LogisticsMethod, ScreenSize } from "../../utils/constants";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { SouthAfricanRand } from "../../utils/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import PageHeader from "../PageHeader";

const CheckoutDeliveryMethod = ({
	checkoutData,
	chosenLogisticsMethod,
	setChosenLogisticsMethod,
	onSubmit
}) => {
	const {screenSize} = useSystemHook();
	const [showError, setShowError] = useState(false);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	const handleDeliveryMethodClick = async (deliveryMethod) => {
		let logisticsMethod;
		if (deliveryMethod === DeliveryMethod.Ship) {
			logisticsMethod = LogisticsMethod.Ship;
		}
		else if (deliveryMethod === DeliveryMethod.Collection) {
			logisticsMethod = LogisticsMethod.Collection;
		}

		setShowError(false);
		setChosenLogisticsMethod(logisticsMethod);
	};

	const onSaveClick = async () => {
		if (chosenLogisticsMethod) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.logisticsMethod = chosenLogisticsMethod;
			checkoutDataClone.shippingAddressId = null;
			checkoutDataClone.collectionAddressId = null;

			await onSubmit({
				checkoutData: checkoutDataClone
			});
		}
	};

	let showAsCurrent = null;
	if (chosenLogisticsMethod) {
		showAsCurrent = chosenLogisticsMethod;
	}
	else if (checkoutData?.logisticsMethod) {
		showAsCurrent = checkoutData.logisticsMethod;
	}

	return (
		<>
			<PageHeader title="Checkout" />
			{!!checkoutData && (
				<Box sx={{ p: 2 }}>
					{screenSize === ScreenSize.Desktop &&
						<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={8}>
									{showError && (
										<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem', backgroundColor: '#ffe6e6', color: '#d32f2f' }}>
											<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
												<ErrorOutlineIcon /> Something went wrong
											</Typography>
											<Typography>Please choose a delivery method</Typography>
										</Paper>
									)}

									<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem' }}>
										<Box display="flex" justifyContent="space-between" alignItems="center">
											<Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
												<LocalShippingIcon /> Delivery
											</Typography>
											<Button
												variant={chosenLogisticsMethod === LogisticsMethod.Ship ? 'contained' : 'outlined'}
												color={chosenLogisticsMethod === LogisticsMethod.Ship ? 'success' : 'primary'}
												onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}
												startIcon={chosenLogisticsMethod === LogisticsMethod.Ship && <CheckIcon />}
											>
												{chosenLogisticsMethod === LogisticsMethod.Ship ? 'Selected' : 'Choose'}
											</Button>
										</Box>
										<Divider sx={{ margin: '1rem 0' }} />
										<Typography variant="body1">
											Orders received Monday to Thursday before 9am will be dispatched the same day.
										</Typography>
										<Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
											Orders received after 9am on Friday to Sunday will be dispatched on the following Monday.
										</Typography>
										<Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
											Delivery typically takes between 2 and 3 working days to all main centers.
										</Typography>
										<Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
											Smaller cities and rural areas may take 5 to 7 working days.
										</Typography>
									</Paper>

									<Paper elevation={3} sx={{ padding: '1rem' }}>
										<Box display="flex" justifyContent="space-between" alignItems="center">
											<Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
												<StoreIcon /> Collection
											</Typography>
											<Button
												variant={chosenLogisticsMethod === LogisticsMethod.Collection ? 'contained' : 'outlined'}
												color={chosenLogisticsMethod === LogisticsMethod.Collection ? 'success' : 'primary'}
												onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}
												startIcon={chosenLogisticsMethod === LogisticsMethod.Collection && <CheckIcon />}
											>
												{chosenLogisticsMethod === LogisticsMethod.Collection ? 'Selected' : 'Choose'}
											</Button>
										</Box>
										<Divider sx={{ margin: '1rem 0' }} />
										<Typography variant="body1">
											Orders are generally ready within 60 minutes from confirmation of payment.
										</Typography>
										<Box sx={{ marginTop: '1rem' }}>
											<Typography variant="body2"><b>Collection address:</b></Typography>
											<Typography variant="body2">28 Great North Road, Brentwood Park, Benoni, 1501</Typography>
										</Box>
										<Box sx={{ marginTop: '1rem' }}>
											<Typography variant="body2"><b>Collection times:</b></Typography>
											<Typography variant="body2">Mon-Thu 8:00am-4:30pm</Typography>
											<Typography variant="body2">Fri: 8:00am-1:30pm</Typography>
										</Box>
									</Paper>
								</Grid>

								<Grid item xs={12} md={4}>
									<CartSummary
										vat={checkoutData.vatPercentage}
										cartNettTotal={checkoutData.subtotal}
										settlementAmount={checkoutData.settlementAmount}
										vatAmount={checkoutData.vatAmount}
										total={checkoutData.total}
									/>

									<Button variant="contained"
									        color="primary"
									        fullWidth
											onClick={onSaveClick}
											disabled={!chosenLogisticsMethod}
											endIcon={<ArrowForwardIcon />}
											sx={{ marginTop: '0.75rem', fontSize: '0.875rem' }}>
										Continue
									</Button>
								</Grid>
							</Grid>
						</Box>
					}
					{screenSize === ScreenSize.Mobile &&
						<Box sx={{ padding: '1rem', backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
							{showError && (
								<Paper elevation={2} sx={{ padding: '1rem', backgroundColor: '#ffe6e6', color: '#d32f2f', marginBottom: '1rem' }}>
									<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<ErrorOutlineIcon /> Something went wrong
									</Typography>
									<Typography>Please choose a delivery method</Typography>
								</Paper>
							)}

							<Paper elevation={2} sx={{ padding: '1rem', marginBottom: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<LocalShippingIcon /> Delivery
									</Typography>
									<Button
										variant={chosenLogisticsMethod === LogisticsMethod.Ship ? 'contained' : 'outlined'}
										color={chosenLogisticsMethod === LogisticsMethod.Ship ? 'success' : 'primary'}
										onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}
										startIcon={chosenLogisticsMethod === LogisticsMethod.Ship && <CheckIcon />}
									>
										{chosenLogisticsMethod === LogisticsMethod.Ship ? 'Selected' : 'Choose'}
									</Button>
								</Box>
								<Divider sx={{ margin: '1rem 0' }} />
								<Typography variant="body2">
									Orders received Monday to Thursday before 9am will be dispatched the same day.
								</Typography>
								<Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
									Orders received after 9am on Friday to Sunday will be dispatched on the following Monday.
								</Typography>
								<Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
									Delivery typically takes between 2 and 3 working days to all main centers.
								</Typography>
								<Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
									Smaller cities and rural areas may take 5 to 7 working days.
								</Typography>
							</Paper>

							<Paper elevation={2} sx={{ padding: '1rem', marginBottom: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<StoreIcon /> Collection
									</Typography>
									<Button
										variant={chosenLogisticsMethod === LogisticsMethod.Collection ? 'contained' : 'outlined'}
										color={chosenLogisticsMethod === LogisticsMethod.Collection ? 'success' : 'primary'}
										onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}
										startIcon={chosenLogisticsMethod === LogisticsMethod.Collection && <CheckIcon />}
									>
										{chosenLogisticsMethod === LogisticsMethod.Collection ? 'Selected' : 'Choose'}
									</Button>
								</Box>
								<Divider sx={{ margin: '1rem 0' }} />
								<Typography variant="body2">
									Orders are generally ready within 60 minutes from confirmation of payment.
								</Typography>
								<Box sx={{ marginTop: '1rem' }}>
									<Typography variant="body2"><b>Collection address:</b></Typography>
									<Typography variant="body2">28 Great North Road, Brentwood Park, Benoni, 1501</Typography>
								</Box>
								<Box sx={{ marginTop: '1rem' }}>
									<Typography variant="body2"><b>Collection times:</b></Typography>
									<Typography variant="body2">Mon-Thu 8:00am-4:30pm</Typography>
									<Typography variant="body2">Fri: 8:00am-1:30pm</Typography>
								</Box>
							</Paper>

							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={onSaveClick}
								disabled={!chosenLogisticsMethod}
								endIcon={<ArrowForwardIcon />}
								sx={{ textTransform: 'none', borderRadius: '8px' }}
							>
								Continue
							</Button>
						</Box>
					}
				</Box>
			)}
		</>
	);
};

export default CheckoutDeliveryMethod;
