import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import PaymentCreditCardOnCollection from "../../components/PaymentCreditCardOnCollection";
import {
	ApiCallStatus,
	PaymentMethod
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { removeAuthCookies } from "../../api/authentication";
import {
	createCreditCardOnCollectionPaymentData,
	fetchPaymentOrderData
} from "../PageFunctions/paymentFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const PaymentCreditCardOnCollectionPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [paymentOrderInfo, setPaymentOrderInfo] = useState(null);

	const [paymentOrderInfoCallStatus, setPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createCreditCardOnCollectionPaymentCallStatus, setCreateCreditCardOnCollectionPaymentCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoOrderConfirmationPage = () => navigate(`/order-confirmation`);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};


	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchPaymentOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPaymentOrderData({
			auth0Token,
			paymentType: PaymentMethod.CardOnCollection,
			setPaymentOrderInfo,
			setStatusInProgress: () => setPaymentOrderInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const createCreditCardOnCollectionPaymentDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createCreditCardOnCollectionPaymentData({
			auth0Token,
			setStatusInProgress: () => setCreateCreditCardOnCollectionPaymentCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreateCreditCardOnCollectionPaymentCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreateCreditCardOnCollectionPaymentCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreateCreditCardOnCollectionPaymentCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {

	};

	const unAuthorizedPageLoad = async () => {
		await fetchPaymentOrderDataAsync();
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (createCreditCardOnCollectionPaymentCallStatus === ApiCallStatus.Succeeded) {
			gotoOrderConfirmationPage();
		}
	}, [createCreditCardOnCollectionPaymentCallStatus]);

	const allLoadingStates = [
		paymentOrderInfoCallStatus,
		createCreditCardOnCollectionPaymentCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<PaymentCreditCardOnCollection
				paymentOrderInfo={paymentOrderInfo}
				onSubmit={createCreditCardOnCollectionPaymentDataAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default PaymentCreditCardOnCollectionPage;
