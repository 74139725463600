import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#f5f5f5', // Light gray for a clean, neutral base
		},
		secondary: {
			main: 'rgb(203, 24, 24)', // Red for accents and important elements
		},
		warning: {
			main: 'rgba(255, 241, 0, 1)', // Yellow, used sparingly for highlights
		},
		background: {
			default: '#ffffff', // Pure white background for a clean look
			paper: '#f5f5f5', // Light gray for cards, modals, etc.
		},
		text: {
			primary: '#333333', // Dark gray for main text
			secondary: '#666666', // Medium gray for secondary text
		},
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		h1: {
			fontSize: '2rem',
			lineHeight: '3rem',
			fontWeight: 500,
		},
		h2: {
			fontSize: '1.5rem',
			lineHeight: '2.25rem',
			fontWeight: 500,
		},
		h3: {
			fontSize: '1.25rem',
			lineHeight: '1.875rem',
			fontWeight: 500,
		},
		body1: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
		},
		body2: {
			fontSize: '0.875rem',
			lineHeight: '1.3125rem',
		},
		button: {
			textTransform: 'none',
			fontWeight: 600,
		},
	},
	shape: {
		borderRadius: 8,
	},
	shadows: [
		'none',  // elevation 0
		'0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',  // elevation 1
		'0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',  // elevation 2
		'0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',  // elevation 3
		'0px 2px 4px rgba(0, 0, 0, 0.2), 0px 3px 6px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',  // elevation 4
		'0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 7px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',  // elevation 5
		'0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',  // elevation 6
		'0px 4px 5px rgba(0, 0, 0, 0.2), 0px 7px 10px rgba(0, 0, 0, 0.14), 0px 2px 16px rgba(0, 0, 0, 0.12)',  // elevation 7
		'0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)',  // elevation 8
		'0px 5px 6px rgba(0, 0, 0, 0.2), 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12)',  // elevation 9
		'0px 6px 6px rgba(0, 0, 0, 0.2), 0px 10px 14px rgba(0, 0, 0, 0.14), 0px 4px 18px rgba(0, 0, 0, 0.12)',  // elevation 10
		'0px 6px 7px rgba(0, 0, 0, 0.19), 0px 11px 15px rgba(0, 0, 0, 0.13), 0px 5px 20px rgba(0, 0, 0, 0.11)',  // elevation 11
		'0px 7px 8px rgba(0, 0, 0, 0.19), 0px 12px 17px rgba(0, 0, 0, 0.13), 0px 5px 22px rgba(0, 0, 0, 0.11)',  // elevation 12
		'0px 7px 8px rgba(0, 0, 0, 0.19), 0px 13px 19px rgba(0, 0, 0, 0.13), 0px 6px 24px rgba(0, 0, 0, 0.11)',  // elevation 13
		'0px 7px 9px rgba(0, 0, 0, 0.19), 0px 14px 21px rgba(0, 0, 0, 0.13), 0px 6px 26px rgba(0, 0, 0, 0.11)',  // elevation 14
		'0px 8px 9px rgba(0, 0, 0, 0.18), 0px 15px 22px rgba(0, 0, 0, 0.12), 0px 7px 28px rgba(0, 0, 0, 0.10)',  // elevation 15
		'0px 8px 10px rgba(0, 0, 0, 0.18), 0px 16px 24px rgba(0, 0, 0, 0.12), 0px 7px 30px rgba(0, 0, 0, 0.10)',  // elevation 16
		'0px 8px 11px rgba(0, 0, 0, 0.18), 0px 17px 26px rgba(0, 0, 0, 0.12), 0px 8px 32px rgba(0, 0, 0, 0.10)',  // elevation 17
		'0px 9px 11px rgba(0, 0, 0, 0.18), 0px 18px 28px rgba(0, 0, 0, 0.12), 0px 8px 34px rgba(0, 0, 0, 0.10)',  // elevation 18
		'0px 9px 12px rgba(0, 0, 0, 0.17), 0px 19px 29px rgba(0, 0, 0, 0.11), 0px 8px 36px rgba(0, 0, 0, 0.09)',  // elevation 19
		'0px 10px 13px rgba(0, 0, 0, 0.17), 0px 20px 31px rgba(0, 0, 0, 0.11), 0px 9px 38px rgba(0, 0, 0, 0.09)',  // elevation 20
		'0px 10px 13px rgba(0, 0, 0, 0.17), 0px 21px 33px rgba(0, 0, 0, 0.11), 0px 9px 40px rgba(0, 0, 0, 0.09)',  // elevation 21
		'0px 10px 14px rgba(0, 0, 0, 0.17), 0px 22px 35px rgba(0, 0, 0, 0.11), 0px 10px 42px rgba(0, 0, 0, 0.09)',  // elevation 22
		'0px 11px 14px rgba(0, 0, 0, 0.16), 0px 23px 36px rgba(0, 0, 0, 0.10), 0px 10px 44px rgba(0, 0, 0, 0.08)',  // elevation 23
		'0px 11px 15px rgba(0, 0, 0, 0.16), 0px 24px 38px rgba(0, 0, 0, 0.10), 0px 10px 46px rgba(0, 0, 0, 0.08)'  // elevation 24
	],
	components: {
		MuiPaper: {
			styleOverrides: {
				elevation4: {
					boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 3px 6px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '0.5rem',
					backgroundColor: 'rgb(203, 24, 24)',
					color: '#FFFFFF',
					'&:hover': {
						backgroundColor: 'rgba(203, 24, 24, 0.8)',
					},
					fontWeight: "400",
					fontSize: '0.775rem',
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: '#f5f5f5',
					color: '#333333',
					boxShadow: 'none',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: '#f5f5f5',
					borderRadius: '0.5rem',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
					borderRadius: '0.5rem',
					backgroundColor: '#ffffff', // White for card backgrounds
				},
			},
		},
	},
});

export default theme;
