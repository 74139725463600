import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

const Error = () => {
	const navigate = useNavigate();

	return (
		<div className="argus-min-height">
			<Grid container>
				<Grid.Row>
					<Grid.Column>
						<div className="notfound-box">
							<h3>Error</h3>

							<p onClick={() => navigate(-1)}>
								There was an error.
							</p>

							<Link to="/">
								Go back to the home page.
							</Link>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default Error;
