import { FulfillmentStatus } from "../../utils/constants";
import {
	createOrder,
	deleteOrderCookies, getOrderConfirmationData,
	getOrderData, getOrderDataById
} from "../../api/orderController";

export const createOrderData = async ({
	auth0Token,
	salesPersonCode,
	logisticsMethod,
	billingAddressId,
	shippingAddressId,
	collectionAddressId,
	items,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await createOrder(
			{
				salesPersonCode: salesPersonCode ?? "155",
				logisticsMethod: logisticsMethod,
				orderItems: items.map((item) => ({
					sku: item.sku,
					quantity: item.quantity,
					fulfillmentStatusId: FulfillmentStatus.New
				})),
				billingAddressId: billingAddressId,
				shippingAddressId: shippingAddressId,
				collectionAddressId: collectionAddressId
			},
			auth0Token
		);

		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchOrderData = async ({
	auth0Token,
	setOrderData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getOrderData(auth0Token);
		if (!!response?.success) {
			setOrderData(response?.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchOrderDataById = async ({
	auth0Token,
	orderId,
	setOrderData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getOrderDataById(auth0Token, orderId);
		if (!!response?.success) {
			setOrderData(response?.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchOrderConfirmationData = async ({
	auth0Token,
	orderId,
	setOrderData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getOrderConfirmationData(auth0Token, orderId);
		if (!!response?.success) {
			setOrderData(response?.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const removeOrderCookies = async ({
	auth0Token,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await deleteOrderCookies(auth0Token);
		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
