import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import PageHeader from "../PageHeader";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PrivacyStatement = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const privacyPolicy = [
		{
			title: "SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?",
			content:
			`
				<p>
					When you purchase something from our store, as part of the buying
					and selling process, we collect the personal information you give
					us such as your name, address and email address.
				</p>
					
				<p>
					Email marketing (if applicable): With your permission, we may send
					you emails about our store, new products and other updates.
				</p>
			`
		},
		{
			title: "SECTION 2 - CONSENT",
			content: `

				<p>How do you get my consent?</p>

				<p>
					When you provide us with personal information to complete a
					transaction, verify your credit card, place an order, arrange for
					a delivery or return a purchase, we imply that you consent to our
					collecting it and using it for that specific reason only.
				</p>

				<p>
					If we ask for your personal information for a secondary reason,
					like marketing, we will either ask you directly for your expressed
					consent, or provide you with an opportunity to say no.
				</p>
				<p>How do I withdraw my consent?</p>

				<p>
					If after you opt-in, you change your mind, you may withdraw your
					consent for us to contact you, for the continued collection, use
					or disclosure of your information, at anytime, by contacting us at 
					<a href="mailto:info@argusmotoring.co.za">info@argusmotoring.co.za</a> 
					or mailing us at:
				</p>

				<p>Argus Motor Company</p>
				<a href="https://goo.gl/maps/RbgTFmVshrD2WJYu8" target="_blank" >
					28 Great North Road, Brentwood Park, 1501, South Africa
				</a>`
		},
		{
			title: "SECTION 3 - DISCLOSURE",
			content: `
				<p>
					We may disclose your personal information if we are required by
					law to do so or if you violate our Terms of Service.
				</p>`
		},
		{
			title: "SECTION 4 - Azure",
			content: `
				<p>
					Our store is hosted on Microsoft Azure, They provide us with the
					platform that allows us to host our website and sell our products
					and services to you.
				</p>
				`
		},
		{
			title: "SECTION 5 - THIRD-PARTY SERVICES",
			content: `
				<p>
					In general, the third-party providers used by us will only
					collect, use and disclose your information to the extent necessary
					to allow them to perform the services they provide to us.
				</p>
				<p>
					For these providers, we recommend that you read their privacy
					policies so you can understand the manner in which your personal
					information will be handled by these providers.
				</p>

				<p>
					In particular, remember that certain providers may be located in
					or have facilities that are located a different jurisdiction than
					either you or us. So if you elect to proceed with a transaction
					that involves the services of a third-party service provider, then
					your information may become subject to the laws of the
					jurisdiction(s) in which that service provider or its facilities
					are located.
				</p>
				<p>
					As an example, if you are located in Canada and your transaction
					is processed by a payment gateway located in the United States,
					then your personal information used in completing that transaction
					may be subject to disclosure under United States legislation,
					including the Patriot Act.
				</p>
				<p>
					Once you leave our store’s website or are redirected to a
					third-party website or application, you are no longer governed by
					this Privacy Policy or our website’s Terms of Service.
				</p>
				<p>Links</p>
				<p>
					When you click on links on our store, they may direct you away
					from our site. We are not responsible for the privacy practices of
					other sites and encourage you to read their privacy statements.
				</p>
`
		},
		{
			title: "SECTION 6 - SECURITY",
			content: `
				<p>
					To protect your personal information, we take reasonable
					precautions and follow industry best practices to make sure it is
					not inappropriately lost, misused, accessed, disclosed, altered or
					destroyed.
				</p>
				<p>
					If you provide us with your credit card information, the
					information is encrypted using secure socket layer technology
					(SSL) and stored with a AES-256 encryption. Although no method of
					transmission over the Internet or electronic storage is 100%
					secure, we follow all PCI-DSS requirements and implement
					additional generally accepted industry standards.
				</p>
`
		},
		{
			title: "SECTION 7 - AGE OF CONSENT",
			content: `
				<p>
					By using this site, you represent that you are at least the age of
					majority in your state or province of residence, or that you are
					the age of majority in your state or province of residence and you
					have given us your consent to allow any of your minor dependents
					to use this site.
				</p>
`
		},
		{
			title: "SECTION 8 - CHANGES TO THIS PRIVACY POLICY",
			content: `
				<p>
					We reserve the right to modify this privacy policy at any time, so
					please review it frequently. Changes and clarifications will take
					effect immediately upon their posting on the website. If we make
					material changes to this policy, we will notify you here that it
					has been updated, so that you are aware of what information we
					collect, how we use it, and under what circumstances, if any, we
					use and/or disclose it.
				</p>
				<p>
					If our store is acquired or merged with another company, your
					information may be transferred to the new owners so that we may
					continue to sell products to you.
				</p>
`
		},
		{
			title: "QUESTIONS AND CONTACT INFORMATION",
			content: `
				<p>
					If you would like to: access, correct, amend or delete any
					personal information we have about you, register a complaint, or
					simply want more information contact our Privacy Compliance
					Officer at <a href="mailto:info@argusmotoring.co.za">info@argusmotoring.co.za</a> or by mail at
				</p>
				<p>Argus Motor Company</p>
				<a href="https://goo.gl/maps/RbgTFmVshrD2WJYu8" target="_blank" rel="noreferrer">
					28 Great North Road, Brentwood Park, 1501, South Africa
				</a >
			`
		},

	]
	return (
		<Fragment>
			<PageHeader title="Privacy Policy" />
			{isMobile === true &&
				<>
					<Box sx={{ p: 2 }}>
						<Container>
							{privacyPolicy.map((section, index) => (
								<Accordion key={index} sx={{ mb: 1 }}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel${index}-content`}
										id={`panel${index}-header`}
									>
										<Typography variant="subtitle1">{section.title}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="body2"
										            color="textSecondary"
										            dangerouslySetInnerHTML={{ __html: section.content }} />
									</AccordionDetails>
								</Accordion>
							))}
						</Container>
					</Box>

				</>
			}
			{isMobile === false &&
				<Box sx={{ p: 4 }}>
					<Container maxWidth="md">
						{privacyPolicy.map((section, index) => (
							<Accordion key={index} sx={{ mb: 2 }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}-content`}
									id={`panel${index}-header`}
								>
									<Typography variant="h6">{section.title}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: section.content }} />
								</AccordionDetails>
							</Accordion>
						))}
					</Container>
				</Box>
			}
		</Fragment>
	);
};
export default PrivacyStatement;
