import React from 'react';
import { SouthAfricanRand } from "../../utils/helpers";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";

const DesktopCartSummary = ({
	vat,
	settlementAmount,
	cartNettTotal,
	total,
	vatAmount,
	logisticsFee,
	deliveryFeeToDisplay
}) => {
	return (
		<Paper elevation={4} sx={{ padding: 3, borderRadius: 2 }}>
			<Box sx={{ mb: 2 }}>
				<Typography variant="body2" fontWeight="bold" align="center">
					Order Summary
				</Typography>
			</Box>
			<Divider sx={{ mb: 2 }} />

			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Typography variant="caption">Nett Total</Typography>
				</Grid>
				<Grid item xs={6} textAlign="right">
					<Typography variant="caption" fontWeight="bold">
						{SouthAfricanRand.format(cartNettTotal)}
					</Typography>
				</Grid>

				{!!settlementAmount && settlementAmount > 0 && (
					<>
						<Grid item xs={6}>
							<Typography variant="caption">Settlement Discount</Typography>
						</Grid>
						<Grid item xs={6} textAlign="right">
							<Typography variant="caption" color="error">
								-{SouthAfricanRand.format(settlementAmount)}
							</Typography>
						</Grid>
					</>
				)}

				<Grid item xs={6}>
					<Typography variant="caption">VAT ({vat}%)</Typography>
				</Grid>
				<Grid item xs={6} textAlign="right">
					<Typography variant="caption" fontWeight="bold">
						{SouthAfricanRand.format(vatAmount)}
					</Typography>
				</Grid>

				{!!logisticsFee && (
					<>
						<Grid item xs={6}>
							<Typography variant="caption">Delivery Fee</Typography>
						</Grid>
						<Grid item xs={6} textAlign="right">
							<Typography variant="caption" fontWeight="bold">
								{deliveryFeeToDisplay()}
							</Typography>
						</Grid>
					</>
				)}

				<Divider sx={{ width: '100%', my: 2 }} />

				<Grid item xs={6}>
					<Typography variant="body2" fontWeight="bold">
						Total
					</Typography>
				</Grid>
				<Grid item xs={6} textAlign="right">
					<Typography variant="body2" fontWeight="bold">
						{SouthAfricanRand.format(total)}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default DesktopCartSummary;