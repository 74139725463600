import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import DOMPurify from "dompurify";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PageHeader from "../PageHeader";



const Faq = () => {

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const sanitizedData = (html) => ({
		__html: DOMPurify.sanitize(html)
	});

	const shippingInfo = {
		heading: "Shipping information",
		sectionContent: [
			{
				key: 1,
				title: "What are the shipping options available for orders from Argus?",
				content: `
					<p>
						At Argus, we offer convenient shipping options to ensure your
						motor spares reach you efficiently. For orders within our local
						and regional areas, we utilize our own fleet of vehicles for
						delivery. We take pride in providing reliable and timely service
						using our dedicated team. For orders that require delivery further
						afield, we have partnered with RAM couriers, a trusted logistics
						provider, to ensure your order reaches you securely.
					</p>
				`
			},
			{
				key: 2,
				title: 'Are there any shipping fees associated with orders from Argus?',
				content: `
					<p>
			            We are pleased to inform you that Argus offers free shipping on
			            orders over R2500. This applies to both local and regional
			            deliveries within our coverage areas. It&apos;s our way of providing
			            added value and convenience to our valued customers.
			        </p>
					`
			},
			{
				key: 3,
				title: 'Do you ship internationally?',
				content: `
					<p>
		                Yes, at Argus, we cater to international customers as well. We
		                regularly ship to countries within the Southern African
		                Development Community (SADC) region. If you are located outside of
		                the SADC countries and require international shipping, we can
		                accommodate your request. We have experience in shipping to
		                various destinations worldwide and can provide the necessary
		                documentation to facilitate the process.
		            </p>
		            <p>
		                For further details on international shipping, including shipping
		                fees, delivery times, and specific requirements for your location,
		                we kindly request international customers to contact our dedicated
		                customer support team.
		            </p>
				`
			},
			{
				key: 4,
				title: 'Can I split my order to ship to different locations?',
				content: `
					<p>
		                Yes, we can accommodate splitting your order to different
		                locations, provided that each location meets the minimum order
		                value required for free shipping. At Argus, we understand that our
		                customers may have specific requirements for shipping items to
		                multiple destinations.
	                </p>
					`
			},
			{
				key: 5,
				title: 'How might I obtain an estimated date of delivery?',
				content: `
		            <p>
		                At Argus, we understand the importance of timely delivery for our
		                customers. Here's how you can obtain an estimated date of delivery
		                for your order:
		            </p>
		            <br />
		            <ol>
		                <li>
		                    <p>
		                        <b>In-stock items: </b>
		                        If you have ordered an item that is marked as in stock on our
		                        website, we strive to ship it as quickly as possible. In-stock
		                        items ordered before 8am on a business day will generally be
		                        shipped on the same day. Orders placed after 8am will be
		                        shipped on the next business day. Please note that delivery
		                        times may vary depending on your location.
		                    </p>
		                </li>
		                <li class="py-3">
		                    <p>
		                        <b>Leadtime items: </b>
		                        For items that are marked as leadtime items on our website,
		                        the estimated shipping time will be specified on each
		                        particular item. Leadtime items may have longer processing
		                        times before they are shipped. We clearly indicate the
		                        leadtime on the website to provide you with accurate
		                        information regarding when you can expect your order to be
		                        dispatched.
		                    </p>
		                </li>
		            </ol>
        			`

			},
		]
	}

	const paymentInfo = {
		heading: "Payment information",
		sectionContent: [
			{
				key: 1,
				title: "What payments methods are available at Argus?",
				content: `
				<p>
		            At Argus, we offer various convenient payment methods to
		            accommodate our customers&apos; preferences. Here are the available
		            options:
		        </p>
		        <br />
		        <ol>
		            <li>
		                <p>
		                    <b>Credit accounts: </b>
		                    Registered customers who have opened an account with Argus can
		                    make purchases on credit, up to their approved credit limit.
		                    This option allows eligible customers to pay for their orders
		                    within the agreed-upon credit terms.
		                </p>
		            </li>
		            <li class="py-3">
		                <p>
		                    <b>Payfast or EFT: </b>
		                    All customers, regardless of whether they have a credit
		                    account, can make payments securely through our website using
		                    Payfast or Electronic Funds Transfer (EFT). Payfast enables
		                    quick and secure online payments using various payment
		                    methods, including credit cards, debit cards, and instant EFT.
		                </p>
		            </li>
		            <li class="py-3">
		                <p>
		                    <b>Pay on Collection: </b>
		                    For customers who prefer to collect their orders directly from
		                    our premises, we offer the option to pay on collection. This
		                    allows you to pay for your order when you arrive to pick it
		                    up. Please note that the processing time of your order will
		                    depend on the chosen payment method and how long it takes for
		                    the payment to clear.
		                </p>
		            </li>
		        </ol>
		        <p>
		            We understand that each payment method has different processing
		            times, and we strive to process orders promptly once payment has
		            been confirmed. Please note that for credit accounts, the order
		            processing time is subject to credit limit availability and
		            account verification.
		        </p>
			`
			},
			{
				key: 2,
				title: "Can I split my payment?",
				content: `
		            <p>
			            Yes, at Argus, we offer the flexibility to split your payment.
			            Here&apos;s how you can do it:
			        </p>
			        <br />
			        <ol>
			            <li>
			                <p>
			                    <b>For Credit Account Holders: </b>
			                    If you have a credit account with Argus, you can use your
			                    available credit to pay for your orders. However, if the total
			                    order amount exceeds your credit limit, you have the option to
			                    split the payment. You can pay for the remaining balance using
			                    Payfast, Electronic Funds Transfer (EFT), or another
			                    convenient payment method available on our website.
			                </p>
			            </li>
			            <li class="py-3">
			                <p>
			                    <b>Using Payfast, EFT, or other payment methods: </b>
			                    For customers who do not have a credit account, or for credit
			                    account holders who wish to split their payment beyond their
			                    credit limit, you can utilize Payfast, EFT, or other payment
			                    methods available on our website. These options allow you to
			                    make separate payments for different portions of your order,
			                    providing you with the flexibility to split your payment
			                    according to your needs.
			                </p>
			            </li>
			        </ol>
			`
			}
		]
	}

	const orderInfo = {
		heading: "Orders and Returns",
		sectionContent: [
			{
				key: 1,
				title: "How do I return or exchange an item?",
				content: `
					<p>
			            Returning or exchanging an item at Argus is a straightforward
			            process. Here are the steps to follow:
			        </p>
			        <br />
			        <ol>
			            <li>
			                <p>
			                    <b>Log the return request: </b>
			                    To initiate a return or exchange, please get in touch with
			                    your salesperson and log the return request. They will guide
			                    you through the process and provide you with any necessary
			                    instructions.
			                </p>
			            </li>
			            <li class="py-3">
			                <p>
			                    <b>Arrange for item return: </b>
			                    Once your return request is logged, we will arrange for the
			                    item to be returned to us. Our team will provide you with the
			                    necessary details and assistance to ensure a smooth return
			                    process.
			                </p>
			            </li>
			            <li class="py-3">
			                <p>
			                    <b>Evaluate the returned item: </b>
			                    Upon receiving the returned item, our team will thoroughly
			                    evaluate its condition and verify its eligibility for a return
			                    or exchange. We will assess factors such as the item&apos;s
			                    condition and adherence to our return policy.
			                </p>
			            </li>
			            <li class="py-3">
			                <p>
			                    <b>Replacement or refund: </b>
			                    If the returned item passes the inspection process, we will
			                    proceed with either replacing the item with a suitable
			                    alternative or issuing a refund. Refunds will be processed
			                    through the same payment channel that was used for the
			                    original purchase.
			                </p>
			            </li>
			        </ol>
				`
			},
			{
				key: 2,
				title: "How do I cancel an order?",
				content: `
					<ol>
			            <li class="py-3">
			                <p>
			                    <b>Order Cancellation: </b>
			                    You can cancel your order before it has been dispatched from
			                    our facility. Once the order has been dispatched, cancellation
			                    becomes more complex, and certain conditions apply.
			                </p>
			            </li>
			            <li class="py-3">
			                <p>
			                    <b>Restocking Fee: </b>
			                    If an order has been dispatched and you wish to cancel it,
			                    please note that a 10% restocking fee will apply. This fee
			                    covers the costs associated with returning the item to our
			                    inventory and restocking it for future orders.
			                </p>
			            </li>
			        </ol>
				`
			}
		]
	}

	const contactInfo = {
		heading: "Contact information",
		sectionContent: [
			{
				key: 1,
				title: "How can I contact the Argus customer support team?",
				content: `
					<p>
			            Our dedicated customer support team is available to assist you
			            with any queries or concerns you may have. Here are the contact
			            options:
			        </p>
			        <br />
			        <ol>
			            <li>
			                <p>
			                    <b>Email: </b>
			                    You can email us at
			                    <a href="mailto:info@argusmotoring.co.za">
			                        info@argusmotoring.co.za
			                    </a>.
			                    We strive to respond to all emails within 24 hours.
			                </p>
			            </li>
			            <li class="py-3">
			                <p>
			                    <b>Phone: </b>
			                    You can reach us by phone at 
			                    <a href="tel:0117475200">011 747 5200</a> during our business
			                    hours.
			                </p>
			            </li>
			        </ol>
			        <p>
			            Our customer support team is committed to providing prompt and
			            helpful assistance to ensure a satisfactory experience for all
			            our customers.
			        </p>
				`
			}
		]
	}

	const sections = [
		shippingInfo,
		paymentInfo,
		orderInfo,
		contactInfo
	]

	return (
		<Fragment>
			<PageHeader title="Frequently Asked Questions" />
			{isMobile === false &&
				<>
					<Container sx={{ mt: 6, mb: 6 }}>
						{sections.map((section, sectionIndex) => (
							<Box key={sectionIndex} sx={{ mb: 8 }}>
								<Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold' }}>
									{section.heading}
								</Typography>
								{section.sectionContent.map((faq, faqIndex) => (
									<Accordion key={faq.key} sx={{ mb: 2 }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls={`panel${faqIndex}-content`}
											id={`panel${faqIndex}-header`}
										>
											<Typography variant="h6">{faq.title}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography variant="body1" color="textSecondary" dangerouslySetInnerHTML={{ __html: faq.content }} />
										</AccordionDetails>
									</Accordion>
								))}
							</Box>
						))}
					</Container>
				</>
			}
			{isMobile === true &&
				<>
					<Container sx={{ mt: 4, mb: 4 }}>
						{sections.map((section, sectionIndex) => (
							<Box key={sectionIndex} sx={{ mb: 6 }}>
								<Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
									{section.heading}
								</Typography>
								{section.sectionContent.map((faq, faqIndex) => (
									<Accordion key={faq.key}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls={`panel${faqIndex}-content`}
											id={`panel${faqIndex}-header`}
										>
											<Typography variant="body1">{faq.title}</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: faq.content }} />
										</AccordionDetails>
									</Accordion>
								))}
							</Box>
						))}
					</Container>
				</>
			}
		</Fragment>
	);
};
export default Faq;
