import React, { useEffect, useState } from "react";
import { LogisticsMethod, PaymentMethod } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";
import {
	Avatar, Box,
	Button, Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import { SouthAfricanRand } from "../../utils/helpers";
import PageHeader from "../PageHeader";

const Payment = ({
	accountCreditPaymentOrderInfo,
	fullTradeAccount,
	logisticsMethod,
	gotoPaymentPayfastPage,
	gotoPaymentAccountCredit,
	gotoPaymentEftPage,
	gotoPaymentCreditCardOnCollectionPage
}) => {
	const {screenSize} = useSystemHook();
	const [recommendedPaymentMethod, setRecommendedPaymentMethod] = useState(
		PaymentMethod.PayFast
	);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!fullTradeAccount && !!accountCreditPaymentOrderInfo) {
			if (
				fullTradeAccount.availableCredit >= accountCreditPaymentOrderInfo.total
			) {
				setRecommendedPaymentMethod(PaymentMethod.AccountCredit);
			}
		}
	}, [fullTradeAccount]);

	return (
		<>
			<PageHeader title="Payment" />
			<Box  sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
				<Grid container spacing={4}>
					{/* Left Section - Payment Methods */}
					<Grid item xs={12}>
						<Paper elevation={3} sx={{ padding: '2rem', marginBottom: '1.5rem' }}>
							<Typography variant="h6" gutterBottom>
								Choose your payment method
							</Typography>
							<List>
								{/* Full Trade Account Payment Option */}
								{fullTradeAccount && (
									<ListItem>
										<Avatar
											variant="square"
											alt="Account Credit"
											src="https://argusweb.azureedge.net/argus-web/logo.svg"
											sx={{ width: 50, height: 50 }}
										/>
										<ListItemText
											primary="Pay with Account Credit"
											secondary={recommendedPaymentMethod === PaymentMethod.AccountCredit && (
												<Typography variant="caption" color="primary">Recommended</Typography>
											)}
										/>
										<ListItemSecondaryAction>
											<Button
												variant="contained"
												color="primary"
												onClick={gotoPaymentAccountCredit}
												sx={{ marginLeft: '1rem' }}
											>
												Pay Now
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
								)}
								{/* EFT Payment Option */}
								<ListItem>
									<Avatar
										variant="square"
										alt="EFT"
										src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
										sx={{ width: 50, height: 50 }}
									/>
									<ListItemText
										primary="Pay via EFT"
										secondary={recommendedPaymentMethod === PaymentMethod.EFT && (
											<Typography variant="caption" color="primary">Recommended</Typography>
										)}
									/>
									<ListItemSecondaryAction>
										<Button
											variant="contained"
											color="primary"
											onClick={gotoPaymentEftPage}
											sx={{ marginLeft: '1rem' }}
										>
											Pay Now
										</Button>
									</ListItemSecondaryAction>
								</ListItem>
								{/* Credit Card on Collection */}
								{logisticsMethod === LogisticsMethod.Collection && (
									<ListItem>
										<Avatar
											variant="square"
											alt="Credit Card"
											src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
											sx={{ width: 50, height: 50 }}
										/>
										<ListItemText
											primary="Pay via Credit Card on Collection"
											secondary={recommendedPaymentMethod === PaymentMethod.CardOnCollection && (
												<Typography variant="caption" color="primary">Recommended</Typography>
											)}
										/>
										<ListItemSecondaryAction>
											<Button
												variant="contained"
												color="primary"
												onClick={gotoPaymentCreditCardOnCollectionPage}
												sx={{ marginLeft: '1rem' }}
											>
												Pay Now
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
								)}
							</List>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default Payment;
