import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Button, Typography, Link as MuiLink, Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";

SwiperCore.use([Autoplay, Navigation]);

const Hero = () => {

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const heroItems = [
		{
			showButton: false,
			image: () => `https://argusblob01.blob.core.windows.net/argus-web/home/hero-1${isMobile ? "-mobile" : ""}.jpg`,
			zoom: false,
			position: 'left'
		},
		{
			showButton: true,
			image: () => `https://argusblob01.blob.core.windows.net/argus-web/home/hero-2${isMobile ? "-mobile" : ""}.jpg`,
			text: "Learn More",
			link: "/about",
			zoom: false,
			position: 'left'
		},
		{
			showButton: true,
			image: () => `https://argusblob01.blob.core.windows.net/argus-web/home/hero-3${isMobile ? "-mobile" : ""}.jpg`,
			text: "Meet the Team",
			link: "/team",
			zoom: false,
			position: 'right'
		}
	];

	return (
		<section className="argus-hero">
			<Swiper
				modules={[Autoplay, Navigation]}
				spaceBetween={50}
				slidesPerView={1}
				navigation
				loop={true}
				pagination={{clickable: true}}
				autoplay={{
					delay: 3000,
					disableOnInteraction: true
				}}
			>
				{!!heroItems && heroItems.map((item, index) => {
					const heroImage = item.image();
					return (
						<SwiperSlide key={index}>
							<Box sx={{
								position: 'relative',
								width: '100%',
								height: { xs: '30vh', md: '40vh' },
								overflow: 'hidden',
							}}>
								{!!item.showButton &&
									<Box sx={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										backgroundColor: 'rgba(0, 0, 0, 0.5)',
										zIndex: 1,
									}} />
								}

								<img src={heroImage}
								     alt={`Slide ${index + 1}`}
								     style={{
									     width: isMobile === true ? '200%' : "100%",
									     height: '100%',
									     objectFit: 'cover',
									     objectPosition: item.zoom ? (item.position === 'left' ? 'left' : 'right') : 'center',
									     transform: isMobile === false ? "none" : (item.zoom ? 'scale(1.3)' : 'none'),
								     }}
								/>

								{item.showButton && (
									<Box sx={{
										position: 'absolute',
										top: "50%",
										left: 0,
										width: '100%',
										height: '100%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-start',
										alignItems: 'center',
										color: '#fff',
										zIndex: 2,
										padding: isMobile ? 0 : 2,
									}}
									>
										<Typography variant={isMobile ? "h6" : "h4"} sx={{ mb: 2, color: '#fff' }}>
											Established in 1955
										</Typography>
										<MuiLink component={Link} to={item.link} sx={{ textDecoration: 'none' }}>
											<Button
												variant="contained"
												sx={{
													backgroundColor: '#d32f2f',
													color: '#fff',
													fontSize: isMobile ? 'var(--font-size-xs)' : 'var(--font-size-s)',
													'&:hover': {
														backgroundColor: '#b71c1c',
													},
												}}
											>
												{item.text}
											</Button>
										</MuiLink>
									</Box>
								)}
							</Box>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</section>
	);
};

export default Hero;
