import { useLocation, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { ApiCallStatus } from "../../utils/constants";
import { fetchAddressCheckoutData } from "../PageFunctions/checkoutFunctions";
import _ from "lodash";
import { Backdrop, CircularProgress } from "@mui/material";
import CreateAddress from "../../components/CreateAddress";
import { useAuth0 } from "@auth0/auth0-react";


const CheckoutCreateAddressPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
	const [countries, setCountries] = useState([]);
	const [provinces, setProvinces] = useState([]);

	const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchCheckoutAddressDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchAddressCheckoutData({
			auth0Token,
			setAddressCheckoutInfo,
			setStatusInProgress: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutAddressDataAsync()
		]);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const handleCreateNewAddressSuccess = () => {
		let pathName = location.pathname;
		pathName = _.replace(pathName, "/create", "");
		navigate(pathName);
	}

	const handleCreateNewAddressFailure = () => {

	}

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			unAuthorizedPageLoad();
		}

	}, [genericDataLoaded, isLoading]);

	useEffect(() => {
		if (!!addressCheckoutInfo) {
			let countries = [];
			let provinces = [];

			if (addressCheckoutInfo?.countries) {
				const countriesInAlphabeticalOrder = _.sortBy(
					addressCheckoutInfo.countries,
					({name}) => name
				);
				countries = _.sortBy(countriesInAlphabeticalOrder, ({name}) =>
					name === "South Africa" ? 0 : 1
				);
			}

			if (addressCheckoutInfo?.provinces) {
				provinces = addressCheckoutInfo.provinces;
			}

			setProvinces(provinces);
			setCountries(countries);
		}
	}, [addressCheckoutInfo]);

	const allLoadingStates = [
		addressCheckoutInfoCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<CreateAddress
				countries={countries}
				provinces={provinces}
				email={genericPageContent?.email}
				closeWithSuccess={handleCreateNewAddressSuccess}
				closeWithFailure={handleCreateNewAddressFailure}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
}

export default CheckoutCreateAddressPage;