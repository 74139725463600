import React, { useEffect, useState } from "react";
import { cloneDeep, compact, find, join } from "lodash";
import { CartSummary } from "../CartSummary";

const CheckoutAddressUi = {
	firstLoad: -1,
	noAddressOptions: 0,
	addressDropdown: 1,
	addressChosen: 2,
	error: 3
};

const CheckoutCollectionAddress = ({
	checkoutData,
	countries,
	provinces,
	collectionAddresses,
	onSubmit
}) => {
	const [currentUiState, setCurrentUiState] = useState(
		CheckoutAddressUi.firstLoad
	);
	const [chosenAddressId, setChosenAddressId] = useState(
		checkoutData?.collectionAddressId ?? null
	);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!collectionAddresses && collectionAddresses.length > 0) {
			showAddressDropdown();
		}
		else {
			showNoAddressOptions();
		}
	}, [collectionAddresses]);

	useEffect(() => {
		if (checkoutData?.collectionAddressId) {
			setChosenAddressId(checkoutData.collectionAddressId);
		}
	}, [checkoutData]);

	const onSaveAddressClick = async () => {
		if (!!chosenAddressId) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.collectionAddressId = chosenAddressId;
			checkoutDataClone.shippingAddressId = null;

			await onSubmit({
				checkoutData: checkoutDataClone
			});
		}
	};

	const onUseAddressClick = (addressId) => {
		setChosenAddressId(addressId);
	};

	const showAddressDropdown = () => {
		setCurrentUiState(CheckoutAddressUi.addressDropdown);
	};

	const showNoAddressOptions = () => {
		setCurrentUiState(CheckoutAddressUi.noAddressOptions);
	};

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">Checkout</div>
			</div>
			{!!checkoutData && (
				<Grid container>
					<Grid.Row>
						<Grid.Column width={12}>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={8}>
											<Header as="h4">
												<Icon name="truck"/>
												Collection address
											</Header>
										</Grid.Column>
									</Grid.Row>
									{currentUiState === CheckoutAddressUi.noAddressOptions && (
										<Grid.Row>
											<Grid.Column width={16}>
												<p className="b1">No addresses found.</p>
											</Grid.Column>
										</Grid.Row>
									)}
									{currentUiState === CheckoutAddressUi.addressDropdown && (
										<>
											<Grid.Row>
												<Grid.Column width={16}>
													<Grid container>
														<Grid.Row>
															<Grid.Column width={16}>
																<Item.Group divided>
																	{collectionAddresses.map((address, index) => {
																		const country = find(
																			countries,
																			(x) => x.countryId === address.countryId
																		);
																		const province = find(
																			provinces,
																			(x) =>
																				x.provinceId === address.provinceId
																		);
																		const addressArray = [
																			address.streetAddress,
																			address.suburb,
																			address.city,
																			address.postalCode,
																			province?.name,
																			country?.name
																		];
																		const cleanedAddressArray =
																			compact(addressArray);
																		const addressString = join(
																			cleanedAddressArray,
																			", "
																		);

																		let showAsCurrent = false;
																		if (chosenAddressId) {
																			showAsCurrent =
																				address.addressId === chosenAddressId;
																		}
																		else if (
																			!!checkoutData.collectionAddressId
																		) {
																			showAsCurrent =
																				address.addressId ===
																				checkoutData.collectionAddressId;
																		}

																		return (
																			<Item key={index}>
																				<Item.Content>
																					<Item.Description>
																						<p className="b2">
																							{addressString}
																						</p>
																					</Item.Description>
																					<Item.Extra>
																						{!!showAsCurrent && (
																							<Button
																								type="button"
																								disabled
																								icon
																								className="green-button"
																								floated="right"
																							>
																								<Icon name="check"/>
																							</Button>
																						)}
																						{!showAsCurrent && (
																							<Button
																								type="button"
																								className="blue-basic-button"
																								onClick={() =>
																									onUseAddressClick(
																										address.addressId
																									)
																								}
																								floated="right"
																							>
																								Use
																							</Button>
																						)}
																					</Item.Extra>
																				</Item.Content>
																			</Item>
																		);
																	})}
																</Item.Group>
															</Grid.Column>
														</Grid.Row>
													</Grid>
												</Grid.Column>
											</Grid.Row>
										</>
									)}
									{currentUiState === CheckoutAddressUi.error && <></>}
								</Grid>
							</Segment>
						</Grid.Column>
						<Grid.Column width={4}>
							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								total={checkoutData.total}
							/>
							{!!chosenAddressId && (
								<Button
									type="button"
									className="blue-button"
									fluid
									onClick={onSaveAddressClick}
								>
									Continue
									<Icon name="arrow right"/>
								</Button>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</div>
	);
};

export default CheckoutCollectionAddress;
