import React, { Fragment } from "react";
import "./style.css";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import PageHeader from "../PageHeader";

const About = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });


	return (
		<Fragment>
			<PageHeader title={"About Us"} />
			{isMobile === true &&
				<Container sx={{ mt: 4, mb: 4 }}>
					{/* Section 1: The Beginning */}
					<Paper elevation={1} sx={{ mb: 4, p: 2 }}>
						<Grid container spacing={2} direction="column" alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
									The Beginning
								</Typography>
								<Typography variant="body2" color="textSecondary" sx={{ mb: 2, textAlign: 'justify' }}>
									In early 1955, a young motor mechanic, Louis Raff, left smoggy Johannesburg with his wife and their two sons,
									to set up shop in ‘the countryside’ at Argus Motors, a ramshackle filling station with a workshop and adjoining
									pondok on Great North Road in Brentwood Park, a few kilometers north of Benoni on the East Rand.
									<br />
									<br />
									The ‘business’ was purchased for £800 from a private operator while the license to sell fuel was obtained from
									the Shell Company, which in turn held the lease on the five-acre property on which Argus Motors was situated.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<img
									src="https://argusweb.azureedge.net/argus-web/about/about-beginning.webp"
									alt="The beginning"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
						</Grid>
					</Paper>

					{/* Section 2: From the Ground Up */}
					<Paper elevation={1} sx={{ mb: 4, p: 2 }}>
						<Grid container spacing={2} direction="column" alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
									From the Ground Up
								</Typography>
								<Typography variant="body2" color="textSecondary" sx={{ mb: 2, textAlign: 'justify' }}>
									In what was zoned as a ‘sub-economic’ area, Brentwood Park proved a tough testing ground for Louis and Anne,
									who lived ‘off-grid’, relying on candle power, a windmill and an outhouse to supply their utilities, while a pre-war
									Onen lighting plant (a 3hp generator) provided unreliable and often dangerous voltage to power the four Argus Motors
									fuel pumps.
									<br />
									<br />
									By the early 1960s Louis and Anne had four sons (Larry, Ronnie, Mark, and Chaim) and a singular mission to not only
									upgrade Argus Motors but also to own the land it was situated on. To achieve this meant convincing the Shell Company
									board of directors in Johannesburg to install a new generator and provide the necessary loan capital to modernize the
									Argus Motors forecourt and workshop.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<img
									src="https://argusweb.azureedge.net/argus-web/about/about-ground-up.webp"
									alt="From the ground up"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
						</Grid>
					</Paper>

					{/* Section 3: Into Distribution */}
					<Paper elevation={1} sx={{ mb: 4, p: 2 }}>
						<Grid container spacing={2} direction="column" alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
									Into Distribution
								</Typography>
								<Typography variant="body2" color="textSecondary" sx={{ mb: 2, textAlign: 'justify' }}>
									By the mid-1970s, both Larry and Ronnie were working in the family business which had established itself as the leading
									service station in the area, thanks to Louis’ “honesty, generosity, and dedication to quality workmanship,” says Anne.
									“Not only were we able to buy the land but also begin building new facilities on it which we could rent to other
									automotive businesses.”
									<br />
									<br />
									Born with an entrepreneurial gift, Larry, then in his mid-20s, soon recognized the massive potential replacement parts
									could have. Wholesale agency contracts were duly signed with leading ISO-accredited parts suppliers, and Argus Motor
									Company took a giant leap forward into the highly competitive business of distributing aftermarket parts to retail motor
									spares shops.
									<br />
									<br />
									Leading brands such as DOE, BETA, Argus Motoring, and X-APPEAL that are recognized today in the marketplace were registered.
									As the 20th Century drew to a close, the Raff family and its loyal staff had developed Argus Motor Company into a leading
									southern African parts supplier, with a distribution infrastructure servicing all the ASDC countries.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<img
									src="https://argusweb.azureedge.net/argus-web/about/about-distrobution.webp"
									alt="Into distribution"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
						</Grid>
					</Paper>

					{/* Section 4: Continuing the Legacy */}
					<Paper elevation={1} sx={{ mb: 4, p: 2 }}>
						<Grid container spacing={2} direction="column" alignItems="center">
							<Grid item xs={12}>
								<Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
									Continuing the Legacy
								</Typography>
								<Typography variant="body2" color="textSecondary" sx={{ mb: 2, textAlign: 'justify' }}>
									In 2018, the family suffered the loss of Ronnie. Today, with Larry and Mark as directors, Argus Motor Company now enjoys its
									third generation of Raff talent in Louis and Anne’s grandchildren, Moishe (COO), Baruch (CTO), and Shifra (CFO).
									<br />
									<br />
									A massive warehouse equipped with high-tech inventory management and dispatching systems keeps a fleet of delivery bakkies
									busy five days a week, while contracted courier companies deliver the long-distance orders from over 2500 Argus Motors customers.
									<br />
									<br />
									According to the team, “Our focus is on supplying quality replacement parts with exceptional backup service to the retail spares
									trade. Our 5000 square meter warehouse has over 20,000 stock items of both leading and Argus-branded products covering the full
									spectrum of popular replacement parts and accessories, which are digitally catalogued for swift identification and accurate merchandising.”
									<br />
									<br />
									With the credo, ‘people sell to people’ underpinning its day-to-day operations, superior customer service will always be the primary
									driver of Argus. “When you deal with Argus Motor Company, you are assured of total customer satisfaction and service. You are dealing
									with a company that is steeped in business ethics and an ethos that you, the valued customer, is all important,” concludes Larry.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<img
									src="https://argusweb.azureedge.net/argusblob01/web/argus-splash.jpg"
									alt="Continuing the legacy"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			}
			{isMobile === false &&
				<Container sx={{ mt: 8, mb: 8 }}>
					{/* Section 1: The Beginning */}
					<Paper elevation={3} sx={{ mb: 6, p: 4 }}>
						<Grid container spacing={4} alignItems="center">
							<Grid item xs={12} md={6}>
								<img
									src="https://argusweb.azureedge.net/argus-web/about/about-beginning.webp"
									alt="The beginning"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography variant="h4" sx={{ mb: 2 }}>
									The Beginning
								</Typography>
								<Typography variant="body1" color="textSecondary">
									In early 1955, a young motor mechanic, Louis Raff, left smoggy Johannesburg with his wife and their two sons,
									to set up shop in ‘the countryside’ at Argus Motors, a ramshackle filling station with a workshop and adjoining
									pondok on Great North Road in Brentwood Park, a few kilometers north of Benoni on the East Rand.
									<br />
									<br />
									The ‘business’ was purchased for £800 from a private operator while the license to sell fuel was obtained from
									the Shell Company, which in turn held the lease on the five-acre property on which Argus Motors was situated.
								</Typography>
							</Grid>
						</Grid>
					</Paper>

					{/* Section 2: From the Ground Up */}
					<Paper elevation={3} sx={{ mb: 6, p: 4 }}>
						<Grid container spacing={4} alignItems="center">
							<Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
								<Typography variant="h4" sx={{ mb: 2 }}>
									From the Ground Up
								</Typography>
								<Typography variant="body1" color="textSecondary">
									In what was zoned as a ‘sub-economic’ area, Brentwood Park proved a tough testing ground for Louis and Anne,
									who lived ‘off-grid’, relying on candle power, a windmill and an outhouse to supply their utilities, while a pre-war
									Onen lighting plant (a 3hp generator) provided unreliable and often dangerous voltage to power the four Argus Motors
									fuel pumps.
									<br />
									<br />
									By the early 1960s Louis and Anne had four sons (Larry, Ronnie, Mark, and Chaim) and a singular mission to not only
									upgrade Argus Motors but also to own the land it was situated on. To achieve this meant convincing the Shell Company
									board of directors in Johannesburg to install a new generator and provide the necessary loan capital to modernize the
									Argus Motors forecourt and workshop.
									<br />
									<br />
									Faced with obdurate corporate red tape, Louis and Anne ‘stormed’ Shell House to present their business case—that
									Argus Motors had great potential in the growing Benoni district and, with the necessary capital, could finally become
									a viable concern.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
								<img
									src="https://argusweb.azureedge.net/argus-web/about/about-ground-up.webp"
									alt="From the ground up"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
						</Grid>
					</Paper>

					{/* Section 3: Into Distribution */}
					<Paper elevation={3} sx={{ mb: 6, p: 4 }}>
						<Grid container spacing={4} alignItems="center">
							<Grid item xs={12} md={6}>
								<img
									src="https://argusweb.azureedge.net/argus-web/about/about-distrobution.webp"
									alt="Into distribution"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography variant="h4" sx={{ mb: 2 }}>
									Into Distribution
								</Typography>
								<Typography variant="body1" color="textSecondary">
									By the mid-1970s, both Larry and Ronnie were working in the family business which had established itself as the leading
									service station in the area, thanks to Louis’ “honesty, generosity, and dedication to quality workmanship,” says Anne.
									“Not only were we able to buy the land but also begin building new facilities on it which we could rent to other
									automotive businesses.”
									<br />
									<br />
									Born with an entrepreneurial gift, Larry, then in his mid-20s, soon recognized the massive potential replacement parts
									could have. Wholesale agency contracts were duly signed with leading ISO-accredited parts suppliers, and Argus Motor
									Company took a giant leap forward into the highly competitive business of distributing aftermarket parts to retail motor
									spares shops.
									<br />
									<br />
									Leading brands such as DOE, BETA, Argus Motoring, and X-APPEAL that are recognized today in the marketplace were registered.
									As the 20th Century drew to a close, the Raff family and its loyal staff had developed Argus Motor Company into a leading
									southern African parts supplier, with a distribution infrastructure servicing all the ASDC countries.
								</Typography>
							</Grid>
						</Grid>
					</Paper>

					{/* Section 4: Continuing the Legacy */}
					<Paper elevation={3} sx={{ mb: 6, p: 4 }}>
						<Grid container spacing={4} alignItems="center">
							<Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
								<Typography variant="h4" sx={{ mb: 2 }}>
									Continuing the Legacy
								</Typography>
								<Typography variant="body1" color="textSecondary">
									In 2018, the family suffered the loss of Ronnie. Today, with Larry and Mark as directors, Argus Motor Company now enjoys its
									third generation of Raff talent in Louis and Anne’s grandchildren, Moishe (COO), Baruch (CTO), and Shifra (CFO).
									<br />
									<br />
									A massive warehouse equipped with high-tech inventory management and dispatching systems keeps a fleet of delivery bakkies
									busy five days a week, while contracted courier companies deliver the long-distance orders from over 2500 Argus Motors customers.
									<br />
									<br />
									According to the team, “Our focus is on supplying quality replacement parts with exceptional backup service to the retail spares
									trade. Our 5000 square meter warehouse has over 20,000 stock items of both leading and Argus-branded products covering the full
									spectrum of popular replacement parts and accessories, which are digitally catalogued for swift identification and accurate merchandising.”
									<br />
									<br />
									With the credo, ‘people sell to people’ underpinning its day-to-day operations, superior customer service will always be the primary
									driver of Argus. “When you deal with Argus Motor Company, you are assured of total customer satisfaction and service. You are dealing
									with a company that is steeped in business ethics and an ethos that you, the valued customer, is all important,” concludes Larry.
								</Typography>
							</Grid>
							<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
								<img
									src="https://argusweb.azureedge.net/argusblob01/web/argus-splash.jpg"
									alt="Continuing the legacy"
									style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			}
		</Fragment>
	);
};
export default About;
