import React, { useEffect, useState } from "react";
import Hero from "../Hero";
import VendorSlider from "../VendorSlider";
import SearchForParts from "../SearchForParts";
import FindProductsByCategory from "../FindProductsByCategory";
import AnnouncementModal from "../AnnouncementModal";
import { useNavigate } from "react-router-dom";
import { storageKeys } from "../../api/storageKeys";
import { getItem } from "../../utils/storageHelper";
import { includes } from "lodash";
import { AnnouncementType } from "../../utils/constants";

const Home = ({
	customerCategory,
	vendors,
	categoriesAndTypes,
	pageContent,
	markAnnouncementAsSeen,
	markAnnouncementAsAcknowledged
}) => {
	const navigate = useNavigate();
	const [announcement, setAnnouncement] = useState(null);
	const [announcementOpen, setAnnouncementOpen] = useState(false);

	useEffect(() => {
		if (!!pageContent) {
			const announcementToUse = pageContent.announcements[0];
			if (!!announcementToUse) {
				let showModal = true;
				const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS);
				let userAnnouncements = JSON.parse(userAnnouncementsString);

				if (!!userAnnouncements) {
					const announcementAlreadySeen = includes(
						userAnnouncements.seen,
						announcementToUse.announcementId
					);
					const announcementAlreadyAcknowledged = includes(
						userAnnouncements.acknowledged,
						announcementToUse.announcementId
					);

					showModal = !announcementAlreadySeen;
					showModal = announcementToUse.type === AnnouncementType.Acknowledgement && !announcementAlreadyAcknowledged;
				}

				setAnnouncement(showModal ? announcementToUse : null);
				setAnnouncementOpen(!!showModal);
			}
		}
	}, [pageContent]);

	const closeAnnouncement = () => {
		setAnnouncementOpen(false);
	};

	const gotoAnnouncementLink = () => {
		if (!!announcement?.link) {
			navigate(announcement.link);
		}
	};

	return (
		<div>
			<Hero/>
			<SearchForParts/>
			<FindProductsByCategory
				categoriesAndTypes={categoriesAndTypes}
				customerCategory={customerCategory}
			/>
			<VendorSlider vendors={vendors} />
			{!!announcement && !!announcementOpen && (
				<AnnouncementModal
					open={announcementOpen}
					setClose={closeAnnouncement}
					type={announcement.type}
					html={announcement.html}
					image={announcement.bannerImage}
					gotoLink={gotoAnnouncementLink}
					announcementId={announcement.announcementId}
					markAnnouncementAsSeen={markAnnouncementAsSeen}
					markAnnouncementAsAcknowledged={markAnnouncementAsAcknowledged}
				/>
			)}
		</div>
	);
};
export default Home;
