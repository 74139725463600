import React, { Fragment, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { useMediaQuery } from "react-responsive";
import { useCart } from "react-use-cart";
import {
	Box, Button,
	CircularProgress,
	Container, Grid,
	ImageList,
	ImageListItem,
	Paper,
	Tab,
	Tabs,
	Typography
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AccountType, ProductAvailability } from "../../utils/constants";
import { SouthAfricanRand } from "../../utils/helpers";
import "./style.css";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Product = ({
	product,
	pricingData,
	accountType,
	loading
}) => {
	const {handleAddToCart} = useArgusCart();
	const navigate = useNavigate();
	const [productAddedInformation, setProductAddedInformation] = useState(null);
	const [tabValue, setTabValue] = useState(0);
	const [productPrice, setProductPrice] = useState(null);

	const { addItem } = useCart();

	const onBackClick = () => {
		return navigate(-1);
	};

	const sanitizedData = () => ({
		__html: !!product ? DOMPurify.sanitize(product.html) : null
	});

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};


	const handleAddToCartClick = () => {
		handleAddToCart({
			id: product.productId,
			name: product.name,
			vendor: product.vendor,
			image: product.image,
			sku: product.sku
		});
	};

	let colourForAvailability;
	let textForAvailability;
	let colourForQuality;
	let textForQuality;
	if (!!product) {
		switch (product.availability) {
			case ProductAvailability.InStock:
				colourForAvailability = "green";
				textForAvailability =
					"This item is in stock at our warehouse. See our delivery policy to see when you can expect it.";
				break;
			case ProductAvailability.LowStock:
				colourForAvailability = "blue";
				textForAvailability =
					"This item shows as having low stock in our warehouse. Please be aware that in some cases, this item may already be sold out and we may have to order this item in or refund your order.";
				break;
			case ProductAvailability.ThreeToFiveDays:
				colourForAvailability = "orange";
				textForAvailability =
					"This item is in-stock at our suppliers. When you place an order for this item, we will collect it from our supplier and deliver it to you. Please see our delivery policy to see when you can expect it.";
				break;
			case ProductAvailability.No_Stock:
				colourForAvailability = "red";
				textForAvailability =
					"This item is unfortunately out of stock, but more stock is on the way! Please contact us or regularly check our website to be notified when this item is back in stock.";
				break;
			default:
				colourForAvailability = "";
				break;
		}
		switch (product.quality) {
			case "A+":
				colourForQuality = "violet";
				textForQuality = "Manufacturer is a recognised O.E. supplier.";
				break;
			case "A":
				colourForQuality = "blue";
				textForQuality =
					"Aftermarket part manufactured to O.E. equivalent specifications.";
				break;
			case "B":
				colourForQuality = "teal";
				textForQuality =
					"Economical replacement aftermarket part conforms to regulator requirements.";
				break;
			case "Other":
				colourForQuality = "olive";
				textForQuality =
					"Applies to tools, accessories and non car specific parts.";
				break;
			default:
				colourForQuality = "";
				textForQuality = "";
				break;
		}
	}

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	useEffect(() => {
		if (!!pricingData && pricingData.length > 0) {
			setProductPrice(pricingData[0]);
		}
	}, [pricingData]);

	return (
		<Fragment>
			{isMobile === true &&
				<Container maxWidth="sm" sx={{ padding: 2 }}>
					<Button
						variant="outlined"
						startIcon={<ArrowBackIcon />}
						onClick={onBackClick}
						sx={{ marginBottom: 2 }}
					>
						Back
					</Button>

					{loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
							<CircularProgress />
						</Box>
					) : (
						<>
							{product ? (
								<Paper elevation={3} sx={{ padding: 2 }}>
									<ImageList cols={1}>
										<ImageListItem>
											<img
												src={product.image}
												alt={product.name}
												style={{ maxHeight: '250px', objectFit: 'contain' }}
											/>
										</ImageListItem>
									</ImageList>

									<Typography variant="h3" sx={{ fontWeight: 'bold', marginTop: 2 }}>
										{product.name}
									</Typography>

									<Typography variant="body2" color="textSecondary">
										SKU: {product.sku}
									</Typography>

									{product.vendor && (
										<Link to={`/products/all?vendor=${product.vendor}`}>
											<Typography variant="body2" color="textSecondary">
												Vendor: {product.vendor}
											</Typography>
										</Link>

									)}

									{!!productPrice && (
										<Box sx={{ marginTop: 2 }}>
											<Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
												{SouthAfricanRand.format(productPrice.price)}
											</Typography>
											{accountType === AccountType.Commercial && (
												<Typography variant="caption" color="textSecondary">
													(Ex. VAT)
												</Typography>
											)}
											{!!productPrice?.invoicePrice &&
												productPrice.invoicePrice !== productPrice.price && (
													<Typography variant="caption" color="textSecondary">
														Invoice price: {SouthAfricanRand.format(productPrice.invoicePrice)}
													</Typography>
												)}
										</Box>
									)}

									{!!product.productCategory && (
										<Link to={`/products/${product.productCategory.toLowerCase()}`}>
											<Typography variant="body2" sx={{ marginTop: 1, textDecoration: 'underline' }}>
												{product.productCategory.replaceAll('_', ' ')}
											</Typography>
										</Link>
									)}
									{!!product?.productCategory && !!product?.productType && (
										<Link to={`/products/${product.productCategory.toLowerCase()}/${product.productType.toLowerCase()}`}>
											<Typography variant="body2" sx={{ textDecoration: 'underline' }}>
												{product.productType.replaceAll('_', ' ')}
											</Typography>
										</Link>
									)}

									{product.availability !== ProductAvailability.No_Stock && (
										<Button
											variant="contained"
											color="error"
											fullWidth
											sx={{ marginTop: 2 }}
											onClick={handleAddToCartClick}
										>
											Add to Cart
										</Button>
									)}

									{!!product.availability && (
										<Paper elevation={1} sx={{ marginTop: 2, padding: 1 }}>
											<Typography variant="subtitle1" sx={{ color: colourForAvailability }}>
												{product.availability}
											</Typography>
											<Typography variant="body2">{textForAvailability}</Typography>
										</Paper>
									)}

									{!!product.quality && (
										<Paper elevation={1} sx={{ marginTop: 2, padding: 1 }}>
											<Typography variant="subtitle1" sx={{ color: colourForQuality }}>
												Quality: {product.quality}
											</Typography>
											<Typography variant="body2">{textForQuality}</Typography>
										</Paper>
									)}

									<Box sx={{ marginTop: 4 }}>
										<Tabs
											value={tabValue}
											onChange={handleTabChange}
											centered
											variant="fullWidth"
											textColor="primary"
											indicatorColor="primary"
										>
											<Tab label="Description & Application" />
										</Tabs>
										<Box sx={{ padding: 2 }}>
											{tabValue === 0 && product?.html && (
												<section>
													<div dangerouslySetInnerHTML={sanitizedData()} />
												</section>
											)}
										</Box>
									</Box>
								</Paper>
							) : (
								<Box sx={{ textAlign: 'center', marginTop: 4 }}>
									<Typography variant="h5">No product found</Typography>
								</Box>
							)}
						</>
					)}
				</Container>
			}
			{isMobile === false &&
				<Container maxWidth="lg" sx={{ padding: 4 }}>
					<Button
						variant="outlined"
						startIcon={<ArrowBackIcon />}
						onClick={onBackClick}
						sx={{ marginBottom: 2 }}
					>
						Back
					</Button>

					{loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
							<CircularProgress />
						</Box>
					) : (
						<>
							{product ? (
								<Paper elevation={3} sx={{ padding: 4 }}>
									<Grid container spacing={4}>
										<Grid item xs={12} md={6}>
											<Box sx={{ display: 'flex', justifyContent: 'center' }}>
												<img
													src={product.image}
													alt={product.name}
													style={{ maxHeight: '400px', objectFit: 'contain', width: '100%' }}
												/>
											</Box>
										</Grid>

										<Grid item xs={12} md={6}>
											<Typography variant="h4" sx={{ marginBottom: 2 }}>
												{product.name}
											</Typography>

											<Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
												SKU: {product.sku}
											</Typography>

											{product.vendor && (
												<Link to={`/products/all?vendor=${product.vendor}`}>
													<Typography variant="body2" sx={{ marginBottom: 2, textDecoration: "none" }}>
														Vendor: {product.vendor}
													</Typography>
												</Link>

											)}

											{!!productPrice && (
												<Box sx={{ marginBottom: 3 }}>
													<Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
														{SouthAfricanRand.format(productPrice.price)}
													</Typography>
													{accountType === AccountType.Commercial && (
														<Typography variant="caption" color="textSecondary">
															(Ex. VAT)
														</Typography>
													)}
													{!!productPrice?.invoicePrice &&
														productPrice.invoicePrice !== productPrice.price && (
															<Typography variant="caption" color="textSecondary">
																Invoice price: {SouthAfricanRand.format(productPrice.invoicePrice)}
															</Typography>
														)}
												</Box>
											)}

											{!!product.productCategory && (
												<Link to={`/products/${product.productCategory.toLowerCase()}`}>
													<Typography variant="body2" sx={{ textDecoration: 'underline', marginBottom: 1 }}>
														{product.productCategory.replaceAll('_', ' ')}
													</Typography>
												</Link>
											)}
											{!!product?.productCategory && !!product?.productType && (
												<Link to={`/products/${product.productCategory.toLowerCase()}/${product.productType.toLowerCase()}`}>
													<Typography variant="body2" sx={{ textDecoration: 'underline' }}>
														{product.productType.replaceAll('_', ' ')}
													</Typography>
												</Link>
											)}

											{product.availability !== ProductAvailability.No_Stock && (
												<Button
													variant="contained"
													color="error"
													fullWidth
													sx={{ marginTop: 3 }}
													onClick={handleAddToCartClick}
												>
													Add to Cart
												</Button>
											)}

											{!!product.availability && (
												<Paper elevation={1} sx={{ marginTop: 3, padding: 2 }}>
													<Typography variant="subtitle1" sx={{ color: colourForAvailability }}>
														{product.availability}
													</Typography>
													<Typography variant="body2">{textForAvailability}</Typography>
												</Paper>
											)}

											{!!product.quality && (
												<Paper elevation={1} sx={{ marginTop: 3, padding: 2 }}>
													<Typography variant="subtitle1" sx={{ color: colourForQuality }}>
														Quality: {product.quality}
													</Typography>
													<Typography variant="body2">{textForQuality}</Typography>
												</Paper>
											)}
										</Grid>
									</Grid>

									<Box sx={{ marginTop: 4 }}>
										<Tabs
											value={tabValue}
											onChange={handleTabChange}
											centered
											variant="fullWidth"
											textColor="primary"
											indicatorColor="primary"
										>
											<Tab label="Description & Application" sx={{ textTransform: "none", fontSize: "1.2em" }} />
										</Tabs>
										<Box sx={{ padding: 2 }}>
											{tabValue === 0 && product?.html && (
												<section>
													<div dangerouslySetInnerHTML={sanitizedData()} />
												</section>
											)}
										</Box>
									</Box>
								</Paper>
							) : (
								<Box sx={{ textAlign: 'center', marginTop: 4 }}>
									<Typography variant="h5">No product found</Typography>
								</Box>
							)}
						</>
					)}
				</Container>
			}
		</Fragment>
	);
};

export default Product;