import React, { useEffect, useState } from "react";
import { SouthAfricanRand } from "../../utils/helpers";
import { PaymentConfirmationMessage } from "../../utils/constants";
import { useMediaQuery } from "react-responsive";
import { find, sum } from "lodash";
import moment from "moment";
import { Box, Divider, Grid, Icon, Paper, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Image from "../Image";

const OrderConfirmation = ({
	orderData
}) => {
	const [confirmationHeading, setConfirmationHeading] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState(null);

	const isMobile = useMediaQuery({
		query: "(min-width: 200px) and (max-width: 1000px) "
	});

	useEffect(() => {

		if (orderData?.orderPaymentStatus === "Pending Payment") {
			setConfirmationHeading("Thank you for your purchase");
			setConfirmationMessage(PaymentConfirmationMessage.MessagePending);
		} else if (orderData?.orderPaymentStatus === "Partially Paid") {
			setConfirmationHeading("Thank you for your purchase");
			setConfirmationMessage(PaymentConfirmationMessage.MessagePartialComplete);
		} else if (orderData?.orderPaymentStatus === "Paid") {
			setConfirmationHeading("Thank you for your purchase");
			setConfirmationMessage(PaymentConfirmationMessage.MessageComplete);
		}

		if (!orderData) {
			setConfirmationHeading(PaymentConfirmationMessage.HeadingFailed);
			setConfirmationMessage(PaymentConfirmationMessage.MessageFailed);
		}
	}, [orderData]);

	return (
		<Box sx={{ minHeight: '100vh', padding: '2rem', backgroundColor: '#f4f4f9' }}>
			{!!orderData && (
				<Grid container justifyContent="center" spacing={4}>
					<Grid item xs={12} md={6}>
						<Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
							<CheckCircleIcon color="success" fontSize="large" />
							<Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
								{confirmationHeading}
							</Typography>
							<Typography variant="body2" sx={{ marginTop: '1rem', color: '#555' }}>
								{confirmationMessage}
							</Typography>
						</Paper>
					</Grid>

					{/* Order Summary */}
					<Grid item xs={12} md={6}>
						<Paper elevation={3} sx={{ padding: '2rem' }}>
							<Typography variant="h6" sx={{ marginBottom: '1rem' }}>
								Order Summary
							</Typography>
							<Divider sx={{ marginBottom: '1.5rem' }} />

							{/* Order Details */}
							<Grid container spacing={2} textAlign="center">
								<Grid item xs={12} sm={4}>
									<Typography variant="caption" color="textSecondary">Date</Typography>
									<Typography variant="body2">{moment(orderData.dateCreated).format('DD MMM YYYY')}</Typography>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant="caption" color="textSecondary">Order #</Typography>
									<Typography variant="body2">{orderData.orderNumber}</Typography>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Typography variant="caption" color="textSecondary">Shipping Method</Typography>
									<Typography variant="body2">{orderData.logisticsMethod}</Typography>
								</Grid>
							</Grid>
							<Divider sx={{ margin: '1.5rem 0' }} />

							{/* Order Items */}
							{orderData.orderItems.map((item, index) => {
								const product = orderData.products.find(x => x.sku === item.sku);
								if (!product) return null;

								return (
									<Grid container key={index} spacing={2} sx={{ marginBottom: '1.5rem' }}>
										<Grid item xs={12} sm={3}>
											<Image src={product.image} alt={product.description} sx={{ width: '100%' }} />
										</Grid>
										<Grid item xs={12} sm={6}>
											<Typography variant="body2" fontWeight="bold">{product.description}</Typography>
											<Typography variant="caption" color="textSecondary">SKU: {product.sku}</Typography>
											<Typography variant="caption">Qty: {item.quantity}</Typography>
										</Grid>
										<Grid item xs={12} sm={3} textAlign="right">
											<Typography variant="body2">{SouthAfricanRand.format(item.priceSnapshot)}</Typography>
										</Grid>
									</Grid>
								);
							})}

							{/* Order Totals */}
							<Divider sx={{ marginBottom: '1rem' }} />
							<Grid container spacing={2}>
								<Grid item xs={8}>
									<Typography variant="caption" color="textSecondary">Subtotal</Typography>
								</Grid>
								<Grid item xs={4} textAlign="right">
									<Typography variant="caption" color="textSecondary">
										{SouthAfricanRand.format(orderData.orderItems.reduce((sum, item) => sum + item.priceSnapshot * item.quantity, 0))}
									</Typography>
								</Grid>
							</Grid>

							{orderData.logisticsFee > 0 && (
								<Grid container spacing={2}>
									<Grid item xs={8}>
										<Typography variant="caption" color="textSecondary">Shipping</Typography>
									</Grid>
									<Grid item xs={4} textAlign="right">
										<Typography variant="caption" color="textSecondary">
											{SouthAfricanRand.format(orderData.logisticsFee)}
										</Typography>
									</Grid>
								</Grid>
							)}

							<Grid container spacing={2} sx={{ marginTop: '1rem' }}>
								<Grid item xs={8}>
									<Typography variant="caption" fontWeight="bold">Total</Typography>
								</Grid>
								<Grid item xs={4} textAlign="right">
									<Typography variant="caption" fontWeight="bold">
										{SouthAfricanRand.format(orderData.total)}
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			)}
		</Box>
	)
}

export default OrderConfirmation;