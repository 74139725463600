import {
	getAddressCheckoutData,
	getCheckoutData,
	getOrderCheckoutData,
	postCheckout,
	putCheckout
} from "../../api/checkoutController";
import { cloneDeep } from "lodash";
import Cookies from "js-cookie";
import { storageKeys } from "../../api/storageKeys";

export const fetchAddressCheckoutData = async ({
	auth0Token,
	setAddressCheckoutInfo,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let checkoutUser = await getAddressCheckoutData(auth0Token);
		if (checkoutUser?.success) {
			const addressData = checkoutUser.data;
			const addressDataClone = cloneDeep(addressData);

			const newShippingAddress = Cookies.get(
				storageKeys.NEW_CHECKOUT_SHIPPING_ADDRESS
			);
			if (newShippingAddress) {
				const guestTempuserAddresses = [];
				guestTempuserAddresses.push(JSON.parse(newShippingAddress));
				addressDataClone.userAddresses = guestTempuserAddresses;
			}

			setAddressCheckoutInfo(addressDataClone);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchOrderCheckoutData = async ({
	auth0Token,
	setOrderCheckoutInfo,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let checkoutUser = await getOrderCheckoutData(auth0Token);
		if (checkoutUser?.success) {
			setOrderCheckoutInfo(checkoutUser.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchCheckoutData = async ({
	auth0Token,
	setCheckoutData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let checkoutData = await getCheckoutData(auth0Token);
		if (checkoutData?.success) {
			setCheckoutData(checkoutData.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const updateCheckoutData = async ({
	auth0Token,
	checkoutData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await putCheckout(checkoutData, auth0Token);

		if (response.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const createCheckout = async ({
	auth0Token,
	items,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postCheckout(items, auth0Token);
		if (response.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
