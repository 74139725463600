import React from "react";
import { SouthAfricanRand } from "../../utils/helpers";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import DesktopCartSummary from "../DesktopCartSummary";
import MobileCartSummary from "../MobileCartSummary";


export const CartSummary = ({
	vat,
	cartNettTotal,
	logisticsFee,
	settlementAmount,
	vatAmount,
	total,
	goToCheckout
}) => {
	const { screenSize } = useSystemHook();

	const deliveryFeeToDisplay = () => {
		if (!!logisticsFee || logisticsFee === 0) {
			if (logisticsFee > 0) {
				return SouthAfricanRand.format(logisticsFee);
			}
			else {
				return "Free";
			}
		}
		else {
			return "TBD";
		}
	};

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<DesktopCartSummary cartNettTotal={cartNettTotal}
				                    logisticsFee={logisticsFee}
				                    settlementAmount={settlementAmount}
				                    vatAmount={vatAmount}
				                    total={total}
				                    vat={vat}
				                    deliveryFeeToDisplay={deliveryFeeToDisplay}

				/>
			}
			{screenSize === ScreenSize.Mobile &&
				<MobileCartSummary cartNettTotal={cartNettTotal}
				                   logisticsFee={logisticsFee}
				                   settlementAmount={settlementAmount}
				                   vatAmount={vatAmount}
				                   total={total}
				                   vat={vat}
				                   deliveryFeeToDisplay={deliveryFeeToDisplay}
				                   goToCheckout={goToCheckout}

				/>
			}
		</>
	);
};
