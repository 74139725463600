import React from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "../HomePage";
import CartPage from "../CartPage";
import ErrorPage from "../ErrorPage";
import LoginPage from "../LoginPage";
import LogoutPage from "../LogoutPage";
import PaymentPage from "../PaymentPage";
import PaymentConfirmationPage from "../PaymentConfirmationPage";
import ProductsPage from "../ProductsPage";
import AboutPage from "../AboutPage";
import TeamPage from "../TeamPage";
import FaqPage from "../FaqPage";
import ContactUsPage from "../ContactUsPage";
import CataloguesPage from "../CataloguesPage";
import CheckoutOrderInformationPage from "../CheckoutOrderInformationPage";
import CheckoutBillingAddressPage from "../CheckoutBillingAddressPage";
import CheckoutShippingAddressPage from "../CheckoutShippingAddressPage";
import CheckoutDeliveryMethodPage from "../CheckoutDeliveryMethodPage";
import CheckoutSummaryPage from "../CheckoutSummaryPage";
import PaymentAccountCreditPage from "../PaymentAccountCreditPage";
import PaymentEftPage from "../PaymentEftPage";
import PaymentCreditCardOnCollectionPage from "../PaymentCreditCardOnCollectionPage";
import CheckoutCollectionAddressPage from "../CheckoutCollectionAddressPage";
import TermsOfServicePage from "../TermsOfServicePage";
import PrivacyStatementPage from "../PrivacyStatementPage";
import HomePage from "../HomePage";
import AccountPage from "../AccountPage";
import PopiaPage from "../PopiaPage";
import CheckoutCreateAddressPage from "../CheckoutCreateAddressPage";
import PageWrapper from "../PageWrapper";
import ProductPage from "../ProductPage";
import OrderConfirmationPage from "../OrderConfirmationPage";
import PostLogin from "../PostLogin";

const AllRoute = () => {
	return (
		<div>
			<Routes>
				<Route exact
				       path="/"
				       element={<PageWrapper><Homepage/></PageWrapper>}
				/>
				<Route exact
				       path="/home"
				       element={<PageWrapper><HomePage/></PageWrapper>}
				/>
				<Route exact
				       path="/about"
				       element={<PageWrapper><AboutPage/></PageWrapper>}
				/>
				<Route exact
				       path="/team"
				       element={<PageWrapper><TeamPage/></PageWrapper>}
				/>
				<Route exact
				       path="/faq"
				       element={<PageWrapper><FaqPage/></PageWrapper>}
				/>
				<Route exact
				       path="/terms-of-service"
				       element={<PageWrapper><TermsOfServicePage/></PageWrapper>}
				/>
				<Route exact
				       path="/popia"
				       element={<PageWrapper><PopiaPage/></PageWrapper>}
				/>
				<Route exact
				       path="/privacy-statement"
				       element={<PageWrapper><PrivacyStatementPage/></PageWrapper>}
				/>
				<Route exact
				       path="/contact-us"
				       element={<PageWrapper><ContactUsPage/></PageWrapper>}
				/>
				<Route exact
				       path="/catalogues"
				       element={<PageWrapper><CataloguesPage/></PageWrapper>}
				/>
				<Route exact
				       path="/account"
				       element={<PageWrapper><AccountPage/></PageWrapper>}
				/>
				<Route path="/products/:productCategory/:productType?"
				       element={<PageWrapper><ProductsPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/product/:productSku"
					element={<PageWrapper><ProductPage/></PageWrapper>}
				/>
				<Route exact
				       path="/cart"
				       element={<PageWrapper><CartPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/information"
					element={<PageWrapper><CheckoutOrderInformationPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/billing"
					element={<PageWrapper><CheckoutBillingAddressPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/billing/create"
					element={<PageWrapper><CheckoutCreateAddressPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/shipping/create"
					element={<PageWrapper><CheckoutCreateAddressPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/collection"
					element={<PageWrapper><CheckoutCollectionAddressPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/shipping"
					element={<PageWrapper><CheckoutShippingAddressPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/delivery-method"
					element={<PageWrapper><CheckoutDeliveryMethodPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/checkout/summary"
					element={<PageWrapper><CheckoutSummaryPage/></PageWrapper>}
				/>
				<Route exact
				       path="/login"
				       element={<LoginPage/>}
				/>
				<Route exact
				       path="/payment"
				       element={<PageWrapper><PaymentPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/payment/account-credit"
					element={<PageWrapper><PaymentAccountCreditPage/></PageWrapper>}
				/>
				<Route exact
				       path="/payment/eft"
				       element={<PageWrapper><PaymentEftPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/payment/credit-card-on-collection"
					element={<PageWrapper><PaymentCreditCardOnCollectionPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/payment-confirmation/:paymentId"
					element={<PageWrapper><PaymentConfirmationPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/order-confirmation/:orderId"
					element={<PageWrapper><OrderConfirmationPage/></PageWrapper>}
				/>
				<Route
					exact
					path="/post-login"
					element={<PostLogin/>}
				/>
				<Route exact
				       path="/logout" element={<PageWrapper><LogoutPage/></PageWrapper>}/>
				<Route exact
				       path="/error"
				       element={<PageWrapper><ErrorPage/></PageWrapper>}/>
				<Route path="*" element={<PageWrapper><ErrorPage/></PageWrapper>}/>
			</Routes>
		</div>
	);
};

export default AllRoute;