import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import PrivacyStatement from "../../components/PrivacyStatement";


const PrivacyStatementPage = ({
	genericDataLoaded,
	genericPageContent
}) => {

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	const allLoadingStates = [
	];

	const pageLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false;

	return (
		<Fragment>
			<PrivacyStatement/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PrivacyStatementPage;
