import React, { Fragment } from 'react';
import { useMediaQuery } from "react-responsive";
import { Box, Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";

const PageHeader = ({
	title,
	subtitle
}) => {
	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const HeaderContainer = styled(Box)(({ theme }) => ({
		backgroundColor: '#f5f5f5',
		padding: theme.spacing(2, 4)
	}));

	return (
		<Fragment>
			<HeaderContainer>
				<Typography variant="h5" gutterBottom>
					{title}
				</Typography>
				{subtitle && (
					<Typography variant="body1" color="textSecondary">
						{subtitle}
					</Typography>
				)}
			</HeaderContainer>
		</Fragment>
	)
}

export default PageHeader;