const rootStyles = getComputedStyle(document.documentElement);

const resolveCssVar = (varName, fallback) => rootStyles.getPropertyValue(varName).trim() || fallback;

const cssVariables = {
	primaryColor: resolveCssVar('--primary-color', '#f5f5f5'),
	secondaryColor: resolveCssVar('--secondary-color', 'rgb(203, 24, 24)'),
	accentColor: resolveCssVar('--accent-color', 'rgba(255, 241, 0, 1)'),
	surfaceColor: resolveCssVar('--surface-color', '#ffffff'),
	successColor: resolveCssVar('--success-color', '#388e3c'),
	errorColor: resolveCssVar('--error-color', '#d32f2f'),
	warningColor: resolveCssVar('--warning-color', '#f57c00'),
	infoColor: resolveCssVar('--info-color', '#0288d1'),
	textColor: resolveCssVar('--text-color', '#333333'),
	secondaryTextColor: resolveCssVar('--text-secondary-color', '#666666'),
	backgroundColor: resolveCssVar('--background-color', '#f5f5f5'),
	borderRadiusXS: resolveCssVar('--border-radius-xs', '0.125rem'),
	borderRadiusS: resolveCssVar('--border-radius-s', '0.25rem'),
	borderRadiusM: resolveCssVar('--border-radius-m', '0.5rem'),
	borderRadiusL: resolveCssVar('--border-radius-l', '0.75rem'),
	borderRadiusXL: resolveCssVar('--border-radius-xl', '1rem'),
	blueButtonColor: resolveCssVar('--blue-button-color', '#0b79bf'),
	fontSizeXXS: resolveCssVar('--font-size-xxs', '0.75rem'),
	fontSizeXS: resolveCssVar('--font-size-xs', '0.875rem'),
	fontSizeS: resolveCssVar('--font-size-s', '1rem'),
	fontSizeM: resolveCssVar('--font-size-m', '1.125rem'),
	fontSizeL: resolveCssVar('--font-size-l', '1.25rem'),
	fontSizeXL: resolveCssVar('--font-size-xl', '1.5rem'),
	fontSizeXXL: resolveCssVar('--font-size-xxl', '2rem'),
	lineHeightXXS: resolveCssVar('--line-height-xxs', '1.125rem'),
	lineHeightXS: resolveCssVar('--line-height-xs', '1.3125rem'),
	lineHeightS: resolveCssVar('--line-height-s', '1.5rem'),
	lineHeightM: resolveCssVar('--line-height-m', '1.6875rem'),
	lineHeightL: resolveCssVar('--line-height-l', '1.875rem'),
	lineHeightXL: resolveCssVar('--line-height-xl', '2.25rem'),
	lineHeightXXL: resolveCssVar('--line-height-xxl', '3rem'),
};

export default cssVariables;
