import axios from "axios";
import { ApiHeaders } from "../utils/constants";
import axiosRetry from "axios-retry";

const authenticationUrl = process.env.REACT_APP_IDP_URL;

export const putCompleteProfileData = async (data) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${authenticationUrl}/api/authentication/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!data?.auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${data.auth0Token}`;
		}

		const bodyData = {
			firstName: data.firstName,
			lastName: data.lastName,
			phone: data.mobile,
			wholesaleTrader: data.wholesaleTrader,
			personalIdNumber: data.personalIdNumber,
			companyRegNo: data.companyRegNo
		};

		const requestUrl = `update-profile`;

		return await instance
			.put(requestUrl, bodyData)
			.then((response) => {
				return {
					error: response.data.error,
					success: response.data.success,
					message: response.data.message
				};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		//notifyError("Could not log you in", e);
	}
};
