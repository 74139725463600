export const getItem = (key) => {
	if (typeof Storage !== "undefined") {
		return localStorage.getItem(key);
	}
	else {
		console.error("Browser does not support this functionality.");
	}
};

export const setItem = (key, value) => {
	if (typeof Storage !== "undefined") {
		localStorage.setItem(key, value);
	}
	else {
		console.error("Browser does not support this functionality.");
	}
};

export const removeItem = (key) => {
	if (typeof Storage !== "undefined") {
		localStorage.removeItem(key);
	}
	else {
		console.error("Browser does not support this functionality.");
	}
};
