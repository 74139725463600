import React, { Fragment, useEffect, useState } from "react";
import Payment from "../../components/Payment";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { removeAuthCookies } from "../../api/authentication";
import { AccountType, ApiCallStatus, PaymentMethod } from "../../utils/constants";
import {
	fetchPaymentOrderData
} from "../PageFunctions/paymentFunctions";
import { fetchLiveTradeAccountData } from "../PageFunctions/tecfinityFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const clearCookies = async () => {
	await removeAuthCookies();
};

const PaymentPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [accountCreditPaymentOrderInfo, setAccountCreditPaymentOrderInfo] = useState(null);
	const [fullTradeAccount, setFullTradeAccount] = useState(null);

	const [accountCreditPaymentOrderInfoCallStatus, setAccountCreditPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fullTradeAccountCallStatus, setFullTradeAccountCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => navigate("/login", {state: {from: location}});
	const gotoPaymentPayfastPage = () => navigate(`/payment/payfast`);
	const gotoPaymentAccountCredit = () => navigate(`/payment/account-credit`);
	const gotoPaymentEftPage = () => navigate(`/payment/eft`);
	const gotoPaymentCreditCardOnCollectionPage = () => navigate(`/payment/credit-card-on-collection`);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchAccountCreditPaymentOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPaymentOrderData({
			auth0Token,
			paymentType: PaymentMethod.AccountCredit,
			setPaymentOrderInfo: setAccountCreditPaymentOrderInfo,
			setStatusInProgress: () => setAccountCreditPaymentOrderInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setAccountCreditPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setAccountCreditPaymentOrderInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setAccountCreditPaymentOrderInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchFullTradeAccountDataAsync = async () => {
		if (!!genericPageContent?.tradeAccountId && genericPageContent.accountType === AccountType.Commercial) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchLiveTradeAccountData({
				auth0Token,
				tradeAccountId: genericPageContent?.tradeAccountId,
				setFullTradeAccount,
				setStatusInProgress: () => setFullTradeAccountCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFullTradeAccountCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFullTradeAccountCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFullTradeAccountCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const authorizedPageLoad = async () => {

	};

	const unAuthorizedPageLoad = async () => {
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (genericDataLoaded === true && !!genericPageContent?.tradeAccountId) {
			fetchFullTradeAccountDataAsync();
		}
	}, [genericPageContent?.tradeAccountId, genericDataLoaded]);

	useEffect(() => {
		if (fullTradeAccountCallStatus === ApiCallStatus.Succeeded) {
			fetchAccountCreditPaymentOrderDataAsync();
		}
	}, [fullTradeAccountCallStatus]);

	const allLoadingStates = [
		accountCreditPaymentOrderInfoCallStatus,
		fullTradeAccountCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<Payment
				accountCreditPaymentOrderInfo={accountCreditPaymentOrderInfo}
				tradeAccount={genericPageContent?.tradeAccount}
				fullTradeAccount={fullTradeAccount}
				gotoPaymentPayfastPage={gotoPaymentPayfastPage}
				gotoPaymentAccountCredit={gotoPaymentAccountCredit}
				gotoPaymentEftPage={gotoPaymentEftPage}
				gotoPaymentCreditCardOnCollectionPage={gotoPaymentCreditCardOnCollectionPage}
			/>=
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PaymentPage;
