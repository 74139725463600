import { ScreenSize, ScreenSizeBreakPoints } from "../utils/constants";
import { useMediaQuery } from "react-responsive";
import { useMemo } from "react";

const useSystemHook = () => {
	const isMobile = useMediaQuery({ query: `(max-width: ${ScreenSizeBreakPoints.MobileMax})` });
	const isTablet = useMediaQuery({ query: `(min-width: ${ScreenSizeBreakPoints.TabletMin}) and (max-width: ${ScreenSizeBreakPoints.TabletMax})` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${ScreenSizeBreakPoints.DesktopMin})` });

	const screenSize = useMemo(() => {
		if (isMobile) {
			return ScreenSize.Mobile;
		} else if (isTablet) {
			return ScreenSize.Tablet;
		} else if (isDesktop) {
			return ScreenSize.Desktop;
		}
		return ScreenSize.Desktop;
	}, [isMobile, isTablet, isDesktop]);

	return {
		screenSize
	}
}

export default useSystemHook;