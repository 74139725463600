import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledImage = styled('img')(({ size }) => {
	let width;

	switch (size) {
		case 'xs':
			width = '30px';
			break;
		case 'sm':
			width = '50px';
			break;
		case 'md':
			width = '100px';
			break;
		case 'lg':
			width = '150px';
			break;
		case 'xl':
			width = '200px';
			break;
		default:
			width = '100px';
	}

	return {
		width,
		height: 'auto',
		display: 'block',
		cursor: 'pointer',
	};
});

const Image = ({ src, alt, href, size = 'md', centered = false }) => {
	return (
		<Box
			component={href ? 'a' : 'div'}
			href={href}
			sx={{
				display: centered ? 'block' : 'inline-block',
				textAlign: centered ? 'center' : 'left',
			}}
			style={{ cursor: !!href ? 'pointer' : "none" }}
		>
			<StyledImage src={src} alt={alt} size={size} />
		</Box>
	);
};

export default Image;
