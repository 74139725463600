import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { removeAuthCookies } from "../../api/authentication";
import {
	useLocation,
	useNavigate,
	useParams
} from "react-router-dom";
import { cloneDeep } from "lodash";
import { ApiCallStatus } from "../../utils/constants";
import {
	fetchCategoriesAndTypes,
	fetchFiltersData,
	fetchProductsData
} from "../PageFunctions/productFunctions";
import ProductGrid from "../../components/ProductGrid";
import { fetchProductsPrices } from "../PageFunctions/priceFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const clearCookies = async () => {
	await removeAuthCookies();
};

const ProductsPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const navigate = useNavigate();
	const location = useLocation();
	const {productCategory, productType} = useParams();
	const [products, setProducts] = useState(null);
	const [pricingData, setPricingData] = useState(null);
	const [filters, setFilters] = useState([]);
	const [activeFilters, setActiveFilters] = useState({
		productCategory: "",
		productType: "",
		vendor: "",
		vehicleYearId: "",
		make: "",
		model: "",
		year: null,
		variant: "",
		pageNumber: null
	});
	const [allProductIds, setAllProductIds] = useState([]);
	const [categoriesAndTypes, setCategoriesAndTypes] = React.useState([]);
	const [total, setTotal] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(18);
	const [productsCallStatus, setProductsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [filtersCallStatus, setFiltersCallStatus] = useState(ApiCallStatus.NotStarted);
	const [pricesCallStatus, setPricesCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchCategoriesAndTypesCallStatus, setFetchCategoriesAndTypesCallStatus] = useState(ApiCallStatus.NotStarted);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchProductsDataAsync = async () => {
		/* USE VEHICLE SAVED IN SEARCH
				const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);
				let vehicle = null;

				if (!!vehicleString) {
					const vehicleObject = JSON.parse(vehicleString);
					vehicle = cloneDeep(vehicleObject);
				}


				if (!!vehicle) {
					if (!!vehicle.vehicleYearId) {
						if (!vehicleYearId && !vehicle?.vehicleYearId) vehicleYearId = vehicle.vehicleYearId;
					}
					else {
						if (!make && !!vehicle?.make) make = vehicle.make;
						if (!model && !!vehicle?.model) make = vehicle.model;
						if (!variant && !!vehicle?.variant) make = vehicle.variant;
						if (!year && !!vehicle?.year) make = vehicle.year;
					}
				}
				*/

		const productQueryParams = [
			activeFilters.productCategory,
			activeFilters.productType,
			activeFilters.vendor
		];
		const vehicleQueryParams = [
			activeFilters.vehicleYearId,
			activeFilters.make,
			activeFilters.model,
			activeFilters.variant,
			activeFilters.year
		];

		const atLeastOneProductQueryParam = productQueryParams.some((x) => !!x);
		const atLeastOneVehicleQueryParam = vehicleQueryParams.some((x) => !!x);

		if (!!atLeastOneProductQueryParam || !!atLeastOneVehicleQueryParam) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchProductsData({
				auth0Token,
				productCategory: activeFilters.productCategory,
				productType: activeFilters.productType,
				vendor: activeFilters.vendor,
				vehicleYearId: activeFilters.vehicleYearId,
				make: activeFilters.make,
				model: activeFilters.model,
				variant: activeFilters.variant,
				year: activeFilters.year,
				pageNumber: activeFilters.pageNumber,
				setProducts,
				setAllProductIds,
				setTotal,
				setPageSize,
				setStatusInProgress: () => setProductsCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setProductsCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setProductsCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setProductsCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchPricesDataAsync = async () => {
		const productSkus = products.map((x) => x.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsPrices({
			auth0Token,
			productSkus,
			setPricingData,
			setStatusInProgress: () => setPricesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPricesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPricesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPricesCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchFiltersDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchFiltersData({
			auth0Token,
			allProductIds,
			setFilters,
			currentProductCategory: activeFilters.productCategory,
			currentProductType: activeFilters.productType,
			currentVendor: activeFilters.vendor,
			currentMake: activeFilters.make,
			currentModel: activeFilters.model,
			currentVariant: activeFilters.variant,
			currentYear: activeFilters.year,
			setStatusInProgress: () => setFiltersCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFiltersCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFiltersCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFiltersCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCategoriesAndTypesAsync = async () => {
		await fetchCategoriesAndTypes({
			setCategoriesAndTypesData: setCategoriesAndTypes,
			setStatusInProgress: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.Error),
		});
	};

	const handlePageNumberChange = async (event, value) => {
		await updateUrlQueryValue("pageNumber", Number(value));
	};

	const updateUrlQueryValue = async (key, value) => {
		setProductsCallStatus(ApiCallStatus.NotStarted);
		const searchParams = new URLSearchParams(location.search);

		searchParams.set(key, value);
		window.location.href = `${location.pathname}${!!searchParams.size > 0 ? "?" : ""}${searchParams.toString()}`;
	};

	const updateQueryParameters = async (queryParameters) => {
		window.location.href = `${location.pathname}${!!queryParameters ? "?" : ""}${queryParameters}`;
	}

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		if (!productCategory) {
			navigate({
				pathname: "/products/all",
				search: `?${searchParams.toString()}`
			});
		}

		const activeFiltersCopy = cloneDeep(activeFilters);

		const pn = searchParams.get("pageNumber");

		activeFiltersCopy.make = searchParams.get("make");
		activeFiltersCopy.model = searchParams.get("model");
		activeFiltersCopy.year = searchParams.get("year");
		activeFiltersCopy.variant = searchParams.get("variant");
		activeFiltersCopy.vendor = searchParams.get("vendor");
		activeFiltersCopy.pageNumber = Number(pn) ?? 1;
		activeFiltersCopy.productCategory = productCategory;
		activeFiltersCopy.productType = productType;

		setPageNumber(Number(pn) ?? 1);
		setActiveFilters(activeFiltersCopy);
	}, [location]);

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				fetchCategoriesAndTypesAsync();
				fetchProductsDataAsync();
			} else {
				fetchCategoriesAndTypesAsync();
				fetchProductsDataAsync();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		setFiltersCallStatus(ApiCallStatus.NotStarted);

		if (!!activeFilters) {
			if (productsCallStatus === ApiCallStatus.NotStarted) {
				fetchProductsDataAsync();
			}
			else {
				fetchFiltersDataAsync();
			}
		}
	}, [activeFilters, productsCallStatus]);

	useEffect(() => {
		if (productsCallStatus === ApiCallStatus.Succeeded && !!products && products.length > 0) {
			fetchPricesDataAsync();
			fetchFiltersDataAsync();
		}
	}, [productsCallStatus, products]);

	const allLoadingStates = [
		productsCallStatus,
		filtersCallStatus,
		pricesCallStatus,
		fetchCategoriesAndTypesCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<ProductGrid
				products={products}
				pricingData={pricingData}
				customerCategory={genericPageContent?.customerCategory}
				categoriesAndTypes={categoriesAndTypes}
				filters={filters}
				activeFilters={activeFilters}
				setActiveFilters={setActiveFilters}
				total={total}
				pageNumber={pageNumber}
				pageSize={pageSize}
				handlePageNumberChange={handlePageNumberChange}
				updateQueryParameters={updateQueryParameters}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default ProductsPage;