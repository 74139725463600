import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import PaymentConfirmation from "../../components/PaymentConfirmation";
import { removeAuthCookies } from "../../api/authentication";
import { ApiCallStatus } from "../../utils/constants";
import { fetchPaymentData } from "../PageFunctions/paymentFunctions";
import {
	fetchOrderData,
	removeOrderCookies
} from "../PageFunctions/orderFunctions";
import { useCart } from "react-use-cart";
import { useAuth0 } from "@auth0/auth0-react";



const clearCookies = async () => {
	await removeAuthCookies();
};

const PaymentConfirmationPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const {emptyCart} = useCart();
	const {paymentId} = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const [orderData, setOrderData] = useState(null);
	const [paymentData, setPaymentData] = useState(null);

	const [fetchPaymentDataCallStatus, setFetchPaymentDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [removeOrderCookiesCallStatus, setRemoveOrderCookiesCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoHomePage = () => navigate("/");


	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchPaymentDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPaymentData({
			auth0Token,
			paymentId,
			setPaymentData,
			setStatusInProgress: () => setFetchPaymentDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchPaymentDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchPaymentDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchPaymentDataCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrderData({
			auth0Token,
			setOrderData,
			setStatusInProgress: () => setFetchOrderDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchOrderDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchOrderDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchOrderDataCallStatus(ApiCallStatus.Error)
		});
	};

	const removeOrderCookiesAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await removeOrderCookies({
			auth0Token,
			setStatusInProgress: () => setRemoveOrderCookiesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setRemoveOrderCookiesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setRemoveOrderCookiesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setRemoveOrderCookiesCallStatus(ApiCallStatus.Error)
		});
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			} else {
				fetchPaymentDataAsync();
				fetchOrderDataAsync();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (removeOrderCookiesCallStatus === ApiCallStatus.Succeeded) {
			emptyCart();
			gotoHomePage();
		}
	}, [removeOrderCookiesCallStatus]);

	const allLoadingStates = [
		fetchPaymentDataCallStatus,
		fetchOrderDataCallStatus,
		removeOrderCookiesCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<PaymentConfirmation
				paymentData={paymentData}
				orderData={orderData}
				handleCloseClick={removeOrderCookiesAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default PaymentConfirmationPage;
