import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FilterAccordion = ({
	title,
	options,
	activeFilters,
	onFilterChange
}) => {
	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				{title}
			</AccordionSummary>
			<AccordionDetails>
				<FormGroup>
					{options.map((option, index) => (
						<FormControlLabel
							key={index}
							label={option.text}
							control={
								<Checkbox
									checked={activeFilters[option.name] === option.value}
									onChange={onFilterChange}
									name={option.name}
									value={option.value}
								/>
							}
						/>
					))}
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};

export default FilterAccordion;
