import React, { Fragment, useEffect, useState } from "react";
import { cloneDeep, remove } from "lodash";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import MobileCart from "../MobileCart";
import { confirmationChoices } from "../ConfirmationModal";
import DesktopCart from "../DesktopCart";
import PageHeader from "../PageHeader";

const Cart = ({
	loading,
	customerCategory,
	pricingData,
	vat,
	cartNettTotal,
	settlementAmount,
	vatAmount,
	total,
	createCheckout,
	goBack
}) => {
	const {
		items,
		setItems,
		removeItem,
		emptyCart,
		clearCartMetadata,
		updateItemQuantity
	} = useArgusCart();
	const [clearCartModalOpen, setClearCartModalOpen] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [editQuantity, setEditQuantity] = useState({});
	const [errorMessages, setErrorMessages] = useState({});

	const scrollToTop = () => {
		window.scroll(0, 0);
	};

	useEffect(() => {
		scrollToTop();
	}, []);

	useEffect(() => {
		const initialValues = items.reduce((acc, item) => {
			acc[item.id] = item.quantity.toString();
			return acc;
		}, {});
		setInputValues(initialValues);
	}, [items]);

	const handleInputChange = (itemId, value) => {
		setInputValues((prevValues) => ({
			...prevValues,
			[itemId]: value
		}));
	};

	const handleInputConfirm = (itemId, value) => {
		const quantity = parseInt(value, 10);
		if (!isNaN(quantity) && quantity > 0) {
			updateItemQuantity(itemId, quantity);
		}
		else {
			setInputValues((prevValues) => ({
				...prevValues,
				[itemId]:
					items.find((item) => item.id === itemId)?.quantity.toString() || "1"
			}));
		}
	};

	const clearCart = () => {
		emptyCart();
		clearCartMetadata();
		setClearCartModalOpen(false);
	};

	const handleClearCartModalOpen = () => setClearCartModalOpen(true);

	const closeModalHandle = () => {
		setClearCartModalOpen(false);
	};

	const handleClearCartModalClose = (confirmationChoice) => {
		const shouldClear = confirmationChoices.confirm === confirmationChoice;

		if (!!shouldClear) {
			clearCart();
		}

		closeModalHandle();
	};

	const onRemoveItemClick = (sku) => {
		const cartLibItemsCopy = cloneDeep(items);
		remove(cartLibItemsCopy, (x) => x.sku === sku);
		removeItem(sku);
		setItems(cartLibItemsCopy);
	};

	const handleCheckoutClick = async () => {
		await createCheckout();
	};

	const goToCheckout = async () => {
		await handleCheckoutClick();
	};

	const handleQuantityChange = (id, sku, value) => {
		const quantity = parseInt(value);

		if (isNaN(quantity) || quantity <= 0) {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: 'Quantity must be a positive number.',
			}));
		} else if (quantity > 500) {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: 'For quantities greater than 500, please contact our sales team.',
			}));
		} else {
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
			updateItemQuantity(id, quantity);
		}
	};

	const handleIncrement = (id, sku, currentQuantity) => {
		if (currentQuantity < 500) {
			updateItemQuantity(id, currentQuantity + 1);
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
		}
	};

	const handleDecrement = (id, sku, currentQuantity) => {
		if (currentQuantity > 1) {
			updateItemQuantity(id, currentQuantity - 1);
			setErrorMessages((prev) => ({
				...prev,
				[sku]: '',
			}));
		}
	};

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	return (
		<Fragment>
			<PageHeader title="Cart" />
			{isMobile === true &&
				<MobileCart pricingData={pricingData}
				            loading={loading}
				            customerCategory={customerCategory}
				            cartNettTotal={cartNettTotal}
				            clearCartModalOpen={clearCartModalOpen}
				            handleClearCart={handleClearCartModalOpen}
				            handleClearCartModalClose={handleClearCartModalClose}
				            total={total}
				            onBackClick={goBack}
				            goToCheckout={goToCheckout}
				            items={items}
				            onRemoveItemClick={onRemoveItemClick}
				            vat={vat}
				            vatAmount={vatAmount}
				            settlementAmount={settlementAmount}
				            updateItemQuantity={updateItemQuantity}
				            handleQuantityChange={handleQuantityChange}
				            errorMessages={errorMessages}
				            setErrorMessages={setErrorMessages}
				            handleIncrement={handleIncrement}
				            handleDecrement={handleDecrement}
				/>
			}
			{isMobile === false &&
				<DesktopCart pricingData={pricingData}
				             loading={loading}
		                     customerCategory={customerCategory}
		                     cartNettTotal={cartNettTotal}
		                     clearCartModalOpen={clearCartModalOpen}
		                     handleClearCart={handleClearCartModalOpen}
		                     handleClearCartModalClose={handleClearCartModalClose}
		                     total={total}
		                     onBackClick={goBack}
		                     goToCheckout={goToCheckout}
		                     items={items}
		                     onRemoveItemClick={onRemoveItemClick}
				             vat={vat}
				             vatAmount={vatAmount}
				             settlementAmount={settlementAmount}
				             updateItemQuantity={updateItemQuantity}
				             handleQuantityChange={handleQuantityChange}
				             errorMessages={errorMessages}
				             setErrorMessages={setErrorMessages}
				             handleIncrement={handleIncrement}
				             handleDecrement={handleDecrement}
				/>
			}
		</Fragment>
	);
};

export default Cart;