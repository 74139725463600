import { getGenericPageContent, getHomePageContent } from "../../api/pageContentController";

export const fetchGenericPageContentData = async ({
	auth0Token,
	setPageContent,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getGenericPageContent(auth0Token);

		if (!!response?.success) {
			setPageContent(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchHomePageContentData = async ({
	auth0Token,
	setPageContent,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getHomePageContent(auth0Token);

		if (!!response?.success) {
			setPageContent(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
