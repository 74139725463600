import React, { useEffect, useState } from "react";
import Whatsapp_Logo from "./logo's/Whatsapp_Logo.png";
import { useMediaQuery } from "react-responsive";
import {
	Link,
	Grid,
	Box,
	Container,
	Divider, Typography
} from "@mui/material";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Image from "../Image"
import "./style.css";

const SiteFooter = () => {
	const [currentPage, setCurrentPage] = useState(null);

	const location = useLocation();

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	useEffect(() => {
		const pathName = location.pathname;
		const pageName = pathName.split("/").pop();
		setCurrentPage(pageName);
	}, [location]);

	const goToTop = () => {
		document.querySelector("body").scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	return (
		<Box className={isMobile ? "argus-footer-mobile" : "argus-footer"} sx={{ padding: "20px 0", color: "#fff" }}>
			<Container maxWidth="lg">
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item xs={12} md={6}>
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
							<Image
								href="/"
								src="https://argusweb.azureedge.net/argus-web/logo.svg"
								alt="Logo"
								size={"xl"}
							/>
						</Box>
					</Grid>

					{!isMobile && (
						<Grid item xs={12} md={6}>
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: "center" }}>
									<Link href="/faq" variant="caption" color="inherit" underline="hover" sx={{ mr: '1rem' }}>Help</Link>
									<Link href="#" variant="caption" color="inherit" underline="hover" sx={{ mr: '1rem' }}>Account</Link>
									<Link href="/team" variant="caption" color="inherit" underline="hover" sx={{ mr: '1rem' }}>Company</Link>
									<Link href="/contact-us" variant="caption" color="#fff" underline="hover" sx={{ mr: '1rem' }}>Contact Us</Link>
									<Image
										src={Whatsapp_Logo}
										alt="Logo"
										size="xs"
										href="https://wa.link/ov1mre"
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mt: '1rem' }}>
									<Link href="/terms-of-service" color="yellow" underline="hover" variant="caption">Terms & Conditions</Link>
									<Divider orientation="vertical" flexItem sx={{ backgroundColor: '#fff' }} />
									<Link href="/privacy-statement" color="yellow" underline="hover" variant="caption">Privacy Policy</Link>
									<Divider orientation="vertical" flexItem sx={{ backgroundColor: '#fff' }} />
									<Link href="/popia" color="yellow" underline="hover" variant="caption">POPIA Act</Link>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mt: '1rem' }}>
									<Typography variant="caption">
										{`©Copyright ${moment().year()} by Argus Motor Company. All Rights Reserved.`}
									</Typography>
								</Box>
							</Box>
						</Grid>
					)}

					{isMobile && (
						<Grid item xs={12}>
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", mt: "3rem" }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: "center" }}>
									<Link href="/faq" color="#fff" variant="caption" underline="hover" sx={{ mr: '0.5rem' }}>Help</Link>
									<Link href="#" color="#fff" variant="caption" underline="hover" sx={{ mr: '0.5rem' }}>Account</Link>
									<Link href="/team" color="#fff" variant="caption" underline="hover" sx={{ mr: '0.5rem' }}>Company</Link>
									<Link href="/contact-us" color="#fff" variant="caption" underline="hover" sx={{ mr: '0.5rem' }}>Contact Us</Link>
									<Image
										src={Whatsapp_Logo}
										alt="Logo"
										size="xs"
										href="https://wa.link/ov1mre"
									/>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: '0.5rem' }}>
									<Link href="/terms-of-service" sx={{ mr: '0.5rem' }} color="yellow" underline="hover" variant="caption">Terms & Conditions</Link>
									<Divider orientation="vertical" flexItem sx={{ backgroundColor: '#fff' }} />
									<Link href="/privacy-statement" sx={{ ml: '0.5rem', mr: '0.5rem' }} color="yellow" underline="hover" variant="caption">Privacy Policy</Link>
									<Divider orientation="vertical" flexItem sx={{ backgroundColor: '#fff' }} />
									<Link href="/popia" sx={{ ml: '0.5rem' }} color="yellow" underline="hover" variant="caption">POPIA Act</Link>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: '0.5rem' }}>
									<Typography variant="caption">
										{`©Copyright ${moment().year()} by Argus Motor Company.`}
									</Typography>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: '0.5rem' }}>
									<Typography variant="caption">
										All Rights Reserved.
									</Typography>
								</Box>
							</Box>
						</Grid>
					)}
				</Grid>
			</Container>
		</Box>
	);
};

export default SiteFooter;