import React, { useEffect } from "react";

const PaymentCreditCardOnCollection = ({paymentOrderInfo, onSubmit}) => {
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">Payment</div>
			</div>
			<Grid container>
				<Grid.Row>
					<Grid.Column width={16}>
						<Segment padded>
							<Grid container>
								<Grid.Row>
									<Grid.Column width={16}>
										<Image
											src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
											size="small"
											fluid
											centered
										/>
									</Grid.Column>
								</Grid.Row>
								{!!paymentOrderInfo && (
									<>
										<Grid.Row textAlign="center">
											<Grid.Column width={16}>
												<Button
													className="blue-button"
													type="button"
													onClick={onSubmit}
												>
													Pay
												</Button>
											</Grid.Column>
										</Grid.Row>
									</>
								)}
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default PaymentCreditCardOnCollection;
