import React from "react";
import AllRoute from "../router";
import "./App.css";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import PageLayout from "../PageLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import theme from './theme';

const Auth0ProviderWithRedirectCallback = ({children, ...props}) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
};

const App = () => {
	return (
		<Router>
			<Auth0ProviderWithRedirectCallback
				domain={process.env.REACT_APP_AUTHO_DOMAIN}
				clientId={process.env.REACT_APP_AUTHO_CLIENT_ID}
				authorizationParams={{
					audience: process.env.REACT_APP_AUTHO_AUDIENCE,
					scope: "openid profile email offline_access email_verified"
				}}
			>
				<ThemeProvider theme={theme}>
					<PageLayout>
						<AllRoute/>
						<ToastContainer/>
					</PageLayout>
				</ThemeProvider>
			</Auth0ProviderWithRedirectCallback>
		</Router>
	);
};

export default App;
