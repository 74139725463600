import React, { createContext, useContext, useState } from "react";
import { useCart } from "react-use-cart";


const ArgusCartContext = createContext({

});

export const ArgusCartProvider = ({ children }) => {
	const {
		addItem,
		removeItem,
		totalItems,
		items,
		setItems,
		emptyCart,
		clearCartMetadata,
		updateItemQuantity
	} = useCart();

	const [addedToCartViewOpen, setAddedToCartViewOpen] = useState(false);
	const [itemAddedToCart, setItemAddedToCart] = useState(null);

	const handleAddToCart = ({
		id,
		name,
		vendor,
		sku,
		image
	}) => {
		const cartProduct = {
			id: id,
			name: name,
			brand: vendor,
			sku: sku,
			price: 0,
			image: image,
		};
		addItem(cartProduct, 1);
		setItemAddedToCart(cartProduct);
		setAddedToCartViewOpen(true);
	}

	const handleCloseAddedToCartView = () => {
		setAddedToCartViewOpen(false);
		setItemAddedToCart(null);
	}

	return (
		<ArgusCartContext.Provider value={{
			handleAddToCart,
			handleCloseAddedToCartView,
			addedToCartViewOpen,
			itemAddedToCart,
			removeItem,
			totalItems,
			items,
			setItems,
			emptyCart,
			clearCartMetadata,
			updateItemQuantity
		}}>
			{children}
		</ArgusCartContext.Provider>
	);
};

export const useArgusCart = () => useContext(ArgusCartContext);