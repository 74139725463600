import React, { useEffect } from "react";
import PageHeader from "../PageHeader";
import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material";

const PaymentEft = ({paymentOrderInfo, onSubmit}) => {
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<>
			<PageHeader title="Payment" />
			<Box  sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>

				<Grid container justifyContent="center" spacing={4}>
					<Grid item xs={12}>
						<Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
							{/* EFT Payment Icon */}
							<Avatar
								src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
								alt="EFT Payment"
								sx={{ width: 100, height: 100, margin: '0 auto 1.5rem' }}
							/>
							{/* EFT Payment Info */}
							<Typography variant="body1" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
								Direct EFT
							</Typography>
							<Typography variant="body2" sx={{ marginBottom: '0.5rem' }}>
								Please note that shipment will only take place once the payment reflects in our account.
							</Typography>
							<Typography variant="body2" sx={{ marginBottom: '1.5rem' }}>
								(Payment is through Nedbank or Standard Bank)
							</Typography>

							{/* Pay Button */}
							{!!paymentOrderInfo && (
								<Button
									variant="contained"
									color="primary"
									onClick={onSubmit}
									sx={{ marginTop: '1.5rem' }}
								>
									Pay
								</Button>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default PaymentEft;
