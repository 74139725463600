import React, { Fragment, useEffect } from "react";
import { filter, find, includes, lowerCase, orderBy } from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { useMediaQuery } from "react-responsive";
import { Box, Grid, Paper, Typography, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";


const VendorSlider = ({
	vendors
}) => {
	const navigate = useNavigate();
	const [vendorsToDisplay, setVendorsToDisplay] = React.useState([]);
	const staticVendorOrder = [
		{ vendor: "ngk", vendorOrder: 1 },
		{ vendor: "kyb", vendorOrder: 2 },
		{ vendor: "fram", vendorOrder: 3 },
		{ vendor: "doe", vendorOrder: 4 },
		{ vendor: "beta", vendorOrder: 5 },
		{ vendor: "shield", vendorOrder: 6 },
		{ vendor: "q20", vendorOrder: 7 },
		{ vendor: "ate", vendorOrder: 8 },
		{ vendor: "optima", vendorOrder: 9 },
		{ vendor: "teknosa", vendorOrder: 10 }
	];

	const onVendorClick = (vendor) => {
		const vendorSlug = lowerCase(vendor.vendor_Name)
			.replaceAll(" ", "_")
			.trim();
		navigate(`/products/all?vendor=${vendorSlug}`);
	}

	const capitalize = (input) => {
		return input
			.toLowerCase()
			.split(" ")
			.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
			.join(" ");
	};


	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

	useEffect(() => {
		if (!!vendors) {
			const vendorNames = staticVendorOrder.map(v => v.vendor);
			const filteredVendors = filter(vendors, v =>
				includes(vendorNames, v.vendor_Name.replace(" ", "_").toLowerCase())
			);
			const filteredVendorsWithOrder = filteredVendors.map(v => {
				const staticVendor = find(
					staticVendorOrder,
					(s) => s.vendor === v.vendor_Name.replace(" ", "_").toLowerCase()
				);
				return { ...v, order: staticVendor.vendorOrder };
			});
			const orderedVendors = orderBy(filteredVendorsWithOrder, v => v.order);
			setVendorsToDisplay(orderedVendors);
		}
	}, [vendors]);

	return (
		<Fragment>
			{isMobile === false &&
				<>
					<Box sx={{ mt: 4, p: 2 }}>
						<Typography variant="h4" component="h2" gutterBottom sx={{ textAlign: 'center' }}>
							Featured Brands
						</Typography>

						<Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 4 }}>
							{vendorsToDisplay.map((vendor, index) => {
								const vendorName = capitalize(vendor.vendor_Name.replace("_", "").toLowerCase());
								return (
									<Grid item md={3} lg={3} key={index}>
										<a href={`/products/all?vendor=${vendor.vendor_Name.toLowerCase()}`}>
											<Tooltip title={vendor.vendor_Description} arrow placement="top">
												<Paper
													elevation={3}
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														height: '150px',
														backgroundColor: '#fff',
														padding: '8px',
														'&:hover': {
															boxShadow: '0 4px 20px rgba(0, 0, 0, 0.12)',
														},
													}}
												>
													<img
														src={vendor.vendor_Image}
														alt={vendorName}
														style={{ maxHeight: '100%', maxWidth: '100%' }}
													/>
												</Paper>
											</Tooltip>
										</a>
									</Grid>
								)
							})}
						</Grid>
					</Box>

				</>
			}
			{isMobile === true &&
				<>
					<Box sx={{mt: 4, p: 2, mb: 2 }}>
						<Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center' }}>
							Featured Brands
						</Typography>
					</Box>
					<section>
						<Swiper
							modules={[Autoplay, Navigation]}
							spaceBetween={10}
							slidesPerView={3}
							loop={true}
							autoplay={{
								delay: 3000,
								disableOnInteraction: true
							}}
							grabCursor={true}
						>
							{vendorsToDisplay.map((vendor, index) => {
								const vendorName = capitalize(vendor.vendor_Name.replace("_", "").toLowerCase())
								return (
									<SwiperSlide key={index}>
										<a href={`/products/all?vendor=${vendor.vendor_Name.toLowerCase()}`}>
											<Paper
												elevation={3}
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													height: '80px',
													backgroundColor: '#fff',
													p: 1,
												}}
											>
												<img
													src={vendor.vendor_Image}
													alt={vendorName}
													style={{ maxHeight: '100%', maxWidth: '100%' }}
												/>
											</Paper>
										</a>
									</SwiperSlide>
								)
							})}
						</Swiper>
					</section>
					<Box sx={{ mb: 4 }} />
				</>
			}
		</Fragment>
		// <section>
		// 	<h1 className="text-align-center">Featured brands</h1>
		// 	<Swiper
		// 		modules={[Autoplay, Navigation]}
		// 		spaceBetween={isDesktopOrLaptop ? 0 : 25}
		// 		slidesPerView={isDesktopOrLaptop ? 1 : 6}
		// 		navigation
		// 		loop={true}
		// 		pagination={{ clickable: true }}
		// 		scrollbar={{ draggable: true }}
		// 		autoplay={{
		// 			delay: 5000,
		// 			disableOnInteraction: true
		// 		}}
		// 		onSwiper={(swiper) => console.log("")}
		// 	>
		// 		{vendorsToDisplay.map((vendor, index) => {
		// 			const vendorName = capitalize(
		// 				vendor.vendor_Name.replace("_", "").toLowerCase()
		// 			)
		//
		// 			return (
		// 				<SwiperSlide key={index}>
		// 					<article className="vendor_container">
		// 						<Image
		// 							size="small"
		// 							src={vendor.vendor_Image}
		// 							alt={`slide_${index + 1}`}
		// 							onMouseEnter={() => handleVendorChange(vendorName)}
		// 						/>
		// 						<Link className="vendor_info_container" to={vendorLink}>
		// 							<h4>{vendor.vendor_Name}</h4>
		// 							<p>
		// 								{vendor.vendor_Description}
		// 							</p>
		// 						</Link>
		// 					</article>
		// 				</SwiperSlide>
		// 			);
		// 		})}
		// 	</Swiper>
		// </section>
	);
};

export default VendorSlider;