import React, { Fragment } from "react";
import Logout from "../../components/Logout";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutPage = () => {
	const {logout} = useAuth0();

	const performLogout = async () => {
		await logout({logoutParams: {returnTo: window.location.origin}});
	};

	return (
		<Fragment>
			<Logout logout={performLogout}/>
		</Fragment>
	);
};
export default LogoutPage;
