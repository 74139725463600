import {
	putCompleteProfileData
} from "../../api/authenticationController";
import {
	putArgusRolePopupSeen
} from "../../api/userController";
import { getUserPreference, postUserPreference } from "../../api/preferenceController";

export const updateProfileData = async ({
	auth0Token,
	firstName,
	lastName,
	mobile,
	wholesaleTrader,
	personalIdNumber,
	companyRegNo,
	personalCapacity,
	setResultMessage,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	try {
		setStatusInProgress();
		let response = await putCompleteProfileData({
			auth0Token,
			firstName,
			lastName,
			mobile,
			wholesaleTrader,
			personalIdNumber: personalCapacity ? personalIdNumber : null,
			companyRegNo: personalCapacity ? null : companyRegNo
		});

		if (!!response?.success) {
			setResultMessage(response.message);
			setStatusSuccess();
		}
		else {
			setResultMessage(response.message);
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setResultMessage(error);
		setStatusError();
	}
};

export const editArgusRolePopupAsSeen = async ({
	auth0Token,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await putArgusRolePopupSeen(auth0Token);
		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};


export const upsertUserPreference = async ({
	auth0Token,
	preferenceId,
	value,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postUserPreference({
			auth0Token,
			preferenceId,
			value
		});

		if (!!response?.success) {
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	} catch (e) {
		console.error(e);
		setStatusError();
	}
}

export const fetchUserPreference = async ({
	auth0Token,
	preferenceId,
	setValue,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getUserPreference({
			auth0Token,
			preferenceId
		});

		if (!!response?.success) {
			if (!!response?.data?.value) {
				setValue(response.data.value);
			}

			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	} catch (e) {
		console.error(e);
		setStatusError();
	}
}
