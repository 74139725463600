import React, { Fragment, useEffect, useState } from "react";
import { removeAuthCookies } from "../../api/authentication";
import { Backdrop, CircularProgress } from "@mui/material";
import Home from "../../components/Home";
import { ApiCallStatus } from "../../utils/constants";
import { fetchHomePageContentData } from "../PageFunctions/pageContentFunctions";
import {
	editAnnouncementDataMarkAsAcknowledged,
	editAnnouncementDataMarkAsSeen
} from "../PageFunctions/announcementFunctions";
import { getItem, setItem } from "../../utils/storageHelper";
import { storageKeys } from "../../api/storageKeys";
import { cloneDeep, uniq } from "lodash";
import { fetchVendorsData } from "../PageFunctions/productFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const HomePage = ({
	genericDataLoaded,
	genericPageContent,
	categoriesAndTypes,
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const [pageContent, setPageContent] = useState(null);
	const [vendors, setVendors] = useState(null);

	const [fetchHomePageContentCallStatus, setFetchHomePageContentCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchVendorsDataCallStatus, setFetchVendorsDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editAnnouncementDataMarkAsSeenCallStatus, setEditAnnouncementDataMarkAsSeenCallStatus] = useState(ApiCallStatus.NotStarted);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchHomePageContentDataAsync = async () => {
		if (fetchHomePageContentCallStatus === ApiCallStatus.Succeeded && !!pageContent) {
			return;
		}
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchHomePageContentData({
			auth0Token,
			setPageContent,
			setStatusInProgress: () => setFetchHomePageContentCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchHomePageContentCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchHomePageContentCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchHomePageContentCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchVendorsDataAsync = async () => {
		await fetchVendorsData({
			setVendorData: setVendors,
			setStatusInProgress: () => setFetchVendorsDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchVendorsDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchVendorsDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchVendorsDataCallStatus(ApiCallStatus.Error)
		});
	};

	const editAnnouncementDataMarkAsSeenAsync = async (announcementId) => {
		const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS);
		let userAnnouncements = JSON.parse(userAnnouncementsString);

		if (!userAnnouncements) {
			userAnnouncements = {
				seen: [],
				acknowledged: []
			};
		}

		const userAnnouncementsClone = cloneDeep(userAnnouncements);
		const arrayClone = userAnnouncementsClone.seen;
		arrayClone.push(announcementId);
		userAnnouncementsClone.seen = uniq(arrayClone);
		setItem(
			storageKeys.USER_ANNOUNCEMENTS,
			JSON.stringify(userAnnouncementsClone)
		);

		if (genericDataLoaded === true) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await editAnnouncementDataMarkAsSeen({
				auth0Token,
				announcementId,
				setStatusInProgress: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const editAnnouncementDataMarkAsAcknowledgedAsync = async (announcementId) => {
		const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS);
		let userAnnouncements = JSON.parse(userAnnouncementsString);

		if (!userAnnouncements) {
			userAnnouncements = {
				seen: [],
				acknowledged: []
			};
		}

		const userAnnouncementsClone = cloneDeep(userAnnouncements);
		const arrayClone = userAnnouncementsClone.acknowledged;
		arrayClone.push(announcementId);
		userAnnouncementsClone.acknowledged = uniq(arrayClone);
		setItem(
			storageKeys.USER_ANNOUNCEMENTS,
			JSON.stringify(userAnnouncementsClone)
		);

		if (genericDataLoaded === true) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await editAnnouncementDataMarkAsAcknowledged({
				auth0Token,
				announcementId,
				setStatusInProgress: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const authorizedLoad = async () => {
		await Promise.all([

		]);
	};

	const unauthorizedLoad = async () => {
		await Promise.all([
			fetchVendorsDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			fetchHomePageContentDataAsync();
			unauthorizedLoad();
			if (isAuthenticated === false) {
				clearCookiesAsync();
			} else {
				authorizedLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	const allLoadingStates = [
		fetchHomePageContentCallStatus,
		editAnnouncementDataMarkAsSeenCallStatus,
		fetchVendorsDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<Home
				vendors={vendors}
				categoriesAndTypes={categoriesAndTypes}
				customerCategory={genericPageContent?.customerCategory}
				pageContent={pageContent}
				markAnnouncementAsAcknowledged={editAnnouncementDataMarkAsAcknowledgedAsync}
				markAnnouncementAsSeen={editAnnouncementDataMarkAsSeenAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default HomePage;
