import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { removeAuthCookies } from "../../api/authentication";
import CheckoutCollectionAddress from "../../components/CheckoutCollectionAddress";
import {
	fetchAddressCheckoutData,
	fetchCheckoutData,
	updateCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { useAuth0 } from "@auth0/auth0-react";



const clearCookies = async () => {
	await removeAuthCookies();
};

const CheckoutCollectionAddressPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [checkoutData, setCheckoutData] = useState(null);
	const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
	const [collectionAddresses, setCollectionAddresses] = useState([]);

	const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoCheckoutSummaryPage = () => navigate("/checkout/summary");

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchCheckoutData({
			auth0Token,
			setCheckoutData,
			setStatusInProgress: () => setCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCheckoutAddressDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchAddressCheckoutData({
			auth0Token,
			setAddressCheckoutInfo,
			setStatusInProgress: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const updateCheckoutDataAsync = async ({checkoutData}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await updateCheckoutData({
			auth0Token,
			checkoutData,
			setStatusInProgress: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Error)
		});
		await fetchCheckoutDataAsync();
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutAddressDataAsync()
		]);
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			}
			else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}

	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (!!addressCheckoutInfo) {
			let collectionAddressesTemp = [];

			if (addressCheckoutInfo?.pickupLocations) {
				collectionAddressesTemp = addressCheckoutInfo.pickupLocations;
			}
			setCollectionAddresses(collectionAddressesTemp);
		}
	}, [addressCheckoutInfo]);

	useEffect(() => {
		if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded) {
			gotoCheckoutSummaryPage();
		}
	}, [updateCheckoutDataCallStatus]);

	const allLoadingStates = [
		checkoutDataCallStatus,
		addressCheckoutInfoCallStatus,
		updateCheckoutDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<CheckoutCollectionAddress
				checkoutData={checkoutData}
				collectionAddresses={collectionAddresses}
				gotoCheckoutSummaryPage={gotoCheckoutSummaryPage}
				onSubmit={updateCheckoutDataAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default CheckoutCollectionAddressPage;
