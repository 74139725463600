import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PostLogin = () => {

	const { user, getIdTokenClaims, state } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		const createSession = async () => {
			const returnTo = state?.returnTo || '/';
			//navigate(returnTo);
		};

		createSession();
	}, [user, getIdTokenClaims, navigate, state]);

	return(
		<div>Login successful...</div>
	)
}

export default PostLogin;