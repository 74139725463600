import React, { Fragment } from "react";

const PageLayout = (props) => {
	return (
		<Fragment>
			<div>{props.children}</div>
		</Fragment>
	);
};

export default PageLayout;
