import axios from "axios";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const productUrl = process.env.REACT_APP_PRODUCT_API_URL;

export const getProductCategoriesAndTypes = async () => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${productUrl}/api/staticdata`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		const requestUrl = `/categories-and-types`;

		return await instance
			.get(requestUrl)
			.then(response => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};
