import React, { Fragment } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import Faq from "../../components/Faq";
import { ApiCallStatus } from "../../utils/constants";



const FaqPage = ({
	genericDataLoaded,
	genericPageContent
}) => {

	const allLoadingStates = [

	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false;

	return (
		<Fragment>
			<Faq/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default FaqPage;
