import { getLiveTradeAccount } from "../../api/tradeAccountController";

export const fetchLiveTradeAccountData = async ({
	auth0Token,
	tradeAccountId,
	setFullTradeAccount,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getLiveTradeAccount(tradeAccountId, auth0Token);
		if (!!response?.success) {
			setFullTradeAccount(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
