import React from "react";
import { Link } from "react-router-dom";

const Logout = ({logout}) => {
	return (
		<Container className="argus-full-page-container argus-background" fluid>
			<Grid centered padded stretched verticalAlign="middle">
				<Grid.Row>
					<Grid.Column width={16}>
						<Image
							src="https://argusweb.azureedge.net/argus-web/logo.svg"
							size="medium"
							centered
							as={Link}
							to="/"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={4}/>
					<Grid.Column width={8} textAlign="center">
						<Segment padded>
							<Grid padded>
								<Grid.Row>
									<Grid.Column width={16}>
										<Header as="h1">Are you sure you want to logout?</Header>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<Button
											className="blue-button"
											type="button"
											onClick={logout}
										>
											Logout
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
					<Grid.Column width={4}/>
				</Grid.Row>
			</Grid>
		</Container>
	);
};

export default Logout;
