import React, { Fragment, useEffect, useState } from "react";
import { removeAuthCookies } from "../../api/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import CheckoutDeliveryMethod from "../../components/CheckoutDeliveryMethod";
import {
	ApiCallStatus,
	LogisticsMethod
} from "../../utils/constants";
import {
	fetchCheckoutData,
	updateCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const CheckoutDeliveryMethodPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [checkoutData, setCheckoutData] = useState(null);
	const [chosenLogisticsMethod, setChosenLogisticsMethod] = useState(null);

	const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoCheckoutShippingAddressPage = () => navigate("/checkout/shipping");
	const gotoCheckoutCollectionAddressPage = () => navigate("/checkout/collection");

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchCheckoutData({
			auth0Token,
			setCheckoutData,
			setStatusInProgress: () => setCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};

	const updateCheckoutDataAsync = async ({checkoutData}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await updateCheckoutData({
			auth0Token,
			checkoutData,
			setStatusInProgress: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {
		await Promise.all([

		]);
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			}
			else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}

	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (checkoutData?.logisticsMethod) {
			setChosenLogisticsMethod(checkoutData.logisticsMethod);
		}
	}, [checkoutData]);

	useEffect(() => {
		if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded && !!chosenLogisticsMethod) {
			if (chosenLogisticsMethod === LogisticsMethod.Ship) {
				gotoCheckoutShippingAddressPage();
			}
			else if (chosenLogisticsMethod === LogisticsMethod.Collection) {
				gotoCheckoutCollectionAddressPage();
			}
		}
	}, [updateCheckoutDataCallStatus]);

	const allLoadingStates = [
		checkoutDataCallStatus,
		updateCheckoutDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<CheckoutDeliveryMethod
				checkoutData={checkoutData}
				chosenLogisticsMethod={chosenLogisticsMethod}
				setChosenLogisticsMethod={setChosenLogisticsMethod}
				onSubmit={updateCheckoutDataAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default CheckoutDeliveryMethodPage;
