import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import FilterCategoryTable from '../FilterCategoryTable';
import FilterAccordion from '../FilterAccordion';
import ClearButton from '../ClearButton';

const DesktopFilters = ({
	productCategoriesListToUse,
	changePageCategory,
	showMoreCategories,
	showAllCategories,
	activeFilters,
	productTypesListToUse,
	changePageProductType,
	showMoreTypes,
	showAllTypes,
	clearPageProductType,
	vendorOptions,
	makeOptions,
	modelOptions,
	variantOptions,
	yearOptions,
	handleFilterChange,
	clearFilter,
}) => {
	return (
		<Grid container spacing={2}>

			<Grid item xs={12}>
				<FilterAccordion
					title="Filter by vendor"
					options={vendorOptions}
					activeFilters={activeFilters}
					onFilterChange={handleFilterChange}
				/>
			</Grid>

			{!!makeOptions.length && (
				<Grid item xs={12}>
					<FilterAccordion
						title="Filter by vehicle make"
						options={makeOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}

			{!!modelOptions.length && (
				<Grid item xs={12}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="h5">Filter by vehicle model</Typography>
						{!!activeFilters.model && (
							<ClearButton onClick={(e) => handleFilterChange(e, { checked: false, name: "model" })} />
						)}
					</Box>
					<FilterAccordion
						title="Model"
						options={modelOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}

			{!!variantOptions.length && (
				<Grid item xs={12}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="h5">Filter by vehicle variant</Typography>
						{!!activeFilters.variant && (
							<ClearButton onClick={(e) => handleFilterChange(e, { checked: false, name: "variant" })} />
						)}
					</Box>
					<FilterAccordion
						title="Variant"
						options={variantOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}

			{!!yearOptions.length && (
				<Grid item xs={12}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="h5">Filter by vehicle year</Typography>
						{!!activeFilters.year && (
							<ClearButton onClick={(e) => handleFilterChange(e, { checked: false, name: "year" })} />
						)}
					</Box>
					<FilterAccordion
						title="Year"
						options={yearOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default DesktopFilters;
