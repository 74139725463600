import React from "react";
import "./style.css"
import { useMediaQuery } from "react-responsive";
import DesktopMakeModelYear from "../DesktopMakeModelYear";
import MobileMakeModelYear from "../MobileMakeModelYear";
import useMakeModelYear from "../../hooks/MakeModelYearHook";
import { cloneDeep } from "lodash";

const MakeModelYear = () => {
	const {
		yearOptions,
		manufacturerOptions,
		modelOptions,
		variantOptions,
		loading,
		userInput,
		setUserInput
	} = useMakeModelYear();

	const submit = (e) => {
		e.preventDefault();

		const queryParameters = new URLSearchParams();
		if (!!userInput.manufacturer) {
			queryParameters.append("make", userInput.manufacturer);
		}
		if (!!userInput.model) {
			queryParameters.append("model", userInput.model);
		}
		if (!!userInput.variant) {
			queryParameters.append("variant", userInput.variant);
		}
		if (!!userInput.year) {
			queryParameters.append("year", userInput.year);
		}

		window.location.href = `/products/all?${queryParameters.toString()}`;
	};

	const handleUserVehicleChange = (name) => (event, newValue) => {
		let userInputCopy = cloneDeep(userInput);
		userInputCopy[name] = newValue;

		if (name === "manufacturer") {
			userInputCopy.model = null;
			userInputCopy.variant = null;
			userInputCopy.year = null;
		}
		else if (name === "model") {
			userInputCopy.variant = null;
			userInputCopy.year = null;
		}
		else if (name === "variant") {
			userInputCopy.year = null;
		}
		setUserInput(userInputCopy);
	};

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	return (
		<>
			{isMobile === true &&
				<MobileMakeModelYear yearOptions={yearOptions}
				                     manufacturerOptions={manufacturerOptions}
				                     modelOptions={modelOptions}
				                     variantOptions={variantOptions}
				                     loading={loading}
				                     userInput={userInput}
				                     setUserInput={setUserInput}
				                     submit={submit}
				                     handleUserVehicleChange={handleUserVehicleChange}

				/>
			}
			{isMobile === false &&
				<DesktopMakeModelYear yearOptions={yearOptions}
				                      manufacturerOptions={manufacturerOptions}
				                      modelOptions={modelOptions}
				                      variantOptions={variantOptions}
				                      loading={loading}
				                      userInput={userInput}
				                      setUserInput={setUserInput}
				                      submit={submit}
				                      handleUserVehicleChange={handleUserVehicleChange}
				/>
			}
		</>
	)
};

export default MakeModelYear;
