import {
	getPayfastResponse,
	postPayfastResponse
} from "../../api/payfastController";
import {
	getPaymentData,
	postAccountCreditPayment,
	postCreditCardOnCollectionPayment,
	postEftPayment,
	postPayment
} from "../../api/paymentController";
import { getOrderPaymentData } from "../../api/orderController";

export const fetchPayfastResponseData = async ({
	auth0Token,
	orderId,
	setPayfastResponse,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getPayfastResponse(orderId, auth0Token);
		if (!!response?.success) {
			setPayfastResponse(response?.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const createPayment = async ({
	auth0Token,
	paymentId,
	orderId,
	amount,
	type,
	status,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postPayment(
			{
				paymentId,
				orderId,
				amount,
				type,
				status
			},
			auth0Token
		);

		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const createPayfastResponse = async ({
	auth0Token,
	payfastResponse,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postPayfastResponse(payfastResponse, auth0Token);

		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchPaymentOrderData = async ({
	auth0Token,
	paymentType,
	setPaymentOrderInfo,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getOrderPaymentData({auth0Token, type: paymentType});
		if (!!response?.success) {
			setPaymentOrderInfo(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const createAccountCreditPaymentData = async ({
	auth0Token,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postAccountCreditPayment(auth0Token);
		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const createCreditCardOnCollectionPaymentData = async ({
	auth0Token,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postCreditCardOnCollectionPayment(auth0Token);
		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const createEftPaymentData = async ({
	auth0Token,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postEftPayment(auth0Token);
		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchPaymentData = async ({
	auth0Token,
	paymentId,
	setPaymentData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getPaymentData(paymentId, auth0Token);
		if (!!response?.success) {
			setPaymentData(response?.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
