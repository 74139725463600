import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import CheckoutBillingAddress from "../../components/CheckoutBillingAddress";
import {
	ApiCallStatus
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { removeAuthCookies } from "../../api/authentication";

import {
	fetchAddressCheckoutData,
	fetchCheckoutData,
	updateCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const clearCookies = async () => {
	await removeAuthCookies();
};

const CheckoutBillingAddressPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [checkoutData, setCheckoutData] = useState(null);
	const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
	const [countries, setCountries] = useState([]);
	const [provinces, setProvinces] = useState([]);
	const [userAddresses, setUserAddresses] = useState(null);

	const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoCheckoutDeliveryMethodPage = () => navigate("/checkout/delivery-method");
	const gotoCreateAddressPage = () => navigate("/checkout/billing/create");


	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (checkoutDataCallStatus === ApiCallStatus.NotStarted) {
			await fetchCheckoutData({
				auth0Token,
				setCheckoutData,
				setStatusInProgress: () => setCheckoutDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setCheckoutDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setCheckoutDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setCheckoutDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchCheckoutAddressDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (addressCheckoutInfoCallStatus === ApiCallStatus.NotStarted) {
			await fetchAddressCheckoutData({
				auth0Token,
				setAddressCheckoutInfo,
				setStatusInProgress: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Error)
			});
		}

	};

	const updateCheckoutDataAsync = async ({checkoutData}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (updateCheckoutDataCallStatus === ApiCallStatus.NotStarted) {
			await updateCheckoutData({
				auth0Token,
				checkoutData,
				setStatusInProgress: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const refreshAddressOptions = async () => {
		await fetchCheckoutAddressDataAsync();
	};

	const authorizedPageLoad = async () => {

	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutDataAsync(),
			fetchCheckoutAddressDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			}
			else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}

	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (!!addressCheckoutInfo) {
			let userAddressesTemp = [];
			let countries = [];
			let provinces = [];

			if (addressCheckoutInfo?.countries) {
				const countriesInAlphabeticalOrder = _.sortBy(
					addressCheckoutInfo.countries,
					({name}) => name
				);
				countries = _.sortBy(countriesInAlphabeticalOrder, ({name}) =>
					name === "South Africa" ? 0 : 1
				);
			}

			if (addressCheckoutInfo?.provinces) {
				provinces = addressCheckoutInfo.provinces;
			}

			if (addressCheckoutInfo?.userAddresses) {
				userAddressesTemp = addressCheckoutInfo.userAddresses;
			}

			setProvinces(provinces);
			setCountries(countries);
			setUserAddresses(userAddressesTemp);
		}
	}, [addressCheckoutInfo]);

	useEffect(() => {
		if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded) {
			gotoCheckoutDeliveryMethodPage();
		}
	}, [updateCheckoutDataCallStatus]);

	const allLoadingStates = [
		checkoutDataCallStatus,
		addressCheckoutInfoCallStatus,
		updateCheckoutDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<CheckoutBillingAddress
				checkoutData={checkoutData}
				countries={countries}
				provinces={provinces}
				userAddresses={userAddresses}
				onSubmit={updateCheckoutDataAsync}
				refreshOptions={refreshAddressOptions}
				gotoCreateAddressPage={gotoCreateAddressPage}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default CheckoutBillingAddressPage;
