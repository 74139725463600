import axios from "axios";
import { encode } from "js-base64";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_PRODUCT_API_URL;

export const getProducts = async ({
	auth0Token,
	productCategory,
	productType,
	vendor,
	make,
	model,
	variant,
	year,
	pageNumber
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/product`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (productCategory)
			searchParams.append("productCategory", productCategory);
		if (productType) searchParams.append("productType", productType);
		if (vendor) searchParams.append("vendor", vendor);
		if (make) searchParams.append("make", make);
		if (model) searchParams.append("model", model);
		if (variant) searchParams.append("variant", variant);
		if (year) searchParams.append("year", year);
		if (pageNumber) searchParams.append("skip", pageNumber - 1);

		const requestUrl = `?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getProductsForVehicle = async ({
	auth0Token,
	vehicleYearId,
	productCategory,
	productType,
	vendor,
	pageNumber
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/product`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (productCategory)
			searchParams.append("productCategory", productCategory);
		if (productType) searchParams.append("productType", productType);
		if (vendor) searchParams.append("vendor", vendor);
		if (pageNumber) searchParams.append("skip", pageNumber - 1);

		const requestUrl = `/vehicle/${vehicleYearId}?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getPrices = async ({
	auth0Token,
	productSkus,
	output
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/product`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const params = {
			output
		};

		const stringifiedParams = JSON.stringify(params);

		const base64Params = encode(stringifiedParams);

		const searchParams = new URLSearchParams();
		if (base64Params) searchParams.append("filters", base64Params);

		const requestUrl = `/prices?${searchParams.toString()}`;

		const body = { skus: productSkus };

		return await instance
			.post(requestUrl, body)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getFilters = async ({
	productIds,
	currentProductCategory,
	currentProductType,
	currentVendor,
	currentMake,
	currentModel,
	currentVariant,
	currentYear,
	auth0Token
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/product`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const params = {
			currentProductCategory,
			currentProductType,
			currentVendor,
			currentMake,
			currentModel,
			currentVariant,
			currentYear
		};

		const stringfiedParams = JSON.stringify(params);

		const base64Params = encode(stringfiedParams);

		const searchParams = new URLSearchParams();
		if (base64Params) searchParams.append("filters", base64Params);

		const requestUrl = `/filters?${searchParams.toString()}`;

		const body = { productIds };

		return await instance
			.post(requestUrl, body)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getProductData = async (productSkus, auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/product`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const params = {
			productSkus: productSkus
		};

		const stringifiedParams = JSON.stringify(params);

		const base64Params = encode(stringifiedParams);

		const searchParams = new URLSearchParams();
		if (base64Params) searchParams.append("filters", base64Params);

		const requestUrl = `/products?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};


