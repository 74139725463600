import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import { AnnouncementType } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

const AnnouncementModal = ({
	open,
	setClose,
	type,
	image,
	gotoLink,
	announcementId,
	markAnnouncementAsSeen,
	markAnnouncementAsAcknowledged
}) => {
	const modalStyle = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center", // Centers content vertically in the modal
		alignItems: "center", // Centers content horizontally
		padding: 4,
		boxShadow: 24,
		bgcolor: "white",
		minWidth: "30vw",
		minHeight: "30vw"
	};

	const closeModal = () => {
		setClose();
	};

	const markAsAcknowledged = async () => {
		closeModal();
		await markAnnouncementAsAcknowledged(announcementId);
	};

	const markAsSeen = async () => {
		closeModal();
		await markAnnouncementAsSeen(announcementId);
	};

	return (
		<Modal
			open={open}
			onClose={closeModal}
			disableBackdropClick
			disableEscapeKeyDown
		>
			<Box sx={modalStyle} className="modalContent">
				<IconButton
					onClick={markAsSeen}
					style={{position: "absolute", top: 0, right: 0}}
				>
					<CloseIcon/>
				</IconButton>
				<Image src={image} className="modalImage"/>
				<div className="buttonContainer">
					{type === AnnouncementType.Acknowledgement && (
						<Button className="blue-button" onClick={markAsAcknowledged}>
							Acknowledge
						</Button>
					)}
					{type === AnnouncementType.Promotional && (
						<>
							<Button className="blue-button" onClick={gotoLink}>
								View
							</Button>
						</>
					)}
				</div>
			</Box>
		</Modal>
	);
};

export default AnnouncementModal;
