import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountType, ScreenSize } from "../../utils/constants";
import { ErrorMessage } from "@hookform/error-message";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Button, Collapse, Grid, MenuItem, Paper, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PageHeader from "../PageHeader";

const CheckoutOrderInformation = ({
	salesPeople,
	accountType,
	phoneNumber,
	firstName,
	lastName,
	email,
	tecfinityAccountName,
	tecfinityAccountNumber,
	salesPersonCode,
	checkoutData,
	onSubmit
}) => {
	const { screenSize } = useSystemHook();
	const [salesPersonOptions, setSalesPersonOptions] = useState([]);
	const [formDropdownOptionsChosen, setFormDropdownOptionsChosen] = useState({
		salesPerson: null
	});
	const [showMobileSummary, setShowMobileSummary] = React.useState(false);
	const schema = yup
		.object({
			salesPerson: yup.string(),
			email: yup.string().email().required("Email is required"),
			phone: yup.string().required("Mobile number is required to register")
		})
		.required();
	const {
		control,
		handleSubmit,
		trigger,
		getValues,
		setValue,
		formState: {errors},
		clearErrors
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			salesPerson: "155",
			email: "",
			phone: ""
		}
	});

	useEffect(() => {
		if (!!salesPeople) {
			const options = salesPeople.map((x) => {
				return {
					key: String(x.code.trim()),
					text: x.name.trim(),
					value: String(x.code.trim())
				};
			});
			setSalesPersonOptions(options);
		}
	}, [salesPeople]);

	useEffect(() => {
		if (!!salesPersonCode) {
			if (salesPersonOptions.length > 0) {
				setValue("salesPerson", String(salesPersonCode));
				const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);
				optionsChosenCopy["salesPerson"] = String(salesPersonCode);
				setFormDropdownOptionsChosen(optionsChosenCopy);
			}
		}
	}, [salesPersonCode]);

	useEffect(() => {
		if (!!phoneNumber) {
			setValue("phone", phoneNumber);
		}
	}, [phoneNumber]);

	useEffect(() => {
		if (!!firstName) {
			setValue("firstName", firstName);
		}
	}, [firstName]);

	useEffect(() => {
		if (!!lastName) {
			setValue("lastName", lastName);
		}
	}, [lastName]);

	useEffect(() => {
		if (!!email) {
			setValue("email", email);
		}
	}, [email]);

	const onSaveOrderInformationClick = async () => {
		const result = await trigger(["phone", "email"]);

		if (!!result && !!checkoutData) {
			const checkoutDataClone = cloneDeep(checkoutData);

			checkoutDataClone.salesPersonCode = getValues("salesPerson") ?? "155";
			if (!!getValues("phone")) {
				checkoutDataClone.phoneNumber = getValues("phone");
			}

			if (!!getValues("email")) {
				checkoutDataClone.email = getValues("email");
			}


			await onSubmit({
				checkoutData: checkoutDataClone
			});
		}
	};

	const onOptionChange = (_, {name, value}) => {
		const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);
		optionsChosenCopy[name] = value;
		setValue(name, value);
		clearErrors(name);
		setFormDropdownOptionsChosen(optionsChosenCopy);
	};

	return (
		<>
			<PageHeader title="Checkout" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
					{!!checkoutData && (
						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1rem' }}>
									<form onSubmit={handleSubmit(onSubmit)}>
										{accountType === AccountType.Commercial &&
											!!tecfinityAccountName &&
											!!tecfinityAccountNumber && (
												<Box sx={{ marginBottom: '1.5rem' }}>
													<Typography
														variant="h6"
														gutterBottom
														sx={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}
													>
														<InfoIcon sx={{ marginRight: '0.5rem', fontSize: '1.25rem' }} />
														Account
													</Typography>
													<TextField
														label="Account"
														variant="outlined"
														fullWidth
														value={`${tecfinityAccountName} - ${tecfinityAccountNumber}`}
														disabled
														InputProps={{
															startAdornment: (
																<AccountCircleIcon sx={{ marginRight: '0.5rem', fontSize: '1.25rem' }} />
															),
														}}
														sx={{ marginBottom: '1rem' }}
													/>
													<Controller
														name="salesPerson"
														control={control}
														render={({ field }) => (
															<TextField
																select
																label="Sales Person"
																variant="outlined"
																fullWidth
																{...field}
																error={!!errors?.salesPerson}
																helperText={errors?.salesPerson?.message}
																sx={{ fontSize: '0.875rem' }}
															>
																{salesPersonOptions.map((option) => (
																	<MenuItem key={option.value} value={option.value}>
																		{option.label}
																	</MenuItem>
																))}
															</TextField>
														)}
													/>
												</Box>
											)}

										<Typography
											variant="body2"
											gutterBottom
											sx={{ display: 'flex', alignItems: 'center', mb: 4}}
										>
											<InfoIcon sx={{ marginRight: '0.5rem', fontSize: '1.25rem' }} />
											Contact Information
										</Typography>
										<Controller
											name="email"
											control={control}
											render={({ field }) => (
												<TextField
													label="Email"
													variant="outlined"
													fullWidth
													{...field}
													error={!!errors?.email}
													helperText={errors?.email?.message}
													InputProps={{
														startAdornment: (
															<EmailIcon sx={{ marginRight: '0.5rem', fontSize: '1.25rem' }} />
														),
													}}
													disabled={!!accountType}
													sx={{ marginBottom: '1rem', fontSize: '0.875rem' }}
												/>
											)}
										/>
										<Controller
											name="phone"
											control={control}
											render={({ field }) => (
												<TextField
													label="Phone"
													variant="outlined"
													fullWidth
													{...field}
													error={!!errors?.phone}
													helperText={errors?.phone?.message}
													InputProps={{
														startAdornment: (
															<PhoneIcon sx={{ marginRight: '0.5rem', fontSize: '1.25rem' }} />
														),
													}}
													disabled={!!accountType}
													sx={{ fontSize: '0.875rem' }}
												/>
											)}
										/>
									</form>
								</Paper>
							</Grid>
							<Grid item xs={12} md={4}>
								<CartSummary
									vat={checkoutData.vatPercentage}
									cartNettTotal={checkoutData.subtotal}
									settlementAmount={checkoutData.settlementAmount}
									vatAmount={checkoutData.vatAmount}
									total={checkoutData.total}
								/>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={onSaveOrderInformationClick}
									endIcon={<ArrowForwardIcon />}
									sx={{ marginTop: '0.75rem' }}
								>
									Continue
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '100vh', padding: '1rem' }}>
					{!!checkoutData && (
						<>
							<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1.5rem' }}>
								<form onSubmit={handleSubmit(onSubmit)}>
									{accountType === AccountType.Commercial && !!tecfinityAccountName && !!tecfinityAccountNumber && (
										<Box sx={{ marginBottom: '1.5rem' }}>
											<Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
												<InfoIcon sx={{ marginRight: '0.5rem' }} />
												Account
											</Typography>
											<TextField
												label="Account"
												variant="outlined"
												fullWidth
												value={`${tecfinityAccountName} - ${tecfinityAccountNumber}`}
												disabled
												InputProps={{
													startAdornment: (
														<AccountCircleIcon sx={{ marginRight: '0.5rem' }} />
													),
												}}
												sx={{ marginBottom: '1rem' }}
											/>
											<Controller
												name="salesPerson"
												control={control}
												render={({ field }) => (
													<TextField
														select
														label="Sales Person"
														variant="outlined"
														fullWidth
														{...field}
														error={!!errors?.salesPerson}
														helperText={errors?.salesPerson?.message}
													>
														{salesPersonOptions.map((option) => (
															<MenuItem key={option.value} value={option.value}>
																{option.label}
															</MenuItem>
														))}
													</TextField>
												)}
											/>
										</Box>
									)}

									<Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
										<InfoIcon sx={{ marginRight: '0.5rem' }} />
										Contact Information
									</Typography>
									<Controller
										name="email"
										control={control}
										render={({ field }) => (
											<TextField
												label="Email"
												variant="outlined"
												fullWidth
												{...field}
												error={!!errors?.email}
												helperText={errors?.email?.message}
												InputProps={{
													startAdornment: (
														<EmailIcon sx={{ marginRight: '0.5rem' }} />
													),
												}}
												disabled={!!accountType}
												sx={{ marginBottom: '1rem' }}
											/>
										)}
									/>
									<Controller
										name="phone"
										control={control}
										render={({ field }) => (
											<TextField
												label="Phone"
												variant="outlined"
												fullWidth
												{...field}
												error={!!errors?.phone}
												helperText={errors?.phone?.message}
												InputProps={{
													startAdornment: (
														<PhoneIcon sx={{ marginRight: '0.5rem' }} />
													),
												}}
												disabled={!!accountType}
											/>
										)}
									/>
								</form>
							</Paper>

							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={() => setShowMobileSummary(!showMobileSummary)}
								endIcon={showMobileSummary ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								sx={{ marginBottom: '1rem' }}
							>
								{showMobileSummary ? 'Hide' : 'Show'} Summary
							</Button>

							<Collapse in={showMobileSummary}>
								<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem' }}>
									<CartSummary
										vat={checkoutData.vatPercentage}
										cartNettTotal={checkoutData.subtotal}
										settlementAmount={checkoutData.settlementAmount}
										vatAmount={checkoutData.vatAmount}
										total={checkoutData.total}
									/>
								</Paper>
							</Collapse>

							<Button
								variant="contained"
								color="primary"
								fullWidth
								onClick={onSaveOrderInformationClick}
								endIcon={<ArrowForwardIcon />}
							>
								Continue
							</Button>
						</>
					)}
				</Box>
			}
		</>
	);
};

export default CheckoutOrderInformation;
