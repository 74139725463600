import axios from "axios";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_ORDER_API_URL;

export const createOrder = async (orderData, auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		return await instance
			.post(``, orderData)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrderData = async (auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `current`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrderDataById = async (auth0Token, orderId) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `${orderId}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrderConfirmationData = async (auth0Token, orderId) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `confirmation/${orderId}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getOrderPaymentData = async ({auth0Token, type}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (type) searchParams.append("type", type);

		const requestUrl = `payment?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const deleteOrderCookies = async (auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/order/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `cookies`;

		return await instance
			.delete(requestUrl)
			.then((response) => {
				if (response.status === 204)
					return {
						error: false,
						success: true
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};
