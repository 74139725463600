import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import OrderConfirmation from "../../components/OrderConfirmation";
import { ApiCallStatus } from "../../utils/constants";
import { fetchOrderConfirmationData, fetchOrderData, fetchOrderDataById } from "../PageFunctions/orderFunctions";
import { useCart } from "react-use-cart";
import { fetchProductsBySkus } from "../PageFunctions/productFunctions";

const OrderConfirmationPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {orderId} = useParams();
	const {emptyCart} = useCart();
	const location = useLocation();
	const navigate = useNavigate();
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const [orderData, setOrderData] = useState(null);
	const [productData, setProductData] = useState(null);

	const [orderDataFetchCallStatus, setOrderDataFetchCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchOrderConfirmationDataAsync = async () => {
		if (!orderId) return;

		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrderConfirmationData({
			auth0Token,
			orderId,
			setOrderData,
			setStatusInProgress: () => setOrderDataFetchCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setOrderDataFetchCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setOrderDataFetchCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setOrderDataFetchCallStatus(ApiCallStatus.Error)
		});
	};

	const unauthorizedCalls = async () => {
		await Promise.all([
			fetchOrderConfirmationDataAsync()
		]);
	};

	const authorizedCalls = async () => {
		await Promise.all([

		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				unauthorizedCalls();
			} else {
				authorizedCalls();
				unauthorizedCalls();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (orderDataFetchCallStatus === ApiCallStatus.Succeeded && !!orderData) {
			console.log("orderData", orderData);
		}
	}, [orderDataFetchCallStatus, orderData]);



	const allLoadingStates = [
		orderDataFetchCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Fragment>
			<OrderConfirmation
				orderData={orderData}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	)
}

export default OrderConfirmationPage;