import GridItem from "../GridItem";
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import { cloneDeep, find } from "lodash";
import { Box, Grid, Pagination } from "@mui/material";
import { useArgusCart } from "../../contexts/ArgusCartContext";

export const GridProducts = ({
    products,
    pricingData,
    customerCategory,
    filters,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    totalResults,
    itemsPerRow
}) => {
    const { items, setItems } = useArgusCart();
    const [showVehicleSpecificInformation, setShowVehicleSpecificInformation] = useState(false);

    const scrollTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (!!filters) {
            const manufacturers = filters.filter(x => x.type === "MANUFACTURER")[0];
            const models = filters.filter(x => x.type === "MODEL")[0];
            const variants = filters.filter(x => x.type === "VARIANT")[0];
            const years = filters.filter(x => x.type === "YEAR")[0];

            const anyActiveManufacturers = manufacturers?.values.some(x => x.active);
            const anyActiveModels = models?.values.some(x => x.active);
            const anyActiveVariants = variants?.values.some(x => x.active);
            const anyActiveYears = years?.values.some(x => x.active);

            setShowVehicleSpecificInformation(anyActiveManufacturers && anyActiveModels && anyActiveVariants && anyActiveYears)
        }
    }, []);

    const handleUpdateQuantity = (productId, quantity) => {
        const itemsCopy = cloneDeep(items);
        const itemToUpdate = itemsCopy.find(x => x.id === productId);
        itemToUpdate.quantity = quantity;
        setItems(itemsCopy);
    };

    const isMobile = useMediaQuery({
        query: '(min-width: 200px) and (max-width:  499px) '
    });

    return (
        <Box>
            <Grid container spacing={2}>
                {!!products && !!pricingData && products.map((product, itemNumber) => {
                    const priceObject = find(pricingData, p => p.sku === product.sku);
                    const itemPrice = priceObject?.price ?? 0;
                    const invoicePrice = priceObject?.invoicePrice ?? 0;
                    return (
                        <Grid item xs={6} sm={6} md={4} lg={12 / itemsPerRow} key={itemNumber}>
                            <GridItem
                                key={itemNumber}
                                title={product.name}
                                price={itemPrice}
                                invoicePrice={invoicePrice}
                                customerCategory={customerCategory}
                                vendor={product.vendor}
                                image={product.image}
                                productId={product.productId}
                                vendorImage={product.vendorImage}
                                handle={product.handle}
                                vendorDescription={product.vendorDescription}
                                quality={product.quality}
                                fitment={product.fitment}
                                availability={product.availability}
                                warranty={product.warranty}
                                warrantyText={product.warrantyText}
                                applicationPosition={product.applicationPosition}
                                applicationPositionImage={product.applicationPositionImage}
                                // showVehicleSpecificInformation={showVehicleSpecificInformation}
                                // isKiosk={isKiosk}
                                bodyHtml={product.html}
                                sku={product.sku}
                                updateCart={handleUpdateQuantity}
                            />
                        </Grid>
                    );
                })}
            </Grid>

            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                    {totalResults && totalResults > 0 && (
                        <Pagination
                            count={Math.ceil(totalResults / pageSize)}
                            page={pageNumber}
                            onChange={handlePageNumberChange}
                            size={isMobile ? 'small' : 'medium'}
                            variant="outlined"
                            shape="rounded"
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

